import { Divider, Modal } from "antd";
import CustomButton from "../../customButton/customButton";
import HeadingComponent from "../../headingComponent/heading";
import {QRCodeSVG} from 'qrcode.react';
import { encryptData } from "../../../util/commonUtil";
import styled from "styled-components";
const QRPopup = ({
  onclose,
  openModal,
  data,
  padding = "20px",
}) => {
  
  const updatedData = {
    trk: {
      bed_capacity: data?.bed_capacity,
      certification_status: data?.certification_status,
      color: data?.color,
      created_at: data?.created_at,
      description: data?.description,
      distinguish_features: data?.distinguish_features,
      hand_loader: data?.hand_loader,
      id: data?.id,
      is_active: data?.is_active,
      is_driver_the_owner: data?.is_driver_the_owner,
      is_leaner_hanger: data?.is_leaner_hanger,
      license_expire_date: data?.license_expire_date,
      license_state: data?.license_state,
      license_tag_number: data?.license_tag_number,
      make: data?.make,
      model: data?.model,
      open_back: data?.open_back,
      side_boards: data?.side_boards,
      sub_contractor_id: data?.sub_contractor?.id,
      truck_driver_address: data?.truck_driver_address,
      truck_driver_city: data?.truck_driver_city,
      truck_driver_driving_license_number: data?.truck_driver_driving_license_number,
      truck_driver_email: data?.truck_driver_email,
      truck_driver_latitude: data?.truck_driver_latitude,
      truck_driver_license_expire_date: data?.truck_driver_license_expire_date,
      truck_driver_license_state: data?.truck_driver_license_state,
      truck_driver_longitude: data?.truck_driver_longitude,
      truck_driver_name: data?.truck_driver_name,
      truck_driver_phone_number: data?.truck_driver_phone_number,
      truck_driver_state: data?.truck_driver_state,
      truck_driver_zip_code: data?.truck_driver_zip_code,
      truck_number: data?.truck_number,
      type_id: data?.type?.id,
      updated_at: data?.updated_at,
      vin_number: data?.vin_number,
      year: data?.year
    }
  };
  
  // Append truck_owner data only if the driver is not the owner
  if (data?.is_driver_the_owner === false) {
    updatedData.trk = {
      ...updatedData.trk,
      truck_owner_address: data?.truck_owner_address,
      truck_owner_city: data?.truck_owner_city,
      truck_owner_company: data?.truck_owner_company,
      truck_owner_latitude: data?.truck_owner_latitude,
      truck_owner_longitude: data?.truck_owner_longitude,
      truck_owner_name: data?.truck_owner_name,
      truck_owner_phone_number: data?.truck_owner_phone_number,
      truck_owner_state: data?.truck_owner_state,
      truck_owner_zip_code: data?.truck_owner_zip_code
    };
  }
  

  const encryptedData = encryptData(updatedData);

  return (
    <Modal open={openModal} onCancel={onclose} footer={null} width={"500px"} height={"500px"} centered>
      <div
        style={{
          padding: padding,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
      <div style={{display:"flex", flexDirection:"column", width:"450px", marginRight:"8px", background:"#3669AE", borderRadius:"5px", padding:"20px"}}>
        <Heading text={"Truck Number:"} margin="0px 10px 0px 0px" fontSize="0.9rem" color="white" fontWeight={700} />
        <Heading text={data?.truck_number } margin="0px 0px 0px 0px" fontSize="0.75rem" color="white" fontWeight={700} />
      </div>

        
        <QRCodeSVG value={encryptedData} style={{width:"200px", height:"200px", margin:"30px 0px"}} />
       
        {/* <div className="d-flex justify-content-end" style={{marginTop:"40px"}}> */}
         <Divider/>
         <ButtonContainer className="d-flex justify-content-end">

          <CustomButton btnText={"Print"} color={"white"} padding="10px 30px" hideIcon margin="10px" onClick={() => {
            
            window.print();
          }} />
         </ButtonContainer>
        {/* </div> */}
      </div>
    </Modal>
  );
};

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B", position, top, bottom, right, left }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} position={position} top={top} bottom={bottom} right={right} left={left} />;
};

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 495px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;

  /* Hide ButtonContainer when printing */
  @media print {
    display: none !important;
  }
`;


export default QRPopup;
