import React, { useEffect, useState } from 'react'
import { generateMenuItems } from '../../util/generateMenuItems';
import { reportsMenuData } from '../../constants/menuData/menuData';
import DisposalSiteReport from '../../components/reportsComponents/disposalSiteReport';
import DailySummaryReport from '../../components/reportsComponents/dailySummaryReport';
import TicketsReport from '../../components/reportsComponents/ticketsReport';
import TruckReports from '../../components/reportsComponents/truckReports';
import CustomMenu from '../../components/customMenu/customMenu';
import { Col, Row } from 'antd';
import ListOfDisposalSiteReport from '../../components/reportsComponents/listOfDisposalSiteReport';
import DebrisLoadReport from '../../components/reportsComponents/debrisLoadReport';
import DebrisCostReport from '../../components/reportsComponents/debrisCostReport';
import usePermissions from '../../components/hooks/usePermissions';
import { hasPermission } from '../../util/permissionChecker';

export default function Reports() {

   //----------------------- Custom Hooks -----------------------//

   const { assignedPermissions } = usePermissions();

   const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
   
  //------------------ Function to Generate Menu Items ---------------------//
  const menuItemsList = generateMenuItems(reportsMenuData);
  

  //------------------State Management---------------------//
  const [activeComponent, setActiveComponent] = useState('Daily Summary Report (PDF)');
  const [selectTab, setSelectTab] = useState("pdf");
  const [menuItems, setMenuItems] = useState();

  useEffect(() => {
    if (menuItemsList && assignedPermissionsArray) {
      const filteredMenuItems = menuItemsList?.filter(item => {
        if (item?.requiredPermission && hasPermission(assignedPermissionsArray, item?.requiredPermission) && item?.reportType?.includes(selectTab)) {
          return item;
        }
      });
      if (filteredMenuItems?.length > 0) {
        setMenuItems(filteredMenuItems);
        setActiveComponent(filteredMenuItems[0]?.label);
      } else {
        setMenuItems([]);
      }
    }
  }, [selectTab,assignedPermissionsArray]);


  //---------------------- Components ----------------------//
  const COMPONENTS = {
      'Daily Summary Report (PDF)': DailySummaryReport,
      'Tickets Report (PDF)': TicketsReport,
      'Truck Report (PDF)': TruckReports,
      'Disposal Site Ticket Report (PDF)': DisposalSiteReport,
      'List of Disposal Site Report (XLS)': ListOfDisposalSiteReport,
      'Debris Load Report (XLS)': DebrisLoadReport,
      'Debris Cost Report (XLS)': DebrisCostReport,

  };
  //---------------------- Props Mapping ----------------------//
  const propsMapping = {
      'Daily Summary Report (PDF)': {},
      'Tickets Report (PDF)': {},
      'Truck Report (PDF)': {},
      'Disposal Site Ticket Report (PDF)': {},
      'List of Disposal Site Report (XLS)': {},
      'Debris Load Report (XLS)': {},
      'Debris Cost Report (XLS)': {},
  };

  const initialSelectedKey = reportsMenuData[0].itemList.find(
    (item) => item.itemName === activeComponent
  )?.key;

   //------------------ Function to Handle Menu Select ---------------------//
  const handleMenuSelect = (item) => {
    if (item?.label) {
      setActiveComponent(item?.label);
    }
  };

  //------------------ Assignment to Render Component ---------------------//
  const ActiveComponent = COMPONENTS[activeComponent];
  const activeComponentProps = propsMapping[activeComponent] || {};
  return (
    <div style={{ marginTop: '20px' }}>
      <Row gutter={16} style={{ marginBottom: '16px', marginTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CustomMenu menuItems={menuItems} handleMenuSelect={handleMenuSelect} initialSelectedKey={initialSelectedKey} addSegment={true} selectTab={selectTab} setSelectTab={setSelectTab}/>
        </div>
        <Col span={6}>
          <ActiveComponent {...activeComponentProps} />
        </Col>
      </Row>
    </div>
  )
};
