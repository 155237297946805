import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { Button, Divider, Form, Input, InputNumber, message, Segmented, Select, Switch } from "antd";
import { CustomModal } from '../../customModal/customModal';
import CustomButton from '../../customButton/customButton';
import CustomSwitch from '../../customSwitch/customSwitch';
import HeadingComponent from '../../headingComponent/heading';
import LocationSelector from '../../customAddressInput/locationSelector';
import { useSelector } from 'react-redux';
import { getUserPositionList } from '../../../util/dataService';
import { main_api } from '../../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../../constants/apiEndPoints';
import PaginatedSelect from '../../customSelect/paginatedSelect';
import { pushNotification } from '../../../util/notification';
import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as PlusIcon } from "../../../assets/rawSvg/Plus.svg";
import { ReactComponent as CrossIcon } from "../../../assets/rawSvg/Cross.svg";
import { fieldsMap, rateMatrixFieldsMap } from '../../../util/fieldsMap';

export default function UpdateRateMatrix({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    updateRateMatrixValues,
    selectedClientForUpdateRateMatrix,
    selectedContractorForUpdateRateMatrix,
    selectedProjectForUpdateRateMatrix,
    selectedDebrisTypeForUpdateRateMatrix,
    selectedClientData,
    selectedContractorData,
    selectedProjectData,
    selectedDebrisTypeData,
    setSelectedClientForUpdateRateMatrix,
    setSelectedContractorForUpdateRateMatrix,
    setSelectedProjectForUpdateRateMatrix,
    setSelectedDebrisTypeForUpdateRateMatrix,
    setSelectedClientData,
    setSelectedContractorData,
    setSelectedProjectData,
    setSelectedDebrisTypeData,
    padding = "20px",
}) {

  //----------------------- State Variables -----------------------//

    const [fields, setFields] = useState({});

    const [contractorOptions, setContractorOptions] = useState([]);
    const [debrisTypeOptions, setDebrisTypeOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);

    const [contractorData, setContractorData] = useState(null);
    const [debrisTypeData, setDebrisTypeData] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const [clientData, setClientData] = useState(null);
    
    const [projectOptionsPage, setProjectOptionsPage] = useState(1);
    const [contractorOptionsPage, setContractorOptionsPage] = useState(1);
    const [clientOptionsPage, setClientOptionsPage] = useState(1);
    const [debrisTypeOptionsPage, setDebrisTypeOptionsPage] = useState(1);

    const [showUpdateRateMatrixForm, setShowUpdateRateMatrixForm] = useState(false);

    const [selectedUnit, setSelectedUnit] = useState(null);

    //----------------------- Form Initialization -----------------------//

    const [form] = Form.useForm();

    useEffect(() => {
        if (updateRateMatrixValues) {
          
          form.setFieldsValue({
            project: updateRateMatrixValues?.project,
            contractor: updateRateMatrixValues?.contractor,
            client: updateRateMatrixValues?.client,
            debris_type: updateRateMatrixValues?.debris_type,
            rate: updateRateMatrixValues?.rate,
            unit_type: updateRateMatrixValues?.unit_type,
            
          });
        }
      }
    , [updateRateMatrixValues]);


    useEffect(() => {
        if (updateRateMatrixValues) {
          
          const fields = updateRateMatrixValues?.debris_type;
          setFields(fields);
          setShowUpdateRateMatrixForm(true);

        }
      }
    , [updateRateMatrixValues]);

    useEffect(() => {
      if (updateRateMatrixValues) {
        setSelectedUnit(updateRateMatrixValues?.unit_type);
      }
    }, [updateRateMatrixValues]);


    useEffect(() => {
      if (updateRateMatrixValues) {
    
        // Iterate over updateRateMatrixValues
        Object.keys(updateRateMatrixValues).forEach((key) => {
          // Check if the key ends with '_to' or '_from'
          if (key.endsWith('_to') || key.endsWith('_from')) {
            // Set the field value dynamically
            form.setFieldsValue({
              [key]: updateRateMatrixValues[key],
            });
          }
        });
    
      }
    }, [updateRateMatrixValues,fields]);

    //----------------------- Fetch Data -----------------------//

 
    const fetchContractors = async (page = 1) => {
      let query = `is_active=true&page=${page}`;
      main_api.get(adminAPIsEndPoints.LIST_CONTRACTORS(query))
        .then((response) => {
          const result = response.data.results;
    
          // Handle pagination
          if (response.data.next) {
            const urlObj = new URL(response.data.next);
            const params = new URLSearchParams(urlObj.search);
            const pageNumber = params.get('page');
            setContractorOptionsPage(pageNumber);
          } else {
            setContractorOptionsPage(null);
          }
    
          // Generate options array from API response
          let options = [...contractorOptions, ...result?.map(contractor => ({
            ...contractor,
            label: contractor.name.charAt(0).toUpperCase() + contractor.name.slice(1),
            value: contractor.id,
          }))];
    
          // Ensure selected prime contractors are included
          
          if (selectedContractorData) {
            const selectedContractor = {
              label: selectedContractorData?.name.charAt(0).toUpperCase() + selectedContractorData?.name.slice(1),
              value: selectedContractorData?.id,
            };
            
            // Ensure the selected contractor is included at the beginning of the options
            options = [selectedContractor, ...options.filter(item => item.value !== selectedContractor.value)];
    
          }

          const uniqueOptions = options.reduce((acc, current) => {
            const existing = acc.find(item => item.value === current.value);
            if (existing) {
              // Compare the number of keys and keep the one with more information
              if (Object.keys(current).length > Object.keys(existing).length) {
                return acc.map(item => item.value === current.value ? current : item);
              }
              return acc;
            } else {
              acc.push(current);
              return acc;
            }
          }, []);
          
          setContractorData(result);
          setContractorOptions(uniqueOptions);
        })
        .catch((error) => {
          pushNotification(error?.response?.data?.detail, "error");
        });
    };


   

    const fetchClients = async (page = 1) => {
      const query = `is_active=true&page=${page}`;
      main_api.get(adminAPIsEndPoints.LIST_CLIENTS(query))
      .then((response) => {
          const result = response.data.results;
         
          if (response.data.next) {
            const urlObj = new URL(response.data.next);
            const params = new URLSearchParams(urlObj.search);
            const pageNumber = params.get('page');
            setClientOptionsPage(pageNumber);
          } else {
            setClientOptionsPage(null);
          }

          let options = [...clientOptions, ...result?.map(client => ({
            ...client,
            label: client.name.charAt(0).toUpperCase() + client.name.slice(1),
            value: client.id,
          }))];

          if (selectedClientData) {
            const selectedClient = {
              ...selectedClientData,
              label: selectedClientData?.name.charAt(0).toUpperCase() + selectedClientData?.name.slice(1),
              value: selectedClientData?.id,
            };
            options = [selectedClient, ...options.filter(item => item.id !== selectedClient.value)];
          }
          

          const uniqueOptions = options.reduce((acc, current) => {
            const existing = acc.find(item => item.value === current.value);
            if (existing) {
              // Compare the number of keys and keep the one with more information
              if (Object.keys(current).length > Object.keys(existing).length) {
                return acc.map(item => item.value === current.value ? current : item);
              }
              return acc;
            } else {
              acc.push(current);
              return acc;
            }
          }, []);


          setClientData(result);
          setClientOptions(uniqueOptions);
      })
      .catch((error) => {
          pushNotification(error?.response?.data?.detail, "error");
      });
  };

    

const fetchProjects = async (page = 1) => {
  const query = `is_active=true&page=${page}`;
  main_api.get(adminAPIsEndPoints.LIST_PROJECT(query))
    .then((response) => {
      const result = response?.data?.results;

      // Handle pagination
      if (response.data.next) {
        const urlObj = new URL(response.data.next);
        const params = new URLSearchParams(urlObj.search);
        const pageNumber = params.get('page');
        setProjectOptionsPage(pageNumber);
      } else {
        setProjectOptionsPage(null);
      }

       // Generate options array from API response
       let options = [...projectOptions, ...result?.map(project => ({
        ...project,
        label: project.name.charAt(0).toUpperCase() + project.name.slice(1),
        value: project.id,
      }))];


      // Ensure the selected contractor is included at the beginning of the options
      if (selectedProjectData) {
        const selectedProject = {
          label: selectedProjectData?.name.charAt(0).toUpperCase() + selectedProjectData?.name.slice(1),
          value: selectedProjectData?.id,
        };

        options = [selectedProject, ...options.filter(item => item.value !== selectedProject.value)];
      }

      // Filter out duplicate options and keep the one with more properties
      const uniqueOptions = options.reduce((acc, current) => {
        const existing = acc.find(item => item.value === current.value);
        if (existing) {
          // Compare the number of keys and keep the one with more information
          if (Object.keys(current).length > Object.keys(existing).length) {
            return acc.map(item => item.value === current.value ? current : item);
          }
          return acc;
        } else {
          acc.push(current);
          return acc;
        }
      }, []);

      setProjectData(result);
      setProjectOptions(uniqueOptions);
    })
    .catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
};


   
    const fetchDebrisTypes = async (page = 1) => {
      if (selectedProjectForUpdateRateMatrix) {
        const query = `disposal_sites__projects=${selectedProjectForUpdateRateMatrix}&is_active=true&page=${page}`;
        main_api.get(adminAPIsEndPoints.LIST_DEBRIS(query))
        .then((response) => {
        const result = response.data.results;
        if(response.data.next){
          const urlObj = new URL(response.data.next);
  
          // Get the search parameters
          const params = new URLSearchParams(urlObj.search);
  
            // Extract the page number
          const pageNumber = params.get('page');
  
          setDebrisTypeOptionsPage(pageNumber);
  
        } else{
          setDebrisTypeOptionsPage(null);
        }
        // Create options array from API response
       // Generate options from the result
        let options = [...debrisTypeOptions, ...result?.map(item => ({
          ...item,
          label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          value: item.id,
        }))];
  
      // If there are selected debris types, ensure they are included in the options
        if (updateRateMatrixValues?.debris_type) {
          const selectedOption = {
            ...updateRateMatrixValues.debris_type,
            label: updateRateMatrixValues.debris_type.name.charAt(0).toUpperCase() + updateRateMatrixValues.debris_type.name.slice(1),
            value: updateRateMatrixValues.debris_type.id,
          };
          options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
        }

         // Filter out duplicate options and keep the one with more properties
        const uniqueOptions = options.reduce((acc, current) => {
          const existing = acc.find(item => item.value === current.value);
          if (existing) {
            // Compare the number of keys and keep the one with more information
            if (Object.keys(current).length > Object.keys(existing).length) {
              return acc.map(item => item.value === current.value ? current : item);
            }
            return acc;
          } else {
            acc.push(current);
            return acc;
          }
        }, []);

        
        setDebrisTypeData(result);
        setDebrisTypeOptions(uniqueOptions);
    
        }).catch((error) => {
            
        pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setDebrisTypeData([]);
        setDebrisTypeOptions([]);
      }
  
    };


    useEffect(() => {
        fetchContractors();
        fetchClients();
        fetchProjects();
    }, []);

    useEffect(() => {
      if (selectedContractorData) {
        fetchContractors();
      }
    }, [selectedContractorData]);

    useEffect(() => {
      if (selectedClientData) {
        fetchClients();
      }
    }, [selectedClientData]);


    useEffect(() => {
        if (selectedProjectForUpdateRateMatrix) {
          fetchDebrisTypes();
        }
      }, [selectedProjectForUpdateRateMatrix]);

    
          

    //----------------------- Event Handlers -----------------------//

    const handleContractorChange = (value) => {
        const selectedContractor = contractorData.filter(item => item.id === value)[0];
        setSelectedContractorData(selectedContractor);
        // setSelectedContractorForUpdateRateMatrix(value);
        form.setFieldsValue({ contractor: value });
    }

    const handleClientChange = (value) => {
        const selectedClient = clientData.filter(item => item.id === value)[0];
        setSelectedClientData(selectedClient);
        // setSelectedClientForUpdateRateMatrix(value);
        form.setFieldsValue({ client: value });
    }

    const handleProjectChange = (value) => {
        const selectedProject = projectData.filter(item => item.id === value)[0];
        
        setSelectedProjectData(selectedProject);
        setDebrisTypeData([]);
        setDebrisTypeOptions([]);
        setSelectedProjectForUpdateRateMatrix(value);
        setSelectedClientForUpdateRateMatrix(selectedProject?.client?.id);
        setSelectedContractorForUpdateRateMatrix(selectedProject?.contractor?.id);
        
        setSelectedClientData(selectedProject?.client);
        setSelectedContractorData(selectedProject?.contractor);

        form.setFieldsValue({ project: value });
        form.setFieldsValue({ client: selectedProject?.client?.id });
        form.setFieldsValue({ contractor: selectedProject?.contractor?.id });
    }

    const handleDebrisTypeChange = (value) => {
        const selectedDebris = debrisTypeOptions.find(item => item.id === value);
        if (selectedDebris) {
          setFields(selectedDebris);
        }else {
          setFields({});
        }
        setSelectedDebrisTypeData(selectedDebris);
        setSelectedDebrisTypeForUpdateRateMatrix(value);
        form.setFieldsValue({ debris_type: value });
    }



    //-------------------------- Add Click --------------------------//

    const handleAddClick = () => {
      const projectValue = form.getFieldValue('project');
      const clientValue = form.getFieldValue('client');
      const contractorValue = form.getFieldValue('contractor');
      const debrisTypeValue = form.getFieldValue('debris_type');
      if (!projectValue) {
        message.error("Please select your Project.");
        return;
      }
      if (!clientValue) {
        message.error("Please select a Client.");
        return;
      }
      if (!contractorValue) {
        message.error("Please select Contractor.");
        return;
      }
      if (!debrisTypeValue) {
        message.error("Please select Debris Type.");
        return;
      }
      setShowUpdateRateMatrixForm(true);
    }

    //---------------------------- Dynamic Rate Matrix Fields ----------------------------//

    const generateRateMatrixFields = (fieldKey, fieldConfig, isRequired) => {
        const { label, type, unitKey } = fieldConfig;
      const fieldUnit = unitKey.replace("_unit", "");

      const unitCondition = selectedUnit === fieldUnit;

      

       if (isRequired && unitCondition) {
        
         return (
           <>
           <Heading text={label} margin="10px 0px 10px 0px" fontSize="0.75rem" color="#3B3B3B" />
  
           <div key={fieldKey} style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
             <div style={{display:"flex", flexDirection:"row", background:"#EBF0F7", borderRadius:"5px", padding:"20px"}}>
               <FormItem
                 name={`${fieldKey}_from`}
                 label="From"
                 rules={[
                   {
                     required: true,
                     message: `Please enter the value`,
                   },
                 ]}
               >
                 <InputNumber
                      placeholder="From"
                      style={{ width: "100%" }}
                      min={0} 
                      step={0.01} 
                  />
               </FormItem>
  
               <FormItem
                 name={`${fieldKey}_to`}
                 label="To"
                 style={{marginLeft:"10px"}}
                 rules={[
                   {
                     required: true,
                     message: `Please enter the value`,
                   },
                 ]}
               >
                 <InputNumber
                      placeholder="To"
                      style={{ width: "100%" }}
                      min={0} 
                      step={0.01} 
                  />
               </FormItem>
  
             </div>
          
           </div>
           </>
         );
       } else {
        return null;
        }

    };

 
    const generateUnitOptions = () => {
      return Object.entries(fields)
          .filter(([key, value]) => key.includes("_unit") && value === true && key !== "zero_value_unit")
          .map(([key]) => {
              const label = key.replace("_unit", ""); 
              const capitalizedLabel = label.toUpperCase();
              return {
                  label: capitalizedLabel,
                  value: key.replace("_unit", ""),
              };
          });
  };
  

  

   useEffect(() => {
      if (fields) {
        const unitOptions = generateUnitOptions();
        setUnitOptions(unitOptions);
      }
    }, [fields]);
  

  return (
    <CustomModal  
    open={isModalOpen}
    title={title}
    width="630px"
    height={showUpdateRateMatrixForm === true ? "585px" :"380px"}
    onCancel={() => {
      setModalOpen(false);
      form.resetFields();
    }}
    
    footer={null}
    maskClosable={false}
    // isScrollable={true}
  >
  <Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
    
    <Form name="updateRateMatrixForm" onFinish={onFinish} form={form} layout="vertical" >
    <FormWrapper height={showUpdateRateMatrixForm === true ? "585px" : "285"}>
        {showUpdateRateMatrixForm === false && !updateRateMatrixValues && (
          <>
          <FormItem 
            name="project" 
            label="Project"
            rules={[
              {
                required: true,
                message: "Please Select your Project",
              },
            ]} 
          >
            <PaginatedSelect 
              fetchData={fetchProjects} 
              placeholder="Select" 
              options={projectOptions} 
              value={selectedProjectForUpdateRateMatrix} 
              onChange={handleProjectChange} 
              pageNumber={projectOptionsPage} 
              style={{width:"100%"}}
            />
  
        </FormItem>
        <FormItem 
            name="debris_type" 
            label="Debris Type"
            rules={[
              {
                required: true,
                message: "Please Select Debris Type",
              },
            ]} 
          >
            <PaginatedSelect 
              fetchData={fetchDebrisTypes} 
              placeholder="Select" 
              options={debrisTypeOptions} 
              value={selectedDebrisTypeForUpdateRateMatrix} 
              onChange={handleDebrisTypeChange} 
              pageNumber={debrisTypeOptionsPage}
              style={{width:"100%"}}
            />
  
        </FormItem>
        <div style={{display:"flex", flexDirection:"row", }}>
          
          <FormItem 
              name="contractor" 
              label="Contractor"
              rules={[
                {
                  required: true,
                  message: "Please select your contractor",
                },
              ]} 
            >
              <PaginatedSelect 
              fetchData={fetchContractors} 
              placeholder="Select" 
              options={contractorOptions} 
              value={selectedContractorForUpdateRateMatrix} 
              disabled={true}
              onChange={handleContractorChange} 
              pageNumber={contractorOptionsPage}
              style={{width:"100%"}}/>
    
          </FormItem>

          <FormItem 
              name="client" 
              label="Client"
              style={{marginLeft:"10px"}}
              rules={[
                {
                  required: true,
                  message: "Please select your client",
                },
              ]} 
            >
              <PaginatedSelect 
              fetchData={fetchClients} 
              placeholder="Select" 
              options={clientOptions} 
              value={selectedClientForUpdateRateMatrix} 
              disabled={true}
              onChange={handleClientChange} 
              pageNumber={clientOptionsPage}
              style={{width:"100%"}}/>
    
          </FormItem>
        </div>

        
          </>
      )}

      {showUpdateRateMatrixForm === true && (
        <>
          <div style={{display:"flex", flexDirection:"column", background:"#EBF0F7", borderRadius:"5px", padding:"20px"}}>
            <span style={{display:"flex", flexDirection:"row" }}>
              <span style={{display:"flex", flexDirection:"row"}}>
                <Heading text="Contractor" margin="0px 0px 0px 0px" fontSize="0.75rem" color="#3B3B3B" />
                <Heading text={updateRateMatrixValues?.contractor !== null && updateRateMatrixValues?.contractor !== undefined ? updateRateMatrixValues?.contractor?.name : selectedContractorData?.name } margin="0px 0px 0px 10px" fontSize="0.75rem" color="#3669AE" />
              </span>
              <span style={{display:"flex", flexDirection:"row", marginLeft:"20px"}}>
                <Heading text="Client" margin="0px 0px 0px 0px" fontSize="0.75rem" color="#3B3B3B" />
                <Heading text={updateRateMatrixValues?.client !== null && updateRateMatrixValues?.client !== undefined ? updateRateMatrixValues?.client?.name : selectedClientData?.name} margin="0px 0px 0px 10px" fontSize="0.75rem" color="#3669AE" />
              </span>
            </span>
            <span style={{display:"flex", flexDirection:"row", marginTop:"10px"}}>
              <span style={{display:"flex", flexDirection:"row"}}>
                <Heading text="Project" margin="0px 0px 0px 0px" fontSize="0.75rem" color="#3B3B3B" />
                <Heading text={updateRateMatrixValues?.project !== null && updateRateMatrixValues?.project !== undefined ? updateRateMatrixValues?.project?.name : selectedProjectData?.name} margin="0px 0px 0px 10px" fontSize="0.75rem" color="#3669AE" />
              </span>
              <span style={{display:"flex", flexDirection:"row", marginLeft:"20px"}}>
                <Heading text="Debris Type" margin="0px 0px 0px 0px" fontSize="0.75rem" color="#3B3B3B" />
                <Heading text={updateRateMatrixValues?.debris_type !== null && updateRateMatrixValues?.debris_type !== undefined ? updateRateMatrixValues?.debris_type?.name : selectedDebrisTypeData?.name} margin="0px 0px 0px 10px" fontSize="0.75rem" color="#3669AE" />
              </span>
            </span>


          </div>

          <FormItem
               name={`unit_type`}
               label="Unit Type"
               style={{marginTop:"20px"}}
               rules={[
                 {
                   required: true,
                   message: `Please enter the value`,
                 },
               ]}
             >
               <Select 
                 options={unitOptions}
                 placeholder="Select"
                 style={{width:"100%"}}
                 onChange={(value) => setSelectedUnit(value)}
               />
          </FormItem>
     
         {(fields.each_unit === true && selectedUnit === "each") &&  ( <>
          
          <Heading text={"Unit"} margin="10px 0px 10px 0px" fontSize="0.75rem" color="#3B3B3B" />
            
            <div key={"unit_form"} style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
              <div style={{display:"flex", flexDirection:"row", background:"#EBF0F7", borderRadius:"5px", padding:"20px"}}>
                <FormItem
                  name={`unit_from`}
                  label="From"
                  rules={[
                    {
                      required: true,
                      message: `Please enter the value`,
                    },
                  ]}
                >
                  <InputNumber
                      placeholder="From"
                      style={{ width: "100%" }}
                      min={0} 
                      step={0.01} 
                  />
                </FormItem>

                <FormItem
                  name={`unit_to`}
                  label="To"
                  style={{marginLeft:"10px"}}
                  rules={[
                    {
                      required: true,
                      message: `Please enter the value`,
                    },
                  ]}
                >
                  <InputNumber
                      placeholder="To"
                      style={{ width: "100%" }}
                      min={0} 
                      step={0.01} 
                  />
                </FormItem>

              </div>
          
            </div>
          </>)}

          {fields && (
              Object.entries(fields?.rate_matrix_fields).map(([key, requirement]) => {
                  const fieldConfig = rateMatrixFieldsMap[key];

                  if (fieldConfig) {
                      const isRequired = requirement === "required" || requirement === "optional";
                      return generateRateMatrixFields(key, fieldConfig, isRequired);
                  }

                  return null; // If the field is not in the map, do not render anything
              })
          )}

           
             <FormItem
               name={`rate`}
               label="Rate"
               rules={[
                 {
                   required: true,
                   message: `Please enter the value`,
                 },
               ]}
             >
               <InputNumber placeholder='Rate' style={{width:"585px"}}/>
             </FormItem>

          

          
          </>
        )}
       
     
  
        </FormWrapper>
        <Divider/>
      <SaveContainer className="d-flex justify-content-end">
        <CustomButton
          btnText={"Cancel"}
          margin="0px 5px"
          noBackground
          hideIcon={true}
          onClick={() => setModalOpen(false)}
        />
        {
          showUpdateRateMatrixForm ? (
            <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
          ) 
          :
          (
            <Button
              type="button"
              onClick={handleAddClick}
              style={{background:"#3669AE", color:"white", border:"none", width:"80px", height:"40px"}}
            >
              Add
            </Button>
          )
        }
      </SaveContainer>
    </Form>
  </CustomModal>
  )
}
const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
  };
  
  const FormItem = styled(Form.Item)`
  width: 100%;
  `;
  
  const FormWrapper = styled.div`
  height: ${(props) => props.height};
  width:  600px;
  padding: 5px;
  overflow: auto;
  
  /* Custom scrollbar styling */
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #3669AE;
      border-radius: 2px;
      min-height: 30px; /* Ensure the thumb isn't too small */
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `;
  
  const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 625px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
  `;
  
  
  
  