import { useNavigate } from "react-router-dom";
import PermissionModal from "../components/modals/permission/permissionModal";
import { useState } from "react";


function NoPermissionPage() {
  const navigate = useNavigate();
  // const permissionPopUp = true;
  const [permissionPopUp, setPermissionPopUp] = useState(true);
  return (
    <>
      <PermissionModal 
        text={"You do not have permission to access this page"}
        onclose={() => {
          setPermissionPopUp(false);
          // navigate("/dashboard");
        }}
        openModal={permissionPopUp}
      />
    </>
  );
}

export default NoPermissionPage;
