

export const fieldsMap = {
    field_monitor_name: { label: "Field Monitor Name", type: "auto" },
    sub_activity: { label: "Sub-activity", type: "dropdown" },
    debris_type: { label: "Debris Type", type: "dropdown" },
    load_date: { label: "Load Date", type: "auto" },
    load_time: { label: "Load Time", type: "auto" },
    confirm_debris_type: { label: "Confirm Debris Type", type: "popup_message" },
    truck_number: { label: "Truck #", type: "scan" },
    address: { label: "Address", type: "auto" },
    disposal_site: { label: "Disposal Site", type: "dropdown" },
    pass_number: { label: "Pass Number", type: "numeric" },
    diameter: { label: "Diameter", type: "numeric", unitKey: "cyds_unit" },
    length: { label: "Length", type: "numeric", unitKey: "length_unit" },
    hazard_type: { label: "Hazard Type", type: "dropdown" },
    stumps_extraction: { label: "Stumps Extraction Required", type: "yes_no" },
    roe: { label: "ROE", type: "yes_no" },
    // roe_reason: { label: "ROE Reason", type: "alphanumeric" },
    tipping_fees: { label: "Tipping Fees", type: "numeric_currency" },
    freon_extracted: { label: "Freon Extracted", type: "yes_no" },
    vin_number: { label: "VIN/HIN", type: "alphanumeric" },
    photos_required: { label: "Photos Req'd", type: "quantity_required" },
    field_monitor_notes: { label: "Field Monitor Notes", type: "alphanumeric" },
    site_monitor_name: { label: "Site Monitor Name", type: "auto" },
    debris_site: { label: "Debris Site", type: "auto" },
    offload_truck_number: { label: "Truck #", type: "scan" },
    offload_date: { label: "Offload Date", type: "auto" },
    offload_time: { label: "Offload Time", type: "auto" },
    offload_confirm_debris_type: { label: "Confirm Debris Type", type: "dropdown" },
    load_call: { label: "Load Call", type: "numeric" },
    confirm_quantity: { label: "Confirm Quantity", type: "numeric" },
    tipping_ticket_number: { label: "Tipping Ticket Number", type: "numeric" },
    // tipping_fee: { label: "Tipping Fee", type: "numeric_currency" },
    load_call_photos: { label: "Load Call Photos", type: "quantity_required" },
    site_monitor_notes: { label: "Site Monitor Notes", type: "alphanumeric" },
    unit_of_measure: { label: "Unit of Measure", type: "auto" },
    mileage_required: { label: "Mileage Req'd", type: "yes_no", unitKey: "miles_unit" },
    weight: { label: "Weight", type: "numeric", unitKey: "weight_unit" },
};


export const rateMatrixFieldsMap = {
    diameter: { label: "Diameter", type: "numeric", unitKey: "size_unit" },
    weight: { label: "Weight", type: "numeric", unitKey: "weight_unit" },
    length: { label: "Length", type: "numeric", unitKey: "length_unit" },
    mileage: { label: "Mileage", type: "numeric", unitKey: "cyds_unit" },
    unit: { label: "Unit", type: "auto", unitKey: "each_unit" },
}




// export const getFieldValidationRules = (type, label, fieldKey, isRequired, isOptional, initialValue) => {
//     let rules = [];

//     // Add required validation rule if the field is mandatory
//     if (isRequired) {
//         rules.push({ required: true, message: `${label} is required` });
//     }

//     // Custom validator to check if the value has changed and whether validation is necessary
//     const validateOnChange = (_, value) => {
//         // If field is optional and value is empty, skip validation
//         if (isOptional && !value) {
//             return Promise.resolve();
//         }

//         // If value is unchanged, resolve the validation
//         if (value === initialValue) {
//             return Promise.resolve();
//         }

//         // Otherwise, we continue with validation based on the type
//         switch (type) {
//             case "numeric":
//                 if (isNaN(value)) {
//                     return Promise.reject(new Error(`${label} must be a number`));
//                 }
//                 break;

//             case "alphanumeric":
//                 if (!/^[a-zA-Z0-9]+$/.test(value)) {
//                     return Promise.reject(new Error(`${label} must be alphanumeric`));
//                 }
//                 break;

//             case "yes_no":
//                 if (value !== true && value !== false) {
//                     return Promise.reject(new Error(`${label} must be Yes or No`));
//                 }
//                 break;

//             case "numeric_currency":
//                 if (!/^\d+(\.\d{1,2})?$/.test(value)) {
//                     return Promise.reject(new Error(`${label} must be a valid currency format (e.g., 100.00)`));
//                 }
//                 break;

//             case "quantity_required":
//                 if (value < 1 || isNaN(value)) {
//                     return Promise.reject(new Error(`${label} must be at least 1`));
//                 }
//                 break;

//             case "vin_number":
//                 if (value.length < 3 || value.length > 20) {
//                     return Promise.reject(new Error(`${label} must be between 3 and 20 characters long`));
//                 }
//                 if (!/^[A-HJ-NPR-Z0-9]+$/.test(value)) {
//                     return Promise.reject(new Error(`${label} must contain only letters (excluding I, O, Q) and numbers`));
//                 }
//                 break;

//             case "dropdown":
//                 if (typeof value !== "string") {
//                     return Promise.reject(new Error(`${label} must be a valid selection`));
//                 }
//                 break;

//             default:
//                 break;
//         }

//         return Promise.resolve();
//     };

//     // Handle specific field type validations
//     switch (type) {
//         case "numeric":
//             rules.push({
//                 validator: validateOnChange,
//                 transform: (value) => value !== undefined && value !== '' ? Number(value) : value,
//             });
//             break;

//         case "alphanumeric":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         case "yes_no":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         case "numeric_currency":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         case "quantity_required":
//             rules.push({
//                 validator: validateOnChange,
//                 transform: (value) => value !== undefined && value !== '' ? Number(value) : value,
//             });
//             break;

//         case "vin":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         case "dropdown":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         default:
//             break;
//     }

//     return rules;
// };


export const getFieldValidationRules = (type, label, fieldKey, isRequired, isOptional, initialValue) => {
    let rules = [];

    // Add required validation rule if the field is mandatory
    if (isRequired) {
        rules.push({ required: true, message: `${label} is required` });
    }

    // Handle optional fields
    if (isOptional) {
        rules.push({
            validator: (_, value) => {
                // If the field is optional and the value is empty, skip validation
                if (!value) {
                    return Promise.resolve(); // No validation needed
                }
                
                // Perform specific validations based on fieldKey
                switch (fieldKey) {
                    case "pass_number":
                        if (!/^[0-9]$/.test(value)) {
                            return Promise.reject(new Error("Pass Number must be a single digit"));
                        }
                        if (parseInt(value, 10) > 5) {
                            return Promise.reject(new Error("Pass Number must not exceed 5"));
                        }
                        break;

                    case "diameter":
                        if (!/^\d+(\.\d+)?$/.test(value)) {
                            return Promise.reject(new Error("Diameter must be a valid number"));
                        }
                        if (value.length > 10) { // Example max length
                            return Promise.reject(new Error("Diameter must not exceed 10 characters"));
                        }
                        break;

                    case "length":
                        if (!/^\d+(\.\d+)?$/.test(value)) {
                            return Promise.reject(new Error("Length must be a valid number"));
                        }
                        if (value.length > 10) { // Example max length
                            return Promise.reject(new Error("Length must not exceed 10 characters"));
                        }
                        break;

                    case "vin_number":
                        if (!/^[A-HJ-NPR-Z0-9-]{3,20}$/.test(value)) {
                            return Promise.reject(new Error("VIN Number must be between 3 to 20 characters and only contain valid characters (excluding I, O, Q)."));
                        }
                        break;

                    case "tipping_fees":
                        if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                            return Promise.reject(new Error("Tipping Fee must be a valid currency format"));
                        }
                        if (value.length > 10) { // Example max length
                            return Promise.reject(new Error("Tipping Fee must not exceed 10 characters"));
                        }
                        break;

                    case "photos_required":
                        if (!/^\d+$/.test(value)) {
                            return Promise.reject(new Error("Photos Required must be numeric"));
                        }
                        if (value.length > 3) { // Example max length
                            return Promise.reject(new Error("Photos Required must not exceed 3 digits"));
                        }
                        break;

                    case "weight":
                        if (!/^\d+(\.\d+)?$/.test(value)) {
                            return Promise.reject(new Error("Weight must be a valid number"));
                        }
                        if (value.length > 10) { // Example max length
                            return Promise.reject(new Error("Weight must not exceed 10 characters"));
                        }
                        break;

                    case "tipping_ticket_number":
                        if (!/^\d+$/.test(value)) {
                            return Promise.reject(new Error("Tipping Ticket Number must be numeric"));
                        }
                        if (value.length > 15) { // Example max length
                            return Promise.reject(new Error("Tipping Ticket Number must not exceed 15 digits"));
                        }
                        break;

                    case "confirm_quantity":
                        if (!/^\d+$/.test(value)) {
                            return Promise.reject(new Error("Confirm Quantity must be numeric"));
                        }
                        if (value.length > 10) { // Example max length
                            return Promise.reject(new Error("Confirm Quantity must not exceed 10 digits"));
                        }
                        break;

                    case "load_call":
                        if (!/^\d+$/.test(value)) {
                            return Promise.reject(new Error("Load Call must be numeric"));
                        }
                        if (value.length > 6) { // Example max length
                            return Promise.reject(new Error("Load Call must not exceed 6 digits"));
                        }
                        break;

                    default:
                        break;
                }

                return Promise.resolve(); // If all validations pass
            }
        });
    } else {
        // If not optional, add the existing validation logic for required fields
        switch (fieldKey) {
            case "pass_number":
                rules.push({
                    validator: (_, value) => {
                       
                        if (!/^[0-9]$/.test(value)) {
                            return Promise.reject(new Error("Pass Number must be a single digit"));
                        }
                        if (parseInt(value, 10) > 5) {
                            return Promise.reject(new Error("Pass Number must not exceed 5"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            case "diameter":
                rules.push({
                    validator: (_, value) => {
                        
                        if (!/^\d+(\.\d+)?$/.test(value)) {
                            return Promise.reject(new Error("Diameter must be a valid number"));
                        }
                        if (value.length > 10) {
                            return Promise.reject(new Error("Diameter must not exceed 10 characters"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            case "length":
                rules.push({
                    validator: (_, value) => {
                       
                        if (!/^\d+(\.\d+)?$/.test(value)) {
                            return Promise.reject(new Error("Length must be a valid number"));
                        }
                        if (value.length > 10) {
                            return Promise.reject(new Error("Length must not exceed 10 characters"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            case "vin_number":
                rules.push({
                    validator: (_, value) => {
                       
                        if (!/^[A-HJ-NPR-Z0-9-]{3,20}$/.test(value)) {
                            return Promise.reject(new Error("VIN Number must be between 3 to 20 characters and only contain valid characters (excluding I, O, Q)."));
                        }
                        
                        return Promise.resolve();
                    },
                });
                break;

            case "tipping_fees":
                rules.push({
                    validator: (_, value) => {
                       
                        if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                            return Promise.reject(new Error("Tipping Fee must be a valid currency format"));
                        }
                        if (value.length > 10) {
                            return Promise.reject(new Error("Tipping Fee must not exceed 10 characters"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            case "photos_required":
                rules.push({
                    validator: (_, value) => {
                        
                        if (!/^\d+$/.test(value)) {
                            return Promise.reject(new Error("Photos Required must be numeric"));
                        }
                        if (value.length > 3) {
                            return Promise.reject(new Error("Photos Required must not exceed 3 digits"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            case "weight":
                rules.push({
                    validator: (_, value) => {
                       
                        if (!/^\d+(\.\d+)?$/.test(value)) {
                            return Promise.reject(new Error("Weight must be a valid number"));
                        }
                        if (value.length > 10) {
                            return Promise.reject(new Error("Weight must not exceed 10 characters"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            case "tipping_ticket_number":
                rules.push({
                    validator: (_, value) => {
                       
                        if (!/^\d+$/.test(value)) {
                            return Promise.reject(new Error("Tipping Ticket Number must be numeric"));
                        }
                        if (value.length > 15) {
                            return Promise.reject(new Error("Tipping Ticket Number must not exceed 15 digits"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            case "confirm_quantity":
                rules.push({
                    validator: (_, value) => {
                        if (!/^\d+$/.test(value)) {
                            return Promise.reject(new Error("Confirm Quantity must be numeric"));
                        }
                        if (value.length > 10) {
                         return Promise.reject(new Error("Confirm Quantity must not exceed 10 digits"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            case "load_call":
                rules.push({
                    validator: (_, value) => {
                        if (!/^\d+$/.test(value)) {
                            return Promise.reject(new Error("Load Call must be numeric"));
                        }
                        const numericValue = Number(value);
                        // Check if the numeric value is between 0 and 100
                        if (numericValue < 0 || numericValue > 100) {
                            return Promise.reject(new Error("Load Call must be between 0 and 100"));
                        }
                        return Promise.resolve();
                    },
                });
                break;

            default:
                break;
        }
    }

    return rules;
};


// export const getFieldValidationRules = (type, label, fieldKey, isRequired, isOptional, initialValue) => {
//     let rules = [];

//     // Add required validation rule if the field is mandatory
//     if (isRequired) {
//         rules.push({ required: true, message: `${label} is required` });
//     }

//     // Custom validator to check if the value has changed and whether validation is necessary
//     const validateOnChange = (_, value) => {
//         // If field is optional and value is empty, skip validation
//         if (isOptional && !value) {
//             return Promise.resolve();
//         }

//         // If value is unchanged, resolve the validation
//         if (value === initialValue) {
//             return Promise.resolve();
//         }

//         // Otherwise, we continue with validation based on the type
//         switch (type) {
//             case "numeric":
//                 if (isNaN(value)) {
//                     return Promise.reject(new Error(`${label} must be a number`));
//                 }
//                 break;

//             case "alphanumeric":
//                 if (!/^[a-zA-Z0-9]+$/.test(value)) {
//                     return Promise.reject(new Error(`${label} must be alphanumeric`));
//                 }
//                 break;

//             case "yes_no":
//                 if (value !== true && value !== false) {
//                     return Promise.reject(new Error(`${label} must be Yes or No`));
//                 }
//                 break;

//             case "numeric_currency":
//                 if (!/^\d+(\.\d{1,2})?$/.test(value)) {
//                     return Promise.reject(new Error(`${label} must be a valid currency format (e.g., 100.00)`));
//                 }
//                 break;

//             case "quantity_required":
//                 if (value < 1 || isNaN(value)) {
//                     return Promise.reject(new Error(`${label} must be at least 1`));
//                 }
//                 break;

//             case "vin_number":
//                 if (value.length < 3 || value.length > 20) {
//                     return Promise.reject(new Error(`${label} must be between 3 and 20 characters long`));
//                 }
//                 if (!/^[A-HJ-NPR-Z0-9]+$/.test(value)) {
//                     return Promise.reject(new Error(`${label} must contain only letters (excluding I, O, Q) and numbers`));
//                 }
//                 break;

//             case "dropdown":
//                 if (typeof value !== "string") {
//                     return Promise.reject(new Error(`${label} must be a valid selection`));
//                 }
//                 break;

//             default:
//                 break;
//         }

//         return Promise.resolve();
//     };

//     // Handle specific field type validations
//     switch (type) {
//         case "numeric":
//             rules.push({
//                 validator: validateOnChange,
//                 transform: (value) => value !== undefined && value !== '' ? Number(value) : value,
//             });
//             break;

//         case "alphanumeric":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         case "yes_no":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         case "numeric_currency":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         case "quantity_required":
//             rules.push({
//                 validator: validateOnChange,
//                 transform: (value) => value !== undefined && value !== '' ? Number(value) : value,
//             });
//             break;

//         case "vin_number":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         case "dropdown":
//             rules.push({
//                 validator: validateOnChange,
//             });
//             break;

//         default:
//             // Additional fieldKey-specific validation logic can go here
//             switch (fieldKey) {
//                 case "offloadTruck":
//                     rules.push({
//                         validator: validateOnChange,
//                         // Additional validations for Offload Truck #
//                     });
//                     break;

//                 case "offloadDate":
//                     rules.push({
//                         validator: validateOnChange,
//                         // Additional validations for Offload Date
//                     });
//                     break;

//                 case "offloadTime":
//                     rules.push({
//                         validator: validateOnChange,
//                         // Additional validations for Offload Time
//                     });
//                     break;

//                 case "confirmQuantity":
//                     rules.push({
//                         validator: validateOnChange,
//                         // Additional validations for Confirm Quantity
//                     });
//                     break;

//                 // Add cases for other specific fieldKeys as needed
//                 default:
//                     break;
//             }
//             break;
//     }

//     return rules;
// };
