import React, { useEffect, useState } from 'react'
import { Button, Card, Switch } from 'antd'
import styled from 'styled-components';
import HeadingComponent from '../headingComponent/heading';
import { pushNotification } from '../../util/notification';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import PaginatedSelect from '../customSelect/paginatedSelect';
import CompanyBrandLogoAnimated from '../companyBrandLogoAnimated/companyBrandLogoAnimated';


export default function TicketsReport() {

  //---------------------------- States ----------------------------//

  const [projectOptions, setProjectOptions] = useState([]);
  const [projectOptionsPage, setProjectOptionsPage] = useState(1);
  const [projectSelected, setProjectSelected] = useState(null);
  const [includePhotos, setIncludePhotos] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //---------------------------- Functions ----------------------------//

  const fetchProjectOptions = async (page = 1) => {
    const query = `page=${page}`;
    main_api.post(adminAPIsEndPoints.DASHBOARD_PROJECT(query))
      .then((response) => {
        const result = response?.data?.results;
  
        // Handle pagination
        if (response.data.next) {
          const urlObj = new URL(response.data.next);
          const params = new URLSearchParams(urlObj.search);
          const pageNumber = params.get('page');
          setProjectOptionsPage(pageNumber);
        } else {
          setProjectOptionsPage(null);
        }
  
         // Generate options array from API response
         let options = [...projectOptions, ...result?.map(project => ({
          ...project,
          label: project.name.charAt(0).toUpperCase() + project.name.slice(1),
          value: project.id,
        }))];
  
  
        // Filter out duplicate options and keep the one with more properties
        const uniqueOptions = options.reduce((acc, current) => {
          const existing = acc.find(item => item.value === current.value);
          if (existing) {
            // Compare the number of keys and keep the one with more information
            if (Object.keys(current).length > Object.keys(existing).length) {
              return acc.map(item => item.value === current.value ? current : item);
            }
            return acc;
          } else {
            acc.push(current);
            return acc;
          }
        }, []);
  
        setProjectOptions(uniqueOptions);
      })
      .catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
  };

  const handleReportDownload = () => {
    if (projectSelected) {
        const payload = {
            project_id: projectSelected,
            include_photos: includePhotos
        }
        main_api.post(adminAPIsEndPoints.TICKETS_REPORT_PDF,payload)
        .then((response) => {
            if (response?.status === 200) {
            pushNotification("Report downloaded and sent to your registered email successfully!", "success");
            setIsLoading(false);
            }
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
    }
  };

  //---------------------------- Use Effects ----------------------------//

  useEffect(() => {
    fetchProjectOptions();
}, []);

  return (
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <Heading text="Tickets Report" margin="0px 0px 15px 5px" fontSize="1.3rem" color="#3B3B3B" />
        <div style={{display: "flex", flexDirection: "row"}}>
                <PaginatedSelect
                  value={projectSelected}
                  placeholder={"Select Project"}
                  options={projectOptions}
                  onChange={(e) => setProjectSelected(e)}
                  fetchData={fetchProjectOptions}
                  pageNumber={projectOptionsPage}
                  style={{marginLeft:"8px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
                />
                <CustomSwitch
                  checked={includePhotos}
                  onChange={(e) => setIncludePhotos(e)}
                  checkedChildren={
                    <span style={{ position: "relative", top: "8px", right: "5px" }}>
                      Photos
                    </span>
                  }
                  unCheckedChildren={
                    <span style={{color: "#667085", position: "relative", top: "8px", left: "5px" }}>
                      Photos
                    </span>
                  }
                />

                <div
                  onClick={() => {
                    setProjectSelected(null);
                    setIncludePhotos(null);
                    setIsLoading(true);
                  }}
                  style={{cursor:"pointer",color:"#EE3E41",marginLeft:"15px", marginBottom: "20px", position:"relative", top:"20px", left:"6px", width:"80px", height:"40px"}}
                >
                  Reset Filters
                </div>
                <Button onClick={handleReportDownload} type="primary" style={{
                  marginLeft: "8px",
                  marginBottom: "20px",
                  position: "relative",
                  top: "12px",
                  left: "590px",
                  width: "150px",
                  height: "40px",
                  color: "white",
                  borderRadius: "8px"
                }}>
                  Download Report
                </Button>

        </div>

            {
              isLoading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "450px"}}>
                  <Heading text="Please select a Project to download the Report" fontSize="1.3rem" color="grey" />
                </div>
                :
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "450px"}}>
                  <span>
                    <CompanyBrandLogoAnimated />
                    <Heading text="Your report will be sent to your registered email address." fontSize="1.3rem" color="grey" margin="25px 0px" />
                  </span>
                </div>
            }
    </CustomCard>
  )
}


const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};


const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1270px;
  height: calc(100vh - 40px);
  max-height: 720px;
  margin: 20px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;


const CustomSwitch = styled(Switch)`
  &.ant-switch {
    width: 84px;
    height: 40px;
    position: relative;
    top: 12px;
    left: 6px;
    margin-left: 8px;
    margin-bottom: 20px;
  }

  &.ant-switch .ant-switch-handle {
    position: absolute;
    top: 8px;
    inset-inline-start: 2px;
    width: 23px;
    height: 23px;
    border-radius: 8%;
    transition: all 0.2s ease-in-out;
  }

  &.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 28px);
  }

  background-color: ${props => (props.checked ? "#3669AE" : "#BAC4CF")};
`;