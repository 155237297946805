import React from "react";
import { CheckOutlined } from "@ant-design/icons"; // Ant Design's check mark icon
import { ReactComponent as CompanyBrandIcon } from "../../assets/rawSvg/companyLogo.svg";
import styles from './companyBrandLogoAnimated.module.css';
export default function CompanyBrandLogoAnimated () {
  return (
    <div className={styles.container}>
      {/* Company Brand Icon */}
      <CompanyBrandIcon
        className={styles.companyIcon} // Apply CSS module class
      />
      {/* Check Mark */}
      <CheckOutlined
        className={styles.checkIcon} // Apply CSS module class
      />
    </div>
  );
};

