import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { Button, Divider, Form, Input, InputNumber, Segmented, Select, Switch } from "antd";
import { CustomModal } from '../../customModal/customModal';
import CustomButton from '../../customButton/customButton';
import { countryCodes } from '../../../util/dropdownData';
import CustomSwitch from '../../customSwitch/customSwitch';
import HeadingComponent from '../../headingComponent/heading';
import LocationSelector from '../../customAddressInput/locationSelector';
import { useSelector } from 'react-redux';
import { getUserPositionList } from '../../../util/dataService';
import { main_api } from '../../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../../constants/apiEndPoints';
import PaginatedSelect from '../../customSelect/paginatedSelect';
import { pushNotification } from '../../../util/notification';
import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as PlusIcon } from "../../../assets/rawSvg/Plus.svg";
import { ReactComponent as CrossIcon } from "../../../assets/rawSvg/Cross.svg";



export default function UpdateContractor({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    editContractorValues,
    selectedPosition,
    setSelectedPosition,
    padding = "20px",
}) {


    const [selectTab, setSelectTab] = useState(null);


    const [address, setAddress] = useState("");
    const [addressLatAndLong, setAddressLatAndLong] = useState([null,null]);
    const [active, setActive] = useState(editContractorValues?.is_active || true);
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const [userRole, setUserRole] = useState(editContractorValues?.roleData?.id || null);
    const [positionType, setPositionType] = useState(editContractorValues?.positionData?.name || null);
    const [roleType, setRoleType] = useState(null);
    const [positionList, setPositionList] = useState([]);
    const [query, setQuery] = useState("");
    const [primeContractorList, setPrimeContractorList] = useState([]);
    const [primeContractor, setPrimeContractor] = useState();
    const [windowSize, setWindowSize] = useState({ height: window.innerHeight, width: window.innerWidth });
    const rolesState = useSelector((state) => state.roles.roles);
    const [subContractorId, setSubContractorId] = useState(null);
    
    
    const roles = rolesState.map((role) => ({
      label: role.name,
      value: role.id,
    }));
    

    const contractorId = rolesState.filter((role) => role.type === "contractor").map((role) => role.id);
    
    const [form] = Form.useForm();
    
    const fetchData = async ( page = 1,) => {
      
     if (contractorId[0] === undefined || contractorId[0] === null) {
       return;
     }
    const query = `role=${contractorId[0]}`;
      main_api.get(`${adminAPIsEndPoints.LIST_POSITION(query)}&page=${page}`)
      .then((response) => {
        const result = response.data.results;
        const positions = result?.map((position) => ({
          label: position.name,
          value: position.id,
        }));
        setSubContractorId(positions.filter((position) => position.label === "Sub Contractor")[0]?.value);
        setSelectTab(positions.filter( (position) => position?.label === editContractorValues?.positionData?.name)[0]?.value || positions.find((position) => position?.label === "Prime Contractor")?.value);
        setSelectedPosition(positions.find((position) => position?.label === "Prime Contractor"));
        setPositionList(positions.sort((a, b) => a.label.localeCompare(b.label)));
        
      }).catch((error) => {
        pushNotification(error.response.data.detail, "error");
      });
    
    }
    
    const fetchPrimeContractor = async ( page = 1,) => {
      const query = `is_active=true&page=${page}`;
      main_api.get(adminAPIsEndPoints.LIST_CONTRACTORS(query))
      .then((response) => {
        const result = response.data.results;
        const primeContractors = result?.map((primeContractor) => ({
          label: primeContractor.name,
          value: primeContractor.id,
        }));
        
        setPrimeContractorList(primeContractors);
      }).catch((error) => {
        pushNotification(error.response.data.detail, "error");
      });
    
    }

    
    


    useEffect(() => {
      fetchData();
      fetchPrimeContractor();
      
    }, []);


   
  


    useEffect(() => {
      if (editContractorValues) {
        
          form.setFieldsValue({
              company_name: editContractorValues?.company_name,
              prime_contractor: editContractorValues?.prime_contractor?.id,
              prefix: editContractorValues?.prefix,
              phone_number: editContractorValues?.phone_number,
              password: editContractorValues?.password,
              confirm_password: editContractorValues?.confirm_password,
              notes: editContractorValues?.additional_data?.notes,
              address_1: editContractorValues?.address,
              latitude: editContractorValues?.latitude,
              longitude: editContractorValues?.longitude,
              is_active: editContractorValues?.is_active,
              email_ticket_receipt: editContractorValues?.additional_data?.email_ticket_receipt?.map((email) => 
                email || []
              ) || [],
              name: editContractorValues?.name,
              email: editContractorValues?.email,
              position: editContractorValues?.position?.name || selectTab,
          });
      } else {
        form.setFieldsValue({
          email_ticket_receipt: [''],
        });
      }
  }, [editContractorValues, form]);
  

  useEffect(() => {
    if(addressLatAndLong){

      let latitude = addressLatAndLong[0] || null;
      form.setFieldValue("latitude", latitude);

      let longitude = addressLatAndLong[1] || null;
      form.setFieldValue("longitude", longitude);
    }
  }, [addressLatAndLong]);

    const handleSwitchChange = (checked) => {
      setActive(checked);
      form.setFieldsValue({ is_active: checked }); 
      
    };

    const handleRoleChange = (value,option) => {
      setUserRole(value);
      const type = rolesState.filter((role) => role.name === option.label).map((role) => role.type);
      setRoleType(type[0]);

      setPositionType(null);
      form.setFieldsValue({ position: null });
      
    }

    const handlePositionChange = (value,option) => {
      if (option.label === "Sub Contractor") {
        setQuery(`role=${userRole}`);
      }
      if (option.label === "Sub Contractor" || option.label === "Prime Contractor") {
        setPositionType(option.label);  
      }else{
      setPositionType(null);
      }
    };

    const handlePrimeContractorChange = (value,option) => {
      setPrimeContractor(value);
    };

    const updateData = (values) =>{
      
      onFinish(values, roleType, positionType)
    }

  return (
<CustomModal  
  open={isModalOpen}
  title={title}
  width="630px"
  height="580px"
  onCancel={() => {
    setModalOpen(false);
    form.resetFields();
  }}
  
  footer={null}
  maskClosable={false}
  // isScrollable={true}
>
<Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
  {!editContractorValues && (<Segmented
          options={positionList}
          onChange={(e) => {
            const selectedPosition = positionList.find(position => position.value === e);
            
            setSelectedPosition(selectedPosition);
            setSelectTab(e);
          }}
          value={selectTab}
          size="large"
          className="p-1"
          style={{marginBottom:"20px"}}
  />)}
  <Form name="updateContractorForm" onFinish={onFinish} form={form} layout="vertical" >
    <FormWrapper>
      
    {
  (selectedPosition?.label === "Sub Contractor" || 
  (editContractorValues && editContractorValues?.type && editContractorValues?.type !== "Prime Contractor")) ? (
    <FormItem 
      name="prime_contractor" 
      label="Prime Contractor"
      rules={[
        {
          required: true,
          message: "Please select your prime contractor",
        },
      ]} 
    >
      <PaginatedSelect 
        fetchData={fetchPrimeContractor} 
        placeholder="Select" 
        options={primeContractorList} 
        onChange={handlePrimeContractorChange} 
        style={{width:"100%"}} 
      />
    </FormItem>
  )
  :
  null
}
      <FormItem 
        name="company_name"
        label="Company Name"
        rules={[
          {
            required: true,
            message: "Please enter event name",
          },
          {
            min: 3,
            max: 50,
            message: "Event name should be between 3 to 50 characters",
          }
        ]}
      >
        <Input placeholder="Enter Company Name" />
      </FormItem>
     
      <Form.Item
        name="prefix"
        label="Prefix"
        rules={[
          {
            min: 2,
            max: 10,
          },
          { 
          required: true, 
          message: 'Please enter the prefix' 
        }
      ]}
      >
        <Input placeholder="Enter Prefix" />
      </Form.Item>

       {/* <FormItem 
      name="phone_number"
      label="Telephone Number"
      rules={[
        {
          required: true,
          message: "Please enter your phone number",
        },
        {
          pattern: /^[0-9-+() ]*$/,
          message: "Phone number can only contain numbers, spaces, and the characters +, -, and ()",
        },
        {
          min: 10,
          max: 15,
          message: "Phone number must be between 10 and 15 characters",
        },
      ]}
    >
      <Input placeholder='Enter Telephone Number' />
    </FormItem> */}
    <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginRight:"8px"}}>
     {!editContractorValues && (<>
     
      <FormItem name="password" label="Password"
        rules={[
          {
            required: true,
            message: "Please enter your password",
          },
          {
            min: 8,
            message: "Password must be at least 8 characters long",
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
            message: "Password must contain uppercase, lowercase, and a number",
          },
        ]}
      >
          <Input.Password placeholder="Enter Password" />
      </FormItem>
      <FormItem name="confirm_password" 
        rules={[
          {
            required: true,
            message: "Please confirm your password",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Passwords do not match"));
            },
          }),
        ]}
        label="Confirm Password" style={{marginLeft:"5px"}}>
          <Input.Password placeholder="Confirm Password" />
      </FormItem>
     </>)}
    </div>
      <FormItem
        name="notes"
        label="Notes"
      >
        <TextArea placeholder="Add Notes" rows={3} />
      </FormItem>
      <span style={{marginRight:"8px"}}>

        <LocationSelector
            address={address}
            setAddress={setAddress}
            setAddressLatAndLong={setAddressLatAndLong}
            form={form}
            checked={false}
            label='Address'
            addressLatAndLong={addressLatAndLong}
            name='address'
          />
      </span>
      <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
        <FormItem name="latitude" label="Latitude">
            <Input value={addressLatAndLong[0] || null} disabled={true} />
        </FormItem>
        <FormItem name="longitude" label="Longitude" style={{marginLeft:"5px"}}>
            <Input value={addressLatAndLong[1] || null} disabled={true} />
        </FormItem>
      </div>

    <FormItem name="is_active" label="Status">
      <CustomSwitch initialStatus={active} onChange={handleSwitchChange} />
    </FormItem>
    
    <Heading text="Email Ticket Receipts To" fontSize="0.875rem" fontWeight={700} margin={"15px 0px 4px 0px"}/>
    <Form.List name="email_ticket_receipt">
  {(fields, { add, remove }) => (
    <>
      {fields.map(({ key, name, ...restField }) => (
        <div key={key} style={{ display: "flex", alignItems: "center", marginBottom: "8px", marginRight:"4px", width:"570px" }}>
          <FormItem
            {...restField}
            name={name}  // Change this line
            label="Email"
            style={{ marginRight: "8px" }}
            rules={[
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input placeholder="Enter Email" />
          </FormItem>
          {showRemoveButton === true && fields.length > 1 && (
            <Button
              type="button"
              onClick={() => remove(name)}
              icon={<CrossIcon />}
              style={{
                background: "#FCE0E0",
                marginLeft: "8px",
                padding: "0 8px",
                cursor: "pointer",
                width: "6%",
                marginTop: "5px"
              }}
            />
          )}
        </div>
      ))}
      <div
        onClick={() => {
          add();
          setShowRemoveButton(true);
        }}
        style={{
          color: "#3669AE",
          cursor: "pointer",
          position: "relative",
          top: "0px",
          right: "0px",
          fontSize: "0.875rem",
          fontWeight: 700,
          margin: "15px 0px 4px 0px",
        }}
      >
        <PlusIcon />
        <span
          style={{
            borderBottom: "1px solid #3669AE",
          }}
        >
          Add More
        </span>
      </div>
    </>
  )}
</Form.List>


    <Heading text="Contractor's Contact" fontSize="0.875rem" fontWeight={700} margin={"15px 0px 4px 0px"}/>
    <FormItem
      name="name"
      label="Name"
      rules={[
        {
          required: true,
          message: "Please enter the name",
        },
      ]}
    >
      <Input placeholder="Enter Name" />
    </FormItem>
    <FormItem
      name = "email"
      label="Email"
      rules={[
        {
          required: true,
          type: "email",
          message: "Please enter a valid email",
        },
      ]}
    >
      <Input placeholder="Enter Email" disabled={editContractorValues ? true : false}/>
    </FormItem>


    <FormItem 
      name="phone_number"
      label="Telephone Number"
      rules={[
        {
          required: true,
          message: "Please enter your phone number",
        },
        {
          pattern: /^[0-9-+() ]*$/,
          message: "Phone number can only contain numbers, spaces, and the characters +, -, and ()",
        },
        {
          min: 10,
          max: 15,
          message: "Phone number must be between 10 and 15 characters",
        },
      ]}
    >
      <Input placeholder='+14155552671' />
    </FormItem>
   

      </FormWrapper>
      <Divider/>
    <SaveContainer className="d-flex justify-content-end">
      <CustomButton
        btnText={"Cancel"}
        margin="0px 5px"
        noBackground
        hideIcon={true}
        onClick={() => setModalOpen(false)}
      />
      <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
    </SaveContainer>
  </Form>
</CustomModal>
  )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const FormItem = styled(Form.Item)`
width: 100%;
`;

const FormWrapper = styled.div`
height: 585px;
width:  600px;
padding: 5px;
overflow: auto;

/* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SaveContainer = styled.div`
position: absolute;
bottom: 4px;
right: 4px;
width: 625px;
border-top: 1px solid #E0E0E0;
background-color: white;
padding: 10px 20px;
`;








