import React, { useEffect, useState } from 'react'
import { Button, Divider, Form, InputNumber, Modal, Select } from "antd";
import CustomButton from "../../customButton/customButton";
import HeadingComponent from "../../headingComponent/heading";
import { CustomModal } from '../../customModal/customModal';
import { calculationTypeOptions, truckTypeOptions } from '../../../util/dropdownData';
import styled from 'styled-components';
import { pushNotification } from '../../../util/notification';
export default function TruckCapacity({
    isModalOpen,
    setModalOpen,
    title,
    truckCapacity,
    setTruckCapacity,
    }) {
    
        const [truckType, setTruckType] = useState("simple");
        const [calculationType, setCalculationType] = useState("addition");
        const [calculatedTruckCapacity, setCalculatedTruckCapacity] = useState(0);

        const [form] = Form.useForm(); 

        useEffect(() => {
            form.setFieldsValue({
                truckType: truckType,
                u_shaped_calculation: calculationType,
            });
        }, [truckType, calculationType, form]);

        const handleCalculateTruckCapacity = (values) => {
            const { truckType, length, width, height, length2, width2, height2 } = values;
            
            let truckCapacity = 0;
            if (truckType === "simple") {
                // Volume in cubic inches: length * width * height
                truckCapacity = length * width * height;
                // Cubic inches to cubic yards: 1 cubic yard = 46656 cubic inches
                truckCapacity = truckCapacity / 46656;
                truckCapacity = parseFloat(truckCapacity.toFixed(3));
                setCalculatedTruckCapacity(truckCapacity);
            } else {
                // Volume in cubic inches: length * width * height
                let firstPortion = (length * width * height);
                firstPortion = firstPortion / 46656;
                let secondPortion = (length2 * width2 * height2);
                secondPortion = secondPortion / 46656;
                // Cubic inches to cubic yards: 1 cubic yard = 46656 cubic inches
                if (calculationType === "addition") {
                    truckCapacity = firstPortion + secondPortion;
                } else {
                    truckCapacity = Math.abs(firstPortion - secondPortion);
                }
                truckCapacity = parseFloat(truckCapacity.toFixed(3));
                setCalculatedTruckCapacity(truckCapacity);
            }
            if (truckCapacity < 1) {
                pushNotification("Error: Invalid dimensions!", "error");
            } else {
                pushNotification("Truck capacity calculated successfully! Save Changes to proceed", "success");
                
            }
        };

        const handleSubmit = () => {
            if (calculatedTruckCapacity !== null && calculatedTruckCapacity !== undefined) {
                setTruckCapacity(calculatedTruckCapacity);
                setModalOpen(false);
            }
        };

    

    return (
        <CustomModal  
            open={isModalOpen}
            centered={true}
            title={title}
            width="630px"
            height="480px"
            onCancel={() => {
                form.resetFields();
                setModalOpen(false);
            }}
            
            footer={null}
            maskClosable={false}
            // isScrollable={true}
        >
        <Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>

        <Form
            form={form}
            layout="vertical"
            name="calculateTruckCapacity"
            onFinish={handleCalculateTruckCapacity}
        >
            <FormWrapper>



            <FormItem
                label="Trailer Type"
                name="truckType"
                rules={[{ required: true, message: "Please select truck type!" }]}
            >
                <Select
                    placeholder="Select Truck Type"
                    defaultValue={truckTypeOptions[0].value}
                    value={truckType}
                    onChange={(value) => setTruckType(value)}
                    options={truckTypeOptions}
                    style={{ width: "590px"}}
                />
                
            </FormItem>
            <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
                
            <FormItem
                label='Length (in inches)'
                name='length'
                rules={[
                    {
                      validator: (_, value) => {
                        // Required check (value must be present)
                        if (value === undefined || value === null) {
                          return Promise.reject(new Error("Please enter length!"));
                        }
                
                        // Check if the value is greater than 0
                        if (value <= 0) {
                          return Promise.reject(new Error("Length must be greater than 0!"));
                        }
                
                        // Validation passed
                        return Promise.resolve();
                      }
                    }
                  ]}
                
            >
                <InputNumber placeholder='Enter Length' style={{ width: "100%" }} />
            </FormItem>

            <FormItem
                label='Width (in inches)'
                name='width'
                rules={[
                    {
                      validator: (_, value) => {
                        // Required check (value must be present)
                        if (value === undefined || value === null) {
                          return Promise.reject(new Error("Please enter width!"));
                        }
                
                        // Check if the value is greater than 0
                        if (value <= 0) {
                          return Promise.reject(new Error("Width must be greater than 0!"));
                        }
                
                        // Validation passed
                        return Promise.resolve();
                      }
                    }
                  ]}
                style={{marginLeft:"5px"}}    
            >
                <InputNumber placeholder='Enter Width' style={{ width: "100%" }} />
            </FormItem>

            <FormItem
                label='Height (in inches)'
                name='height'
                rules={[
                    {
                      validator: (_, value) => {
                        // Required check (value must be present)
                        if (value === undefined || value === null) {
                          return Promise.reject(new Error("Please enter height!"));
                        }
                
                        // Check if the value is greater than 0
                        if (value <= 0) {
                          return Promise.reject(new Error("Height must be greater than 0!"));
                        }
                
                        // Validation passed
                        return Promise.resolve();
                      }
                    }
                ]}
                style={{marginLeft:"5px"}}    

            >
                <InputNumber placeholder='Enter Height' style={{ width: "100%" }} />
            </FormItem>
            </div>


            {
                truckType === "u-shaped" && (
                    <>
                    <FormItem
                        label="U-Shaped Calculation"
                        name="u_shaped_calculation"
                        rules={[{ required: true, message: "Please select truck type!" }]}
                    >
                        <Select
                            placeholder="Select Calculation Type"
                            defaultValue={calculationTypeOptions[0].value}
                            value={calculationType}
                            onChange={(value) => setCalculationType(value)}
                            options={calculationTypeOptions}
                            style={{ width: "590px"}}
                        />
                        
                    </FormItem>
                    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
                    
                    
                    <FormItem
                        label='Length (in inches)'
                        name='length2'
                        rules={[
                            {
                              validator: (_, value) => {
                                // Required check (value must be present)
                                if (value === undefined || value === null) {
                                  return Promise.reject(new Error("Please enter length!"));
                                }
                        
                                // Check if the value is greater than 0
                                if (value <= 0) {
                                  return Promise.reject(new Error("Length must be greater than 0!"));
                                }
                        
                                // Validation passed
                                return Promise.resolve();
                              }
                            }
                        ]}
                    >
                        <InputNumber placeholder='Enter Length' style={{ width: "100%" }} />
                    </FormItem>

                    <FormItem
                        label='Width (in inches)'
                        name='width2'
                        rules={[
                            {
                              validator: (_, value) => {
                                // Required check (value must be present)
                                if (value === undefined || value === null) {
                                  return Promise.reject(new Error("Please enter width!"));
                                }
                        
                                // Check if the value is greater than 0
                                if (value <= 0) {
                                  return Promise.reject(new Error("Width must be greater than 0!"));
                                }
                        
                                // Validation passed
                                return Promise.resolve();
                              }
                            }
                        ]}
                        style={{marginLeft:"5px"}}    

                    >
                        <InputNumber placeholder='Enter Width' style={{ width: "100%" }} />
                    </FormItem>

                    <FormItem
                        label='Height (in inches)'
                        name='height2'
                        rules={[
                            {
                              validator: (_, value) => {
                                // Required check (value must be present)
                                if (value === undefined || value === null) {
                                  return Promise.reject(new Error("Please enter height!"));
                                }
                        
                                // Check if the value is greater than 0
                                if (value <= 0) {
                                  return Promise.reject(new Error("Height must be greater than 0!"));
                                }
                        
                                // Validation passed
                                return Promise.resolve();
                              }
                            }
                        ]}
                        style={{marginLeft:"5px"}}    

                    >
                        <InputNumber placeholder='Enter Height' style={{ width: "100%" }} />
                    </FormItem>
                    </div>
                    </>

                )
            }
            <span style={{display:"flex", flexDirection:"row"}}>
            <Heading
                text={`Calculated Truck Capacity: ${calculatedTruckCapacity} cubic yards`}
                margin="10px 0px"
                fontSize="0.75rem"
                color="#3B3B3B"
            />
            <CustomButton btnText={"Calculate"} margin={"0px 0px 0px 10px"} color={"white"} type='submit' hideIcon={true} />
            </span>

            </FormWrapper>

            <Divider/>

            <SaveContainer className="d-flex justify-content-end">
                <CustomButton
                    btnText={"Cancel"}
                    margin="0px 5px"
                    noBackground
                    hideIcon={true}
                    onClick={() => setModalOpen(false)}
                />

                <CustomButton btnText={"Save Changes"} color={"white"} onClick={handleSubmit} hideIcon={true} />
            </SaveContainer>
        </Form>

        </CustomModal>
       
    );
}


const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B", position, top, bottom, right, left }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} position={position} top={top} bottom={bottom} right={right} left={left} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const FormWrapper = styled.div`
  height: 280px;
  width:  600px;
  overflow: auto;

 /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 625px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;
