import { ReactComponent as ArrowRightIcon } from "../../assets/rawSvg/greaterThanIcon.svg";


export const administrationMenuData = [
  {
    itemList: [
      {
        key: '0-0',
        itemName: "Debris Type",
        itemIcon: <ArrowRightIcon />,
        active: true,
      },
      {
        key: '0-1',
        itemName: "Truck Type",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-2',
        itemName: "Sub-Activity",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-3',
        itemName: "Hazard",
        itemIcon: <ArrowRightIcon />,
      },
      // {
      //   key: '0-4',
      //   itemName: "Assessment Hazard",
      //   itemIcon: <ArrowRightIcon />,
      // },
      {
        key: '0-4',
        itemName: "User Role",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-5',
        itemName: "Position",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-6',
        itemName: "Menu Setup",
        itemIcon: <ArrowRightIcon />,
      },
    ],
  },
];


export const reportsMenuData = [
  {
    itemList: [
      {
        key: '0-0',
        itemName: "Daily Summary Report (PDF)",
        itemIcon: <ArrowRightIcon />,
        reportType: ['pdf'],
        requiredPermission: 'daily_summary_pdf',
        active: true,
      },
      {
        key: '0-1',
        itemName: "Tickets Report (PDF)",
        itemIcon: <ArrowRightIcon />,
        reportType: ['pdf'],
        requiredPermission: 'tickets_pdf',
      },
      {
        key: '0-2',
        itemName: "Truck Report (PDF)",
        itemIcon: <ArrowRightIcon />,
        reportType: ['pdf'],
        requiredPermission: 'truck_pdf',
      },
      {
        key: '0-3',
        itemName: "Disposal Site Ticket Report (PDF)",
        itemIcon: <ArrowRightIcon />,
        reportType: ['pdf'],
        requiredPermission: 'disposal_site_ticket_pdf',
      },
      {
        key: '0-4',
        itemName: "List of Disposal Site Report (XLS)",
        itemIcon: <ArrowRightIcon />,
        reportType: ['xls'],
        requiredPermission: 'disposal_site_sheet',
      },
      {
        key: '0-5',
        itemName: "Debris Load Report (XLS)",
        itemIcon: <ArrowRightIcon />,
        reportType: ['xls'],
        requiredPermission: 'debris_load_sheet',
      },
      {
        key: '0-6',
        itemName: "Debris Cost Report (XLS)",
        itemIcon: <ArrowRightIcon />,
        reportType: ['xls'],
        requiredPermission: 'debris_cost_sheet',
      },
    ],
  },
];
