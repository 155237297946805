import React, { useEffect } from 'react'
import { CustomModal } from '../../customModal/customModal';
import HeadingComponent from '../../headingComponent/heading';
import { Divider, Form, Input } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

export default function UserProfileModal({
    isModalOpen,
    setModalOpen,
    title,
    truckCapacity,
    setTruckCapacity,
    }) {
        const profileState = useSelector((state) => state?.profileData?.profileData);
        
        const name = profileState?.name;
        const email = profileState?.email;

        useEffect(() => {
            if (profileState) {
                form.setFieldsValue({
                    name: name,
                    email: email,
                });
            }
        }, [profileState]);
        
        const [form] = Form.useForm(); 

  return (
    <CustomModal  
            open={isModalOpen}
            centered={true}
            title={title}
            width="630px"
            height="480px"
            onCancel={() => {
                setModalOpen(false);
            }}
            
            footer={null}
            maskClosable={false}
            // isScrollable={true}
        >
        <Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
        
        <Form
            form={form}
            layout="vertical"
            name="userProfileForm"
        >

            <FormItem
                label="Name"
                name="name"
            >
                <Input
                    disabled={true}
                    value={name}
                />
            </FormItem>

            <FormItem
                label="Email"
                name="email"
            >
                <Input
                    disabled={true}
                    value={email}
                />
            </FormItem>
           

        </Form>

        </CustomModal>
  )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B", position, top, bottom, right, left }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} position={position} top={top} bottom={bottom} right={right} left={left} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;
