import React, { useEffect, useState } from 'react'
import { Button, Card, Drawer, Select } from 'antd'
import styled from 'styled-components';
import HeadingComponent from '../../components/headingComponent/heading';
import CustomButton from '../../components/customButton/customButton';
import { disposalSiteManagementColumns, eventManagementColumns, truckManagementColumns } from '../../util/antdTableColumns';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import { pushNotification } from '../../util/notification';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { status, truckWorkStatus } from '../../util/dropdownData';
import CustomFilter from '../../components/customFilterWithSearchBar/customFilter';
import DeletePermissionModal from '../../components/modals/permission/deletePermissionModal';
import UpdateEvent from '../../components/modals/eventManagement/updateEvent';
import PermissionModal from '../../components/modals/permission/permissionModal';
import { hasPermission } from '../../util/permissionChecker';
import usePermissions from '../../components/hooks/usePermissions';
import useProjects from '../../components/hooks/useProjects';
import UpdateDisposalSite from '../../components/modals/disposalSiteManagement/updateDisposalSite';
import dayjs from 'dayjs';
import UpdateTruck from '../../components/modals/truckManagement/updateTruck';
import SearchInput from '../../components/searchInput/SearchInput';
import PaginatedSelect from '../../components/customSelect/paginatedSelect';
import {ReactComponent as FilterIcon} from '../../assets/rawSvg/filter_list.svg';
import QRPopup from '../../components/modals/QRPopup/QRPopup';
export default function TruckManagement() {


  //----------------------- Custom Hooks -----------------------//

  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
  

//---------------------- State for Data ----------------------//

const [truckDataForTable, setTruckDataForTable] = useState({});
const [currentPage, setCurrentPage] = useState(1);
const [projectOptions, setProjectOptions] = useState([]);
const [primeContractorOptions, setPrimeContractorOptions] = useState([]);
const [subContractorOptions, setSubContractorOptions] = useState([]);


const [searchedValue, setSearchedValue] = useState('');
const [projectSelected, setProjectSelected] = useState(null);
const [primeContractorSelected, setPrimeContractorSelected] = useState(null);
const [subContractorSelected, setSubContractorSelected] = useState(null);
const [statusSelected, setStatusSelected] = useState(null);
const [truckWorkStatusSelected, setTruckWorkStatusSelected] = useState(null);


const [file, setFile] = useState(null);
const [uploadedFiles, setUploadedFiles] = useState([]);


const [editTruckValues, setEditTruckValues] = useState(null);
const [QRCodeValues, setQRCodeValues] = useState(null);
//---------------------- State for Modals ----------------------//

const [updateTruckModal, setUpdateTruckModal] = useState(false);
const [deleteTruckModal, setDeleteTruckModal] = useState(false);
const [QRPopupModal, setQRPopupModal] = useState(false);


const [openFilterPopup, setOpenFilterPopup] = useState(false);

const [permissionText, setPermissionText] = useState('');
const [permissionPopUp, setPermissionPopUp] = useState(false);
  


  //----------------------- Fetch Data  -------------------------//

  const fetchData = async (
    query = '', 
    page = 1, 
 ) => {
    main_api.get(`${adminAPIsEndPoints.LIST_TRUCK(query)}&page=${page}`)
    .then((response) => {
    const result = response.data.results;
    const transformedTruckData = result.map(item => ({
        key: item?.id, 
        truck_number: item?.truck_number || '---',
        primeContractor: item?.project?.contractor?.name || '---',
        subContractor: item?.sub_contractor?.name || '---',
        driver_name: item?.truck_driver_name || '---',
        certification_status: item?.certification_status || '---',
        ...item, 
      }));
    

    setTruckDataForTable({count: response.data.count, data:  transformedTruckData });

    // const options = result?.map((item) => {
    //     item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
    //     item.value = item.id;
    //     return item;
    // });

    }).catch((error) => {
        
    pushNotification(error?.response?.data?.detail, "error");
    });
};


//----------------------------- Fetch Project Options -------------------------//

const fetchProjectOptions = async (query = '', page = 1) => {
  main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&page=${page}`)
  .then((response) => {
  const result = response.data.results;
 
  const options = result?.map((item) => {
      item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
      item.value = item.id;
      return item;
  });
  setProjectOptions(options);


  }).catch((error) => {
      
  pushNotification(error?.response?.data?.detail, "error");
  });
};

//---------------------------- Fetch Contractor Options -------------------------//

const fetchPrimeContractors = async (query = '', page = 1,) => {
  main_api.get(`${adminAPIsEndPoints.LIST_CONTRACTORS(query)}&page=${page}`)
  .then((response) => {
    const result = response.data.results;
    const contractors = result?.map((contractor) => ({
      label: contractor.name,
      value: contractor.id,
    }));
    
    setPrimeContractorOptions(contractors);
  }).catch((error) => {
    pushNotification(error.response.data.detail, "error");
  });
};
const fetchSubContractors = async ( page = 1,) => {
  if (primeContractorSelected !== null && primeContractorSelected !== undefined) {
    const query = `prime_contractor=${primeContractorSelected}`;
    
    main_api.get(`${adminAPIsEndPoints.LIST_CONTRACTORS(query)}&page=${page}`)
    .then((response) => {
      const result = response.data.results;
      const contractors = result?.map((contractor) => ({
        label: contractor.name,
        value: contractor.id,
      }));
      
      setSubContractorOptions(contractors);
    }).catch((error) => {
      pushNotification(error.response.data.detail, "error");
    });
  }
};



  //------------------ Functions for Update & Delete Truck Modal ---------------------//
  
  const handleAddRow = () => {
    if (!hasPermission(assignedPermissionsArray, 'add_truck')) {
      setPermissionText("You do not have permission to add a disposal site");
      setPermissionPopUp(true);
    } else {
      setEditTruckValues(null);
      setUpdateTruckModal(true);
    }
  };


  const handleEditRow = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_truck')) {
      setPermissionText("You do not have permission to edit a disposal site");
      setPermissionPopUp(true)
    } else if (values) {
          setEditTruckValues(values);
          setUpdateTruckModal(true);
 
    }
  };
  const handleGenerateQRCode = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_truck')) {
      setPermissionText("You do not have permission to generate QR code for a disposal site");
      setPermissionPopUp(true);
    } else if (values) {
      setQRCodeValues(values);
      setQRPopupModal(true);
    }
  };



  const handleDeleteRow = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_truck')) {
      setPermissionText("You do not have permission to delete a disposal site");
      setPermissionPopUp(true)
    }else if (values) {
        setEditTruckValues(values);
        setUpdateTruckModal(true);
    }
  };



 //----------------------------- Add and Edit Truck Functions -----------------------------//

 const handleEditTruck = async (values) => {
  const id = editTruckValues.key;
  values = {
    ...values,
    license_expire_date:values?.license_expire_date ? dayjs(values.license_expire_date).format("YYYY-MM-DD") : null,
    truck_driver_license_expire_date: values?.truck_driver_license_expire_date ? dayjs(values.truck_driver_license_expire_date).format("YYYY-MM-DD") : null,
    attachments: uploadedFiles?.map(item => {
      return { key: item.key, attachment_type: item.attachment_type, type: item.type }
    }),
  };

  if (values?.sub_contractor !== null && values?.sub_contractor !== undefined) {
    values = {
      ...values,
      sub_contractor: values?.sub_contractor,
    }
  } else {
    delete values.sub_contractor; 
  }
  values = Object.fromEntries(
    Object.entries(values).filter(([_, value]) => value !== undefined && value !== null)
  );

  main_api.put(adminAPIsEndPoints.UPDATE_TRUCK(id), values)
    .then((response) => {
      if (response.status === 200) {
        pushNotification("Truck updated successfully", "success");
        setUploadedFiles([]);
        let query = `search=${searchedValue}`;
        if(projectSelected !== null && projectSelected !== undefined){
          query += `&project=${projectSelected}`;
        }
        if (statusSelected!==null && statusSelected!==undefined) {
          query+=`&certification_status=${statusSelected}`;
        }
        if (primeContractorSelected !== null && primeContractorSelected !== undefined) {
          query += `&prime_contractor=${primeContractorSelected}`;
        }
        if (subContractorSelected !== null && subContractorSelected !== undefined) {
          query += `&sub_contractor=${subContractorSelected}`;
        }
        fetchData(query, currentPage);
        setUpdateTruckModal(false);
      }
    })
    .catch((error) => {
      setUploadedFiles([]);
      pushNotification(error.response.data.detail, "error");
    });
};


const handleAddTruck = async (values) => {

  values = {
    ...values,
    license_expire_date: values?.license_expire_date ? dayjs(values.license_expire_date).format("YYYY-MM-DD") : null,
    truck_driver_license_expire_date: values?.truck_driver_license_expire_date ? dayjs(values.truck_driver_license_expire_date).format("YYYY-MM-DD") : null,
    attachments: uploadedFiles?.map(item => {
      return { key: item.key, attachment_type: item.attachment_type, type: item.type }
    }),
  };

  if (values?.sub_contractor !== null && values?.sub_contractor !== undefined) {
    values = {
      ...values,
      sub_contractor: values?.sub_contractor,
    }
  } else {
    delete values.sub_contractor; 
  }

  if (values?.certification_status === undefined) {
    values = {
      ...values,
      certification_status: true,
    };
  };
  values = Object.fromEntries(
    Object.entries(values).filter(([_, value]) => value !== undefined && value !== null)
  );

  main_api.post(adminAPIsEndPoints.ADD_TRUCK, values)
  .then((response) => {
      if (response.status === 201) {
          pushNotification("Truck added successfully", "success");
          setUploadedFiles([]);
          setSearchedValue('');
          setStatusSelected(null);
          setPrimeContractorSelected(null);
          setSubContractorSelected(null);
          fetchData();
          setUpdateTruckModal(false);
      }
  }).catch((error) => {
      setUploadedFiles([]);
      pushNotification(error.response.data.detail, "error");
  });
}

//----------------------------- Delete Truck Functions -----------------------------//

const handleDeleteTruck = async () => {
  const id = editTruckValues.key;
  main_api.delete(adminAPIsEndPoints.DELETE_TRUCK(id))
    .then((response) => {
      if (response.status === 204) {
        pushNotification("Truck deleted successfully", "success");
        const query = '';
        fetchData(query, currentPage);
        setDeleteTruckModal(false);
      }
    })
    .catch((error) => {
      pushNotification(error.response.data.detail, "error");
    });
};


//---------------------- Effect for Fetching Data ----------------------//

useEffect(() => {
  fetchData();
  fetchProjectOptions();
  fetchPrimeContractors();
}, []);


useEffect(() => {
  if (primeContractorSelected) {
    fetchSubContractors();
  }
}, [primeContractorSelected]);


//------------------------- Filter --------------------------//
  
   useEffect(() => {
    let query = `search=${searchedValue}`;
    if(projectSelected){
      query += `&project=${projectSelected}`;
    }
    if (statusSelected!==null) {
      query+=`&certification_status=${statusSelected}`;
    }
    fetchData(query);
  }, [projectSelected, statusSelected, searchedValue]);


  const handleExtraFilters = async () => {
    let query = '';
    if(primeContractorSelected){
      query += `&prime_contractor=${primeContractorSelected}`;
    }
    if(subContractorSelected){
      query += `&sub_contractor=${subContractorSelected}`;
    }
    await fetchData(query);
    setOpenFilterPopup(false);
  }



  return (
     <div style={{ marginTop: '10px' }}>
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Heading text="Manage trucks" margin="0px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
          <CustomButton btnText={"Add Truck"} color={"white"} onClick={handleAddRow} />
        </div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"10px"}}>
          <div style={{display: "flex", flexDirection: "row"}}>
                <SearchInputWrapper>
                  <SearchInput onBlur={(e) => setSearchedValue(e)} onKeyDown={(e) => setSearchedValue(e)} placeholder={"Search by Truck Number"} />
                </SearchInputWrapper>
                <PaginatedSelect
                  value={projectSelected}
                  placeholder={"Select Project"}
                  options={projectOptions}
                  onChange={(e) => setProjectSelected(e)}
                  fetchData={fetchProjectOptions}
                  style={{marginLeft:"8px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
                />
                <Select
                  value={statusSelected}
                  placeholder={"Select Status"}
                  options={status}
                  onChange={(e) => setStatusSelected(e)}
                  style={{marginLeft:"20px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}}
                />
                <div
                  onClick={() => {
                    setProjectSelected(null);
                    setStatusSelected(null);
                    setSearchedValue('');
                    fetchData();
                  }}
                  style={{cursor:"pointer",color:"#EE3E41",marginLeft:"15px", marginBottom: "20px", position:"relative", top:"20px", left:"6px", width:"80px", height:"40px"}}
                >
                  Reset Filters
                </div>
          </div>
          
          <Button
            icon={<FilterIcon />}
            style={{
              backgroundColor: "white",
              color: "#3669AE",
              border: "1px solid #3669AE",
              width: "85px",
              height: "38px",
              borderRadius: "5px",
              position: "relative",
              top: "15px",
              transition: "background-color 0.3s ease",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
            onClick={() => setOpenFilterPopup(!openFilterPopup) }
          >
            More
          </Button>
            
        {openFilterPopup && (<Card
             title="Filters"
             extra={<span onClick={() => {
                setPrimeContractorSelected(null);
                setSubContractorSelected(null);
                setTruckWorkStatusSelected(null);
             }} style={{ cursor: 'pointer', color: 'red' }}>Reset Filters</span>}
             style={{
               position: 'absolute',
               top: '132px',  // Adjust the top position as needed
               right: '32px', // Adjust the right position as needed
               zIndex: 1000,
               width: '300px', // Set the width of the card
               height: '320px', // Set the height of the card
               boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a shadow for depth
               borderRadius: '8px' // Slightly round the corners
             }}
             bodyStyle={{ padding: '16px' }} // Adjust padding inside the card
            >
              <PaginatedSelect
                value={primeContractorSelected}
                placeholder={"Select Prime Contractor"}
                options={primeContractorOptions}
                onChange={(e) => {
                  setPrimeContractorSelected(e);
                  setSubContractorSelected(null);
                }}
                fetchData={fetchPrimeContractors}
                style={{marginBottom: "20px", width:"260px", height:"40px"}}
              />
              <PaginatedSelect
                value={subContractorSelected}
                placeholder={"Select Sub Contractor"}
                options={subContractorOptions}
                onChange={(e) => setSubContractorSelected(e)}
                fetchData={fetchSubContractors}
                style={{marginBottom: "20px", width:"260px", height:"40px"}}
              />
              <SaveContainer className="d-flex justify-content-end">
                <CustomButton
                  btnText={"Cancel"}
                  margin="0px 5px"
                  noBackground
                  hideIcon={true}
                  onClick={() => setOpenFilterPopup(false)}
                />
                <Button onClick={handleExtraFilters} type="primary" style={{height:"40px"}}>Apply Filters</Button>
              </SaveContainer>
            </Card>)}

        </div>

       
        <AntdesignTablePagination 
          columns={truckManagementColumns({handleEditRow,handleDeleteRow,handleGenerateQRCode})} 
          data={truckDataForTable.data}
          totalCount={truckDataForTable.count}
          loadPaginatedData={fetchData} 
          allowRowSelection={false}
          tableHeight={450}
          tableWidth={1100} 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
    </CustomCard>
    {updateTruckModal && 
      <UpdateTruck 
        isModalOpen={updateTruckModal} 
        title={editTruckValues ? "Edit Truck" : "Add New Truck"} 
        onFinish={editTruckValues ? handleEditTruck : handleAddTruck  } 
        setModalOpen={setUpdateTruckModal} 
        editTruckValues={editTruckValues}
        file={file}
        setFile={setFile}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
    }
    {deleteTruckModal &&
      <DeletePermissionModal
        isModalOpen={deleteTruckModal} 
        title='Delete Truck'
        description='Are you sure you want to delete this truck? This action cannot be undone.'
        deleteText='Delete'
        onDelete={handleDeleteTruck} 
        setModalOpen={setDeleteTruckModal} 
      />
    }
    {permissionPopUp && (
      <PermissionModal 
        text={permissionText}
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />
    )}
    {QRPopupModal && (
      <QRPopup 
        onclose={() => setQRPopupModal(false)}
        openModal={QRPopupModal}
        data={QRCodeValues}
      />
    )}

  </div>
  )
}




const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1570px;
  height: calc(100vh - 40px);
  max-height: 750px;
  margin-top: 40px;
  margin-left: 40px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;

const SearchInputWrapper = styled.div`
  width: 350px;
  margin-left: 5px;
`;


const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 0px;
  width: 298px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;
