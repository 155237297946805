

import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Checkbox, Divider, Form, Input, InputNumber, Select } from "antd";
import CustomButton from "../../../customButton/customButton";
import HeadingComponent from "../../../headingComponent/heading";
import { CustomModal } from '../../../customModal/customModal';
import { accessPermission, debrisColorOptions, unitRequirementOptions } from '../../../../util/dropdownData';
import { useSelector } from 'react-redux';
import CustomSwitch from '../../../customSwitch/customSwitch';

export default function UpdateDebrisType({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    editDebrisTypeValues,
    padding = "20px",
}) {
  
  //----------------------- State Management -----------------------//

  const [active, setActive] = useState(editDebrisTypeValues?.is_active || true);


  //----------------------- Form Initialization -----------------------//

  const [form] = Form.useForm();


  //----------------------- Use Effect -----------------------//

  // useEffect(() => {
  //   if (editDebrisTypeValues) {
      
  //     form.setFieldsValue({
  //       name: editDebrisTypeValues?.name,
  //       is_active: editDebrisTypeValues?.is_active ,
  //       mileage: editDebrisTypeValues?.rate_matrix_fields?.mileage ,
  //       diameter: editDebrisTypeValues?.rate_matrix_fields?.diameter ,
  //       unit: editDebrisTypeValues?.rate_matrix_fields?.unit ,
  //       weight: editDebrisTypeValues?.rate_matrix_fields?.weight ,
  //       reduction_rate: editDebrisTypeValues?.rate_matrix_fields?.reduction_rate,
  //     });
  //   }
  // }, [editDebrisTypeValues]);

  useEffect(() => {
    if (editDebrisTypeValues) {
      form.setFieldsValue({
        name: editDebrisTypeValues?.name,
        color: editDebrisTypeValues?.color,
        is_active: editDebrisTypeValues?.is_active,
        closed_by_field_monitor: editDebrisTypeValues?.closed_by_field_monitor,
        field_photo_count: editDebrisTypeValues?.field_photo_count,
        site_photo_count: editDebrisTypeValues?.site_photo_count,
        cyds_unit: editDebrisTypeValues?.cyds_unit,
        each_unit: editDebrisTypeValues?.each_unit,
        weight_unit: editDebrisTypeValues?.weight_unit,
        length_unit: editDebrisTypeValues?.length_unit,
        size_unit: editDebrisTypeValues?.size_unit,
        zero_value_unit: editDebrisTypeValues?.zero_value_unit,
        mileage: editDebrisTypeValues?.rate_matrix_fields?.mileage,
        diameter: editDebrisTypeValues?.rate_matrix_fields?.diameter,
        unit: editDebrisTypeValues?.rate_matrix_fields?.unit,
        weight: editDebrisTypeValues?.rate_matrix_fields?.weight,
        reduction_rate: editDebrisTypeValues?.rate_matrix_fields?.reduction_rate,
        lat_lng: editDebrisTypeValues?.rate_matrix_fields?.lat_lng,
        disposal_site: editDebrisTypeValues?.rate_matrix_fields?.disposal_site,
        pass_number: editDebrisTypeValues?.rate_matrix_fields?.pass_number,
        length: editDebrisTypeValues?.rate_matrix_fields?.length,
        hazard_type: editDebrisTypeValues?.rate_matrix_fields?.hazard_type,
        stumps_extraction: editDebrisTypeValues?.rate_matrix_fields?.stumps_extraction,
        roe: editDebrisTypeValues?.rate_matrix_fields?.roe,
        freon_extracted: editDebrisTypeValues?.rate_matrix_fields?.freon_extracted,
        vin: editDebrisTypeValues?.rate_matrix_fields?.vin,
        load_call: editDebrisTypeValues?.rate_matrix_fields?.load_call,
        confirm_quantity: editDebrisTypeValues?.rate_matrix_fields?.confirm_quantity,
        tipping_ticket_number: editDebrisTypeValues?.rate_matrix_fields?.tipping_ticket_number,
        tipping_fee: editDebrisTypeValues?.rate_matrix_fields?.tipping_fee,
      });
    }
  }, [editDebrisTypeValues]);
  


  //----------------------- Event Handlers -----------------------//

  

  const handleDiameterUnitCheckboxChange = (e) => {
  
    form.setFieldsValue({ cyds_unit: e.target.checked });
  };

  const handleGoodsUnitCheckboxChange = (e) => {

    form.setFieldsValue({ each_unit: e.target.checked });
  };

  const handleWeightUnitCheckboxChange = (e) => {

    form.setFieldsValue({ weight_unit: e.target.checked });
  };

  const handleLengthUnitCheckboxChange = (e) => {

    form.setFieldsValue({ length_unit: e.target.checked });
  };

  const handleSizeUnitCheckboxChange = (e) => {

    form.setFieldsValue({ size_unit: e.target.checked });
  };
  const handleZeroValueUnitCheckboxChange = (e) => {

    form.setFieldsValue({ size_unit: e.target.checked });
  };

  const handleSwitchChange = (checked) => {
    setActive(checked);
    form.setFieldsValue({ is_active: checked }); 
    
  };

  const handleClosedByFieldMonitor = (checked) => {
    form.setFieldsValue({ closed_by_field_monitor: checked });
  }


  return (
    <CustomModal  
      open={isModalOpen}
      title={title}
      width="600px"
      height="614px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
    >
      <Divider style={{width:"108.5%", position:"relative", top:"0px", right:"24px", borderTop:"1px solid #DEE2E6"}}/>
      <Form name="updateDebrisTypeForm" onFinish={onFinish} form={form} layout="vertical">
      <FormWrapper>
      <div style={{ display: "flex", flexDirection: "row" }}>
          <FormItem 
            name="name" 
            label="Debris Name" 
            rules={[
              {
                required: true,
                min: 3,
                max: 50,
                message: "Please enter a debris name",
              },
            ]}
          >
             <Input placeholder="Enter Here" />
          </FormItem>

          <FormItem
            name="color"
            label="Debris Color"
            style={{marginLeft: "10px"}}
            rules={[
              {
                required: false,
                message: "Please enter a color",
              },
            ]}
          >
            <Select style={{ width: "100%" }} options={debrisColorOptions} placeholder="Select Color" />
          </FormItem>

      </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
         
            

         <FormItem
           name="field_photo_count"
           label= "Field Photo Count"
           rules={[
             {
                required: false,
                type: "number",
                message: "Please enter the field photo count",
             },
           ]}
         >
            <InputNumber style={{ width: "100%" }} placeholder="Enter Here" />
         </FormItem>

         <FormItem
            name="site_photo_count"
            label= "Site Photo Count"
            style={{marginLeft: "10px"}}
            rules={[
              {
                required: false,
                type: "number",
                message: "Please enter the site photo count",
              },
            ]}
          >
           <InputNumber style={{ width: "100%" }} placeholder="Enter Here" />
          </FormItem>
       </div>
            <FormItem name="is_active" label="Status" style={{ position: "relative", top: "0px", right: "1px" }}>
              <CustomSwitch value={active} onChange={handleSwitchChange} />
            </FormItem>

            <FormItem
              name= "closed_by_field_monitor"
              valuePropName='checked'
              rules={[
                {
                  required: false,
                  message: "Please select the requirement",
                },
              ]}
            >
              <span style={{ display: "flex", flexDirection: "row", margin: "0px 0px 10px 0px" }}>

                <Checkbox defaultChecked={editDebrisTypeValues?.closed_by_field_monitor} onChange={handleClosedByFieldMonitor} />
                <Heading text="Closed By Field Monitor" margin="4px 0px 0px 8px" fontSize="0.75rem" color="grey" />
              </span>
            </FormItem>
            
          <Heading text="Select Units." margin="20px 0px 10px 2px" fontSize="0.95rem" color="grey" />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormItem
              name="cyds_unit"
              valuePropName="checked" 
              rules={[
                {
                  required: false,
                  message: "Please mark the diameter field",
                },
              ]}
            >
              <span style={{ display: "flex", flexDirection: "row", margin: "20px 0px 0px 0px" }}>
              <Checkbox defaultChecked={editDebrisTypeValues?.cyds_unit} onChange={handleDiameterUnitCheckboxChange}/>
              <Heading text="Cubic Yards" margin="4px 0px 0px 8px" fontSize="0.75rem" color="grey" />
              </span>
            </FormItem>
            
            <FormItem
              name="each_unit"
              valuePropName="checked" 
              rules={[
                {
                  required: false,
                  message: "Please mark the unit field",
                },
              ]}
            >
              <span style={{display: "flex", flexDirection: "row", margin: "20px 0px 0px 0px"}}>
              <Checkbox defaultChecked={editDebrisTypeValues?.each_unit} onChange={handleGoodsUnitCheckboxChange}/>
              <Heading text="Quantity of Good" margin="4px 0px 0px 8px" fontSize="0.75rem" color="grey" />
              </span>
            </FormItem>

            <FormItem
              name="weight_unit"
              valuePropName="checked" 
              rules={[
                {
                  required: false,
                  message: "Please mark the weight field",
                },
              ]}
            >
              <span  style={{display: "flex", flexDirection: "row", margin: "20px 0px 0px 0px"}}>
              <Checkbox defaultChecked={editDebrisTypeValues?.weight_unit} onChange={handleWeightUnitCheckboxChange}/>
              <Heading text="Tonns" margin="4px 0px 0px 8px" fontSize="0.75rem" color="grey" />
              </span>
            </FormItem>
            

          </div>
        
         
          <div style={{ display: "flex", flexDirection: "row" }}>
         
            <FormItem
              name="length_unit"
              valuePropName="checked" 
              rules={[
                {
                  required: false,
                  message: "Please mark the reduction rate field",
                },
              ]}
            >
              <span  style={{display: "flex", flexDirection: "row", margin: "2px 0px 0px 0px"}}>
              <Checkbox defaultChecked={editDebrisTypeValues?.length_unit} onChange={handleLengthUnitCheckboxChange}/>
              <Heading text="Length in Feet" margin="4px 0px 0px 8px" fontSize="0.75rem" color="grey" />
              </span>
            </FormItem>

            <FormItem
              name="size_unit"
              valuePropName="checked" 
              rules={[
                {
                  required: false,
                  message: "Please mark the reduction rate field",
                },
              ]}
            >
              <span  style={{display: "flex", flexDirection: "row", margin: "2px 0px 0px 0px"}}>
              <Checkbox defaultChecked={editDebrisTypeValues?.size_unit} onChange={handleSizeUnitCheckboxChange}/>
              <Heading text="Size in Inches" margin="4px 0px 0px 8px" fontSize="0.75rem" color="grey" />
              </span>
            </FormItem>

            <FormItem
              name="zero_value_unit"
              valuePropName="checked" 
              rules={[
                {
                  required: false,
                  message: "Please mark the reduction rate field",
                },
              ]}
            >
              <span  style={{display: "flex", flexDirection: "row", margin: "2px 0px 0px 0px"}}>
              <Checkbox defaultChecked={editDebrisTypeValues?.zero_value_unit} onChange={handleZeroValueUnitCheckboxChange}/>
              <Heading text="Zero Value Unit" margin="4px 0px 0px 8px" fontSize="0.75rem" color="grey" />
              </span>
            </FormItem>

           
          </div>
          <Heading text="Select fields." margin="20px 0px 20px 2px" fontSize="0.95rem" color="grey" />
          <div style={{ display: "flex", flexDirection: "row" }}>
         
            

         <FormItem
           name="diameter"
           label= "Diameter"
           rules={[
             {
               required: false,
               message: "Please select the requirement",
             },
           ]}
         >
           <Select
             style={{ width: "100%" }}
             placeholder="Select Requirement"
             defaultValue={"not_required"}
             options={unitRequirementOptions}
           />
         </FormItem>

       </div>
       <div style={{ display: "flex", flexDirection: "row" }}>
         
       <FormItem
          name="disposal_site"
          label="Disposal Site"
          rules={[
            {
              required: false,
              message: "Please select the requirement",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select Requirement"
            defaultValue={"not_required"}
            options={unitRequirementOptions}
          />
        </FormItem>

        <FormItem
          name="pass_number"
          label="Pass Number"
          style={{marginLeft: "10px"}}
          rules={[
            {
              required: false,
              message: "Please select the requirement",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select Requirement"
            defaultValue={"not_required"}
            options={unitRequirementOptions}
          />
        </FormItem>
         </div>
         <div style={{ display: "flex", flexDirection: "row" }}>
         
         <FormItem
            name="length"
            label="Length"
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>

          <FormItem
            name="hazard_type"
            label="Hazard Type"
            style={{marginLeft: "10px"}}
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>
         </div>
       
         <div style={{ display: "flex", flexDirection: "row" }}>
         <FormItem
            name="roe"
            label="ROE"
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>
         <FormItem
            name="stumps_extraction"
            label="Stumps Extraction"
            style={{marginLeft: "10px"}}
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>

         </div>
         <div style={{ display: "flex", flexDirection: "row" }}>
         
         <FormItem
            name="freon_extracted"
            label="Freon Extracted"
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>

          <FormItem
            name="vin"
            label="VIN"
            style={{marginLeft: "10px"}}
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>
         </div>
         <div style={{ display: "flex", flexDirection: "row" }}>
         
          <FormItem
            name="load_call"
            label="Load Call"
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>

          <FormItem
            name="confirm_quantity"
            label="Confirm Quantity"
            style={{marginLeft: "10px"}}
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>
         </div>
         <div style={{ display: "flex", flexDirection: "row" }}>
         
         <FormItem
            name="tipping_ticket_number"
            label="Tipping Ticket Number"
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>

          <FormItem
            name="tipping_fee"
            label="Tipping Fee"
            style={{marginLeft: "10px"}}
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>
        </div>
       
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormItem
              name="mileage"
              label= "Mileage"
              rules={[
                {
                  required: false,
                  message: "Please select the requirement",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select Requirement"
                defaultValue={"not_required"}
                options={unitRequirementOptions}
              />
            </FormItem>

            <FormItem
            name="weight"
            label="Weight"
            style={{marginLeft: "10px"}}
            rules={[
              {
                required: false,
                message: "Please select the requirement",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Requirement"
              defaultValue={"not_required"}
              options={unitRequirementOptions}
            />
          </FormItem>
          </div>
          
          </FormWrapper>
        <Divider style={{width:"108.5%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
        <SaveContainer className="d-flex justify-content-end">
          <CustomButton
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => setModalOpen(false)}
          />
          <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
        </SaveContainer>
      </Form>
    </CustomModal>
  );
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const FormWrapper = styled.div`
height: 585px;
width:  570px;
padding: 5px;
overflow: auto;

/* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SaveContainer = styled.div`
position: absolute;
bottom: 4px;
right: 4px;
width: 595px;
border-top: 1px solid #E0E0E0;
background-color: white;
padding: 10px 20px;
`;

