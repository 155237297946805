import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { Button, DatePicker, Divider, Form, Input, InputNumber, message, Radio, Segmented, Select, Switch, TimePicker } from "antd";
import { CustomModal } from '../../customModal/customModal';
import CustomButton from '../../customButton/customButton';
import { countryCodes, status, ticketStatusOptions } from '../../../util/dropdownData';
import CustomSwitch from '../../customSwitch/customSwitch';
import HeadingComponent from '../../headingComponent/heading';
import LocationSelector from '../../customAddressInput/locationSelector';
import { useSelector } from 'react-redux';
import { getUserPositionList } from '../../../util/dataService';
import { main_api } from '../../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../../constants/apiEndPoints';
import PaginatedSelect from '../../customSelect/paginatedSelect';
import { pushNotification } from '../../../util/notification';
import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as PlusIcon } from "../../../assets/rawSvg/Plus.svg";
import { ReactComponent as CrossIcon } from "../../../assets/rawSvg/Cross.svg";
import CustomUploader from '../../customUploader/customUploader';
import { fieldsMap, getFieldValidationRules } from '../../../util/fieldsMap';
import dayjs from 'dayjs';
import distanceCalculator from '../../../util/distanceCalculator';


export default function UpdateTicket({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    updateTicketValues,
    selectedProjectForUpdateForm,
    selectedSubActivityForUpdateForm,
    selectedDebrisTypeForUpdateForm,
    setSelectedProjectForUpdateForm,
    setSelectedSubActivityForUpdateForm,
    setSelectedDebrisTypeForUpdateForm,
    file,
    setFile,
    uploadedFiles,
    setUploadedFiles,
    loadTime,
    setLoadTime,
    disposalTime,
    setDisposalTime,
    loadDate,
    setLoadDate,
    disposalDate,
    setDisposalDate,
    padding = "20px",
}) {

  //----------------------- State Variables -----------------------//
  const rolesState = useSelector((state) => state.roles.roles);

  const [address, setAddress] = useState("");
  const [addressLatAndLong, setAddressLatAndLong] = useState([null,null]);
  
  const [disposalSiteAddressLatAndLong, setDisposalSiteAddressLatAndLong] = useState([null,null]);

    // const [file, setFile] = useState(null);
    // const [uploadedFiles, setUploadedFiles] = useState([]);
    const [savedFileUrl, setSavedFileUrl] = useState(null);

    const [projectOptions, setProjectOptions] = useState([]);
    const [subActivityTypeOptions, setSubActivityTypeOptions] = useState([]);
    const [debrisTypeOptions, setDebrisTypeOptions] = useState([]);
    const [disposalSiteOptions, setDisposalSiteOptions] = useState([]);
    const [hazardTypeOptions, setHazardTypeOptions] = useState([]);
    const [hazardNameOptions, setHazardNameOptions] = useState([]);
    const [fieldMonitorOptions, setFieldMonitorOptions] = useState([]);
    const [siteMonitorOptions, setSiteMonitorOptions] = useState([]);
    const [truckNumberOptions, setTruckNumberOptions] = useState([]);

    const [disposalSiteType, setDisposalSiteType] = useState('');

    const [hazardTypeOptionsPage, setHazardTypeOptionsPage] = useState(1);
    const [hazardNameOptionsPage, setHazardNameOptionsPage] = useState(1);
    const [disposalSiteOptionsPage, setDisposalSiteOptionsPage] = useState(1);
    const [debrisTypeOptionsPage, setDebrisTypeOptionsPage] = useState(1);
    const [fieldMonitorOptionsPage, setFieldMonitorOptionsPage] = useState(1);
    const [siteMonitorOptionsPage, setSiteMonitorOptionsPage] = useState(1);
    const [truckNumberOptionsPage, setTruckNumberOptionsPage] = useState(1);


    const [projectSelected, setProjectSelected] = useState(updateTicketValues?.project || null);
    const [subActivitySelected, setSubActivitySelected] = useState(updateTicketValues?.project?.sub_activity?.map(item => item.id) || null);
    const [debrisTypeSelected, setDebrisTypeSelected] = useState(updateTicketValues?.debris_type || null);
    const [disposalSiteSelected, setDisposalSiteSelected] = useState(updateTicketValues?.disposal_site || null);
    const [hazardTypeSelected, setHazardTypeSelected] = useState(updateTicketValues?.hazard_type || null);
    const [hazardNameSelected, setHazardNameSelected] = useState(updateTicketValues?.hazard_name || null);
    const [fieldMonitorSelected, setFieldMonitorSelected] = useState(null);
    const [siteMonitorSelected, setSiteMonitorSelected] = useState(null);
    const [truckNumberSelected, setTruckNumberSelected] = useState(null);


    const [selectedProjectData, setSelectedProjectData] = useState(null);
    const [selectedDebrisTypeData, setSelectedDebrisTypeData] = useState(null);
    const [selectedTruckData, setSelectedTruckData] = useState(null);
    const [showUpdateTicketForm, setShowUpdateTicketForm] = useState(false);

    const [requiredPhotoCount, setRequiredPhotoCount] = useState({});
    const [fields, setFields] = useState({});
    
    const [unitOptions, setUnitOptions] = useState([]);
    const [truckCapacity, setTruckCapacity] = useState(0);
    const [truckLoadVolume, setTruckLoadVolume] = useState(0);
    const [distance, setDistance] = useState(0);

    const [showReasonField, setShowReasonField] = useState(false);

    //----------------------- Form Initialization -----------------------//

    const [form] = Form.useForm();

    useEffect(() => {
        if (updateTicketValues) {
          
          form.setFieldsValue({
            project: updateTicketValues?.project.id,
            sub_activity: updateTicketValues?.project?.sub_activity?.map(item => item.id),
            debris_type: updateTicketValues?.debris_type?.id,
            truck: updateTicketValues?.truck?.id,
            truck_capacity: updateTicketValues?.truck?.bed_capacity,
            field_monitor: updateTicketValues?.field_monitor?.id,
            site_monitor: updateTicketValues?.site_monitor?.id,
            load_date: updateTicketValues?.load_date ? dayjs(updateTicketValues?.load_date, "YYYY/MM/DD") : null,
            load_time: updateTicketValues?.load_time ? dayjs(updateTicketValues?.load_time, "HH:mm:ss") : null,
            disposal_date: updateTicketValues?.disposal_date ? dayjs(updateTicketValues?.disposal_date, "YYYY/MM/DD") : null,
            disposal_time: updateTicketValues?.disposal_time ? dayjs(updateTicketValues?.disposal_time, "HH:mm:ss") : null,
            manual_ticket_number: updateTicketValues?.manual_ticket_number,
            site_type: updateTicketValues?.disposal_site?.site_type,
            address: updateTicketValues?.addresses.map(item => item.address),
            latitude: updateTicketValues?.addresses.map(item => item.latitude),
            longitude: updateTicketValues?.addresses.map(item => item.longitude),
            field_monitor_notes: updateTicketValues?.field_monitor_notes,
            site_monitor_notes: updateTicketValues?.site_monitor_notes,
            // unit_of_measure: updateTicketValues?.unit_of_measure,
            status: updateTicketValues?.status,
            tipping_fee: updateTicketValues?.tipping_fees,
            hazard_type: updateTicketValues?.hazard_name?.type,
            hazard_name: updateTicketValues?.hazard_name?.id,
            stumps_extraction: updateTicketValues?.stumps_extraction_required,
          });
          setHazardTypeSelected(updateTicketValues?.hazard_name?.type);
          setFieldMonitorSelected(updateTicketValues?.field_monitor?.id);
          setSiteMonitorSelected(updateTicketValues?.site_monitor?.id);
          setTruckNumberSelected(updateTicketValues?.truck?.id);
          const length = updateTicketValues?.addresses.length;
          const latestAddress = updateTicketValues?.addresses[length - 1];
          setAddress(latestAddress?.address);
          setAddressLatAndLong([latestAddress?.latitude, latestAddress?.longitude]);
          setDisposalSiteAddressLatAndLong([updateTicketValues?.disposal_site?.latitude, updateTicketValues?.disposal_site?.longitude]);
          if (updateTicketValues?.roe) {
            setShowReasonField(true);
            form.setFieldsValue({ roe_reason: updateTicketValues?.roe_reason });
          }
        }
      }
    , [updateTicketValues]);

    useEffect(() => {
      if (updateTicketValues && fields && Object.keys(fields).length > 0) {
        form.setFieldsValue({
          unit_of_measure: updateTicketValues?.unit_of_measure,
          load_call: updateTicketValues?.load_call === 0 || updateTicketValues?.load_call === null ? null : updateTicketValues?.load_call,
        });
      }
    }, [updateTicketValues,fields]);

    useEffect(() => {
      if (updateTicketValues && updateTicketValues?.id) {
        fetchAttachments();
      }
    }, [updateTicketValues]);

    useEffect(() => {
      if (updateTicketValues && Object.keys(updateTicketValues).length > 0) {
        
        const fields = updateTicketValues?.debris_type;
        setFields(fields);
        setShowUpdateTicketForm(true);

      }
    }
  , [updateTicketValues]);

    




    useEffect(() => {
      if (updateTicketValues  && fields && Object.keys(fields).length > 0 && fields?.rate_matrix_fields) {
        // Iterate over the keys in the fields state
      
        
        Object.keys(fields?.rate_matrix_fields).forEach((fieldKey) => {
          // Check if the fieldKey is also in updateTicketValues
          
          if (updateTicketValues.hasOwnProperty(fieldKey)) {

            if (fieldKey === "disposal_site" || fieldKey === "debris_type" || fieldKey === "hazard_type") {
              // Handle fields that might be objects with an id
              form.setFieldsValue({
                [fieldKey]: updateTicketValues[fieldKey] ? updateTicketValues[fieldKey].id : null,
              });

              if (fieldKey === "disposal_site") {
                
                setDisposalSiteSelected(updateTicketValues[fieldKey]?.id || null);
                
                form.setFieldsValue({
                  disposal_site: updateTicketValues[fieldKey]?.id,
                  site_type: updateTicketValues[fieldKey]?.site_type,
                });
              } else if (fieldKey === "hazard_type") {
                setHazardTypeSelected(updateTicketValues[fieldKey]?.id || null);
                form.setFieldsValue({
                  hazard_type: updateTicketValues[fieldKey]?.id,
                });
              }

              
             

            } else {
              // Handle other fields
              form.setFieldsValue({
                [fieldKey]: updateTicketValues[fieldKey],
              });
              if (fieldKey === "tipping_fee") {
                
                form.setFieldsValue({
                  [fieldKey]: updateTicketValues["tipping_fees"] || 0,
                });
              }
              if (fieldKey === "load_call") {
                form.setFieldsValue({
                  [fieldKey]: updateTicketValues["load_call"] === 0 || updateTicketValues["load_call"] == null ? null : updateTicketValues["load_call"],
                });
                
              }
            }
          }
        });
      }
    }, [updateTicketValues, fields]);
    
    



    //----------------------- Fetch Data -----------------------//


    const fetchProjects = async (query = '', page = 1) => {
        main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&is_active=true&page=${page}`)
        .then((response) => {
            const result = response?.data?.results;
            let options = result?.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }));
            if (updateTicketValues?.project) {
              const selectedOption = {
                ...updateTicketValues.project,
                label: updateTicketValues.project.name.charAt(0).toUpperCase() + updateTicketValues.project.name.slice(1),
                value: updateTicketValues.project.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }
            setProjectOptions(options);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
    };

    const fetchSubActivityTypes = async (page = 1) => {
      if (selectedProjectForUpdateForm) {
        const query = `projects=${selectedProjectForUpdateForm}`;
        try {
          const response = await main_api.get(`${adminAPIsEndPoints.LIST_SUB_ACTIVITY(query)}&is_active=true&page=${page}`);
          const result = response?.data?.results;
    
          if (result) {
            // Format options with proper labels and values
            let options = result.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }));
    
            // Check if updateTicketValues.sub_activity contains IDs and update the selected options
            
            const selectedOptionIds = updateTicketValues?.sub_activity || [];
            
            // const selectedOptions = options.filter(option => selectedOptionIds.includes(option.value));
            const selectedOptions = options.filter(option => option.value === selectedOptionIds?.id);
    
            // Ensure selected options are present at the start of the options list
            // options = [...selectedOptions, ...options.filter(item => !selectedOptionIds.includes(item.value))];
            options = [...selectedOptions, ...options.filter(item => item.id !== selectedOptionIds?.id)];
    
            setSubActivityTypeOptions(options);
          } else {
            setSubActivityTypeOptions([]);
          }
        } catch (error) {
          pushNotification(error?.response?.data?.detail, "error");
        }
      } else {
        setSubActivityTypeOptions([]);
      }
    };
    

    const fetchDebrisTypes = async ( page = 1) => {
      if (selectedProjectForUpdateForm) {
        
        const query =  `disposal_sites__projects=${selectedProjectForUpdateForm}&is_active=true&page=${page}`

        main_api.get(adminAPIsEndPoints.LIST_DEBRIS(query))
        .then((response) => {
            const result = response?.data?.results;
            
            if (response.data.next) {
              const urlObj = new URL(response.data.next);
              const params = new URLSearchParams(urlObj.search);
              const pageNumber = params.get('page');
              setDebrisTypeOptionsPage(pageNumber);
            } else {
              setDebrisTypeOptionsPage(null);
            }

            let options = [...debrisTypeOptions, ...result?.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }))];
            if (updateTicketValues?.debris_type) {
              const selectedOption = {
                ...updateTicketValues.debris_type,
                label: updateTicketValues.debris_type.name.charAt(0).toUpperCase() + updateTicketValues.debris_type.name.slice(1),
                value: updateTicketValues.debris_type.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }
            const uniqueOptions = options.reduce((acc, current) => {
              const existing = acc.find(item => item.value === current.value);
              if (existing) {
                // Compare the number of keys and keep the one with more information
                if (Object.keys(current).length > Object.keys(existing).length) {
                  return acc.map(item => item.value === current.value ? current : item);
                }
                return acc;
              } else {
                acc.push(current);
                return acc;
              }
            }, []);
            // setSelectedDebrisTypeData(result);
            setDebrisTypeOptions(uniqueOptions);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setDebrisTypeOptions([]);
      }
    };

    const fetchDisposalSites = async (page = 1) => {
      if (selectedProjectForUpdateForm && selectedDebrisTypeForUpdateForm && disposalSiteType) {
        const query = `projects=${selectedProjectForUpdateForm}&debris_type=${selectedDebrisTypeForUpdateForm}&site_type=${disposalSiteType}&is_active=true&page=${page}`;
        main_api.get(adminAPIsEndPoints.LIST_DISPOSAL_SITES(query))
        .then((response) => {
            const result = response?.data?.results;
            if (response.data.next) {
              const urlObj = new URL(response.data.next);
              const params = new URLSearchParams(urlObj.search);
              const pageNumber = params.get('page');
              setDisposalSiteOptionsPage(pageNumber);
            } else {
              setDisposalSiteOptionsPage(null);
            }
            let options = [...disposalSiteOptions, ...result?.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }))];

            if (updateTicketValues?.disposal_site) {
              const selectedOption = {
                ...updateTicketValues.disposal_site,
                label: updateTicketValues.disposal_site.name.charAt(0).toUpperCase() + updateTicketValues.disposal_site.name.slice(1),
                value: updateTicketValues.disposal_site.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }

            const uniqueOptions = options.reduce((acc, current) => {
              const existing = acc.find(item => item.value === current.value);
              if (existing) {
                // Compare the number of keys and keep the one with more information
                if (Object.keys(current).length > Object.keys(existing).length) {
                  return acc.map(item => item.value === current.value ? current : item);
                }
                return acc;
              } else {
                acc.push(current);
                return acc;
              }
            }, []);

            setDisposalSiteOptions(uniqueOptions);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setDisposalSiteOptions([]);
      }
    };

    const fetchFieldMonitors = async (page = 1) => {
      if (selectedProjectForUpdateForm) {
        const query = `projects=${selectedProjectForUpdateForm}&page=${page}`
        main_api.get(adminAPIsEndPoints.LIST_MONITORS(query))
          .then((response) => {
              const result = response?.data?.results;
              if (response.data.next) {
                const urlObj = new URL(response.data.next);
                const params = new URLSearchParams(urlObj.search);
                const pageNumber = params.get('page');
                setFieldMonitorOptionsPage(pageNumber);
              } else {
                setFieldMonitorOptionsPage(null);
              }

              // let fieldMonitorOptions = result?.map(item => ({
              //   label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              //   value: item.id,
              // }));
              let options = [...fieldMonitorOptions, ...result?.map(item => ({
                ...item,
                label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                value: item.id,
              }))];
              
              if (updateTicketValues?.field_monitor !== null && updateTicketValues?.field_monitor !== undefined) {
                const selectedOption = {
                  // ...updateTicketValues.field_monitor,
                  label: updateTicketValues.field_monitor.name.charAt(0).toUpperCase() + updateTicketValues.field_monitor.name.slice(1),
                  value: updateTicketValues.field_monitor.id,
                };
                options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
              }

              const uniqueOptions = options.reduce((acc, current) => {
                const existing = acc.find(item => item.value === current.value);
                if (existing) {
                  // Compare the number of keys and keep the one with more information
                  if (Object.keys(current).length > Object.keys(existing).length) {
                    return acc.map(item => item.value === current.value ? current : item);
                  }
                  return acc;
                } else {
                  acc.push(current);
                  return acc;
                }
              }, []);

              setFieldMonitorOptions(uniqueOptions);
          })
          .catch((error) => {
              pushNotification(error?.response?.data?.detail, "error");
          });
      } else {
        setFieldMonitorOptions([]);
      }
    };

    const fetchSiteMonitors = async ( page = 1) => {
      if (selectedProjectForUpdateForm) {
        const query = `projects=${selectedProjectForUpdateForm}&page=${page}`
        main_api.get(adminAPIsEndPoints.LIST_MONITORS(query))
        .then((response) => {
            const result = response?.data?.results;
            if (response.data.next) {
              const urlObj = new URL(response.data.next);
              const params = new URLSearchParams(urlObj.search);
              const pageNumber = params.get('page');
              setSiteMonitorOptionsPage(pageNumber);
            } else {
              setSiteMonitorOptionsPage(null);
            }

            // let siteMonitorOptions = result?.map(item => ({
            //   label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
            //   value: item.id,
            // }));

            let options = [...siteMonitorOptions, ...result?.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }))];
            
            if (updateTicketValues?.site_monitor !== null && updateTicketValues?.site_monitor !== undefined) {
              const selectedOption = {
                // ...updateTicketValues.site_monitor,
                label: updateTicketValues.site_monitor.name.charAt(0).toUpperCase() + updateTicketValues.site_monitor.name.slice(1),
                value: updateTicketValues.site_monitor.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }
            const uniqueOptions = options.reduce((acc, current) => {
              const existing = acc.find(item => item.value === current.value);
              if (existing) {
                // Compare the number of keys and keep the one with more information
                if (Object.keys(current).length > Object.keys(existing).length) {
                  return acc.map(item => item.value === current.value ? current : item);
                }
                return acc;
              } else {
                acc.push(current);
                return acc;
              }
            }, []);

            setSiteMonitorOptions(uniqueOptions);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setSiteMonitorOptions([]);
      }
  };


    const fetchTruckNumber = async ( page = 1) => {
      if (selectedProjectForUpdateForm) {
        const query = `project=${selectedProjectForUpdateForm}&is_active=true&page=${page}`
        main_api.get(`${adminAPIsEndPoints.LIST_TRUCK(query)}&page=${page}`)
        .then((response) => {
            const result = response?.data?.results;
            if (response.data.next) {
              const urlObj = new URL(response.data.next);
              const params = new URLSearchParams(urlObj.search);
              const pageNumber = params.get('page');
              setTruckNumberOptionsPage(pageNumber);
            } else {
              setTruckNumberOptionsPage(null);
            }

            // let options = result?.map(item => ({
            //   ...item,
            //   label: item.truck_number,
            //   value: item.id,
            // }));
            let options = [...truckNumberOptions, ...result?.map(item => ({
              ...item,
              label: item.truck_number,
              value: item.id,
            }))];

            if (updateTicketValues?.truck) {
              const selectedOption = {
                ...updateTicketValues.truck,
                label: updateTicketValues?.truck?.truck_number,
                value: updateTicketValues?.truck?.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }

            const uniqueOptions = options.reduce((acc, current) => {
              const existing = acc.find(item => item.value === current.value);
              if (existing) {
                // Compare the number of keys and keep the one with more information
                if (Object.keys(current).length > Object.keys(existing).length) {
                  return acc.map(item => item.value === current.value ? current : item);
                }
                return acc;
              } else {
                acc.push(current);
                return acc;
              }
            }, []);
            
            setTruckNumberOptions(uniqueOptions);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setTruckNumberOptions([]);
      }
  };



const fetchHazardTypes = async (page = 1) => {
    const query = `is_active=true&page=${page}`;
    main_api.get(adminAPIsEndPoints.LIST_HAZARD_TYPE(query))
    .then((response) => {
        const result = response?.data?.results;
        if (response.data.next) {
          const urlObj = new URL(response.data.next);
          const params = new URLSearchParams(urlObj.search);
          const pageNumber = params.get('page');
          setHazardTypeOptionsPage(pageNumber);
        } else {
          setHazardTypeOptionsPage(null);
        }
        let options =  [...hazardTypeOptions, ...result?.map(item => ({
          ...item,
          label: item.type.charAt(0).toUpperCase() + item.type.slice(1),
          value: item.id,
        }))];
        if (updateTicketValues?.hazard_type) {
          const selectedOption = {
            ...updateTicketValues.hazard_type,
            label: updateTicketValues.hazard_type.name.charAt(0).toUpperCase() + updateTicketValues.hazard_type.name.slice(1),
            value: updateTicketValues.hazard_type.id,
          };
          options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
        }
        const uniqueOptions = options.reduce((acc, current) => {
          const existing = acc.find(item => item.value === current.value);
          if (existing) {
            // Compare the number of keys and keep the one with more information
            if (Object.keys(current).length > Object.keys(existing).length) {
              return acc.map(item => item.value === current.value ? current : item);
            }
            return acc;
          } else {
            acc.push(current);
            return acc;
          }
        }, []);
        setHazardTypeOptions(uniqueOptions);
    })
    .catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
    });
};

const fetchHazardNames = async ( page = 1) => {
  if (hazardTypeSelected) {
    const query = `hazard_type=${hazardTypeSelected}&is_active=true&page=${page}`
    main_api.get(adminAPIsEndPoints.LIST_HAZARD_NAME(query))
    .then((response) => {
        const result = response?.data?.results;
        if (response.data.next) {
          const urlObj = new URL(response.data.next);
          const params = new URLSearchParams(urlObj.search);
          const pageNumber = params.get('page');
          setHazardNameOptionsPage(pageNumber);
        } else {
          setHazardNameOptionsPage(null);
        }
        let options =  [...hazardNameOptions, ...result?.map(item => ({
          ...item,
          label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          value: item.id,
        }))];

        if (updateTicketValues?.hazard_name) {
          const selectedOption = {
            ...updateTicketValues.hazard_name,
            label: updateTicketValues.hazard_name.name.charAt(0).toUpperCase() + updateTicketValues.hazard_name.name.slice(1),
            value: updateTicketValues.hazard_name.id,
          };
          options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
          
        }
        const uniqueOptions = options.reduce((acc, current) => {
          const existing = acc.find(item => item.value === current.value);
          if (existing) {
            // Compare the number of keys and keep the one with more information
            if (Object.keys(current).length > Object.keys(existing).length) {
              return acc.map(item => item.value === current.value ? current : item);
            }
            return acc;
          } else {
            acc.push(current);
            return acc;
          }
        }, []);

        setHazardNameOptions(uniqueOptions);
    })
    .catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
    });
  } else {
    setHazardNameOptions([]);
  } 
}



    //--------------------------- Fetch Attachments-----------------------//

    const fetchAttachments = async () => {
      // API call to fetch attachments
      const objectId = updateTicketValues?.id;
      if (objectId) {
          const query = `object_id=${objectId}&module_name=ticket`;
          main_api.get(adminAPIsEndPoints.LIST_UPLOADED_FILES(query))
          .then((response) => {
            const result = response?.data?.results;
            let attachments = result?.map(item => ({
              key: item?.key,
              url: item?.url,
              name: item?.key.split("/").pop(),
              type: item?.type,
              attachment_type: item?.attachment_type
            }))
            setUploadedFiles(attachments);
          })
          .catch((error) => {
              pushNotification(error?.response?.data?.detail, "error");
          });
      }   
  }

const generateUnitOptions = () => {
  return Object.entries(fields)
      .filter(([key, value]) => key.includes("_unit") && value === true)
      .map(([key]) => {
          let label = key.replace("_unit", ""); 
          label = label.replace("_", " ");
          const titleCasedLabel = label.charAt(0).toUpperCase() + label.slice(1);
          // const capitalizedLabel = label.toUpperCase();
          return {
              label: titleCasedLabel,
              value: key.replace("_unit", ""),
          };
      });
};

useEffect(() => {
  const unitOptions = generateUnitOptions();
  setUnitOptions(unitOptions);
  }
, [fields]);

    useEffect(() => {
        fetchProjects();
        // fetchHazardTypes();
        setDisposalSiteType('temporary');
        form.setFieldsValue({site_type:'temporary'});
    }, []);

    useEffect(() => {
      form.setFieldsValue({unit_of_measure: unitOptions[0]?.value});
    }, [unitOptions]);


    useEffect(() => {
      if (hazardTypeSelected) {
        fetchHazardNames();
        
      }
    }, [hazardTypeSelected]);

    useEffect(() => {
      fetchSubActivityTypes();
      fetchDebrisTypes();
      fetchFieldMonitors();
      fetchSiteMonitors();
      fetchTruckNumber();
      fetchHazardTypes();

    }, [selectedProjectForUpdateForm]);

    useEffect(() => {
        fetchDisposalSites();
    }, [selectedDebrisTypeForUpdateForm, selectedProjectForUpdateForm, disposalSiteType]);


    useEffect(() => {
      if(addressLatAndLong){

        let latitude = addressLatAndLong[0] || null;
        form.setFieldValue("latitude", latitude);

        let longitude = addressLatAndLong[1] || null;
        form.setFieldValue("longitude", longitude);
      }
    }, [addressLatAndLong]);

    useEffect(() => {
      if(addressLatAndLong[0] !== null && addressLatAndLong[1] !== null && disposalSiteAddressLatAndLong[0] !== null && disposalSiteAddressLatAndLong[1] !== null){
        const lat1 = addressLatAndLong[0];
        const lon1 = addressLatAndLong[1];
        const lat2 = disposalSiteAddressLatAndLong[0];
        const lon2 = disposalSiteAddressLatAndLong[1];
        const calculatedDistance = distanceCalculator(lat1, lon1, lat2, lon2);
        setDistance(calculatedDistance);
        form.setFieldsValue({ distance: calculatedDistance });
      } else {
        setDistance(0);
        form.setFieldsValue({ distance: 0 });
      }
    }, [addressLatAndLong, disposalSiteAddressLatAndLong]);
    //----------------------- Event Handlers -----------------------//


    const handleProjectChange = (value) => {
        setSelectedSubActivityForUpdateForm(null);
        setSelectedDebrisTypeForUpdateForm(null);
        const data = projectOptions.filter(item => item.id === value)[0];
        // const disposalSite = data?.disposal_site[0];
        // if (disposalSite) {
        //     setDisposalSiteAddressLatAndLong([disposalSite.latitude, disposalSite.longitude]);
        // } else {
        //     setDisposalSiteAddressLatAndLong([null, null]);
        // }
        setSelectedProjectData(data);
        // setProjectSelected(value);
        setSelectedProjectForUpdateForm(value);
        form.setFieldsValue({ project: value });
        form.setFieldsValue({ sub_activity: null });
        form.setFieldsValue({ debris_type: null });
    }

    const handleSubActivityChange = (value) => {
        // setSubActivitySelected(value);
        setSelectedSubActivityForUpdateForm(value);
        form.setFieldsValue({ sub_activity: value });
    }

    const handleDebrisTypeChange = (value) => {
        // setDebrisTypeSelected(value);
        setSelectedDebrisTypeForUpdateForm(value);
        const selectedDebris = debrisTypeOptions.filter(item => item.id === value)[0];
        
        setRequiredPhotoCount({
          field_photo_count: selectedDebris?.field_photo_count,
          site_photo_count: selectedDebris?.site_photo_count
        });
        setFields(selectedDebris);
        setSelectedDebrisTypeData(selectedDebris);
        form.setFieldsValue({ debris_type: value });
    }

    const handleSiteTypeChange = (e) => {
      setDisposalSiteOptions([]);
      form.setFieldsValue({ disposal_site: null });
      setDisposalSiteSelected(null);
      setDisposalSiteType(e.target.value);

    };

    const handleDisposalSiteChange = (value) => {
        setDisposalSiteSelected(value);
        const disposalSite = disposalSiteOptions.filter(item => item.id === value)[0];
        if (disposalSite) {
            setDisposalSiteAddressLatAndLong([disposalSite.latitude, disposalSite.longitude]);
        } else {
            setDisposalSiteAddressLatAndLong([null, null]);
        }
        form.setFieldsValue({ disposal_site: value });
    }

    const handleFieldMonitorChange = (value) => {
        setFieldMonitorSelected(value);
        form.setFieldsValue({ field_monitor: value });
    }

    const handleSiteMonitorChange = (value) => {
        setSiteMonitorSelected(value);
        form.setFieldsValue({ site_monitor: value });
    }

    const handleTruckNumberChange = (value) => {
        setTruckNumberSelected(value);
        const selectedTruck = truckNumberOptions.filter(item => item.id === value)[0];
        const capacity = selectedTruck?.bed_capacity;
        setTruckCapacity(capacity);
        const loadCall = updateTicketValues?.load_call || form.getFieldValue("load_call");
        const bedCapacity = selectedTruck?.bed_capacity;
        if (loadCall && bedCapacity) {
          const loadVolume = bedCapacity * loadCall / 100;
          setTruckLoadVolume(loadVolume);
          form.setFieldsValue({ truck_load_volume: loadVolume });
        } else {
          setTruckLoadVolume(0);
          form.setFieldsValue({ truck_load_volume: 0 });
        }
        form.setFieldsValue({ truck: value });
        form.setFieldsValue({ truck_capacity: capacity });
    }

    const handleHazardTypeChange = (value) => {
        setHazardTypeSelected(value);
        form.setFieldsValue({ hazard_type: value });
    }
    const handleHazardNameChange = (value) => {
      setHazardNameSelected(value);
      form.setFieldsValue({ hazard_name: value });
  }


    

   

    const handleRoeChange = (e) => {
      const value = e.target.value;
      setShowReasonField(value);
      form.setFieldsValue({ roe: value });
    }

    //-------------------------- Add Click --------------------------//

    const handleAddClick = () => {
      const projectValue = form.getFieldValue('project');
      const subActivityValue = form.getFieldValue('sub_activity');
      const debrisTypeValue = form.getFieldValue('debris_type');
      if (!projectValue) {
        message.error("Please select your Project.");
        return;
      }
      if (!subActivityValue) {
        message.error("Please select Sub Activity.");
        return;
      }
      if (!debrisTypeValue) {
        message.error("Please select Debris Type.");
        return;
      }
      setShowUpdateTicketForm(true);
    }

 
    //----------------------- Dynamic Form Fields -----------------------//
   

    const generateFormItem = (fieldKey, fieldConfig, isRequired, isOptional) => {
      const { label, type } = fieldConfig;
      const rules = getFieldValidationRules(type, label, fieldKey, isRequired, isOptional);
      let inputComponent;
      if (isRequired === false && isOptional === false) {
        return null;
      }
      switch (type) {
          case "numeric":
          case "numeric_currency":
          case "quantity_required":
              inputComponent = <InputNumber placeholder='Enter Here' style={{width:"100%"}} />;
              break;
          case "dropdown":
              inputComponent = (
                <>
                
                  <PaginatedSelect
                      fetchData={fieldKey === "hazard_type" ? fetchHazardTypes : fieldKey === "disposal_site" ? fetchDisposalSites : null}
                      placeholder="Select"
                      options={fieldKey === "hazard_type" ? hazardTypeOptions : fieldKey === "disposal_site" ? disposalSiteOptions : []}
                      value={fieldKey === "hazard_type" ? hazardTypeSelected : fieldKey === "disposal_site" ? disposalSiteSelected : null}
                      onChange={fieldKey === "hazard_type" ? handleHazardTypeChange : fieldKey === "disposal_site" ? handleDisposalSiteChange : null}
                      pageNumber={fieldKey === "hazard_type" ? hazardTypeOptionsPage : fieldKey === "disposal_site" ? disposalSiteOptionsPage : null}
                  />

                 
                  </>
              );
              break;
          case "yes_no":
              inputComponent = (
                 fieldKey === 'roe' ? 
                 ( 
                  <Radio.Group 
                    onChange={handleRoeChange}
                    value={showReasonField}
                    style={{display:"flex", flexDirection:"row"}} 
                  >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                  </Radio.Group>
                  )
                  :
                  (
                    <Radio.Group 
                      style={{display:"flex", flexDirection:"row"}} 
                      onChange={() => {
                    }}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                  )
              );
              break;
          default:
              inputComponent = (
                    <Input placeholder='Enter Here' />
            );
              break;
      }
  
      return (


          <FormItem key={fieldKey} label={label} name={fieldKey} rules={rules}>
              {inputComponent}
              {fieldKey === "disposal_site" && (
                    <FormItem 
                      name="site_type" 
                      style={{marginTop:"10px"}}
                    >
                      <Radio.Group onChange={handleSiteTypeChange} value={disposalSiteType}>
                        <Radio value="final">Final</Radio>
                        <Radio value="temporary">Temporary</Radio>
                      </Radio.Group>
          
                    </FormItem>
                  )}

                  {
                    fieldKey === "hazard_type" && (
                      <FormItem
                        name="hazard_name"
                        label="Hazard Name"
                        style={{marginTop:"20px"}}
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Hazard Name",
                          },
                        ]}
                      >
                      <PaginatedSelect
                        fetchData={fetchHazardNames}
                        placeholder="Select"
                        options={hazardNameOptions}
                        value={hazardNameSelected}
                        onChange={handleHazardNameChange}
                        pageNumber={hazardNameOptionsPage}
                      />
                    </FormItem>
                    )

                  }
              {fieldKey === "roe" && showReasonField && (
                <FormItem
                  name="roe_reason"
                  label="Reason"
                  style={{marginTop:"20px"}}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Reason",
                    },
                    {
                      validator: (_, value) => {
                        if (!value || value.trim().length === 0) {
                          return Promise.reject(new Error("Reason cannot be empty"));
                        }
                        if (value.length > 100) {
                          return Promise.reject(new Error("Reason must be at most 100 characters"));
                        }
                        // Regex validation: only letters, numbers, and spaces allowed
                        if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
                          return Promise.reject(new Error("Reason can only include letters, numbers, and spaces"));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                    <Input placeholder='Enter Here' />
                </FormItem>
              )}
          </FormItem>
      );
  };


  return (
    <CustomModal  
    open={isModalOpen}
    title={title}
    width="630px"
    height={showUpdateTicketForm === true ? "580px" : "380px"}
    onCancel={() => {
      form.resetFields();
      setShowUpdateTicketForm(false);
      setSelectedProjectForUpdateForm(null);
      setSelectedSubActivityForUpdateForm(null);
      setSelectedDebrisTypeForUpdateForm(null);
      setProjectSelected(null);
      setSubActivitySelected(null);
      setDebrisTypeSelected(null);
      setDisposalSiteSelected(null);
      setHazardTypeSelected(null);
      setFieldMonitorSelected(null);
      setSiteMonitorSelected(null);
      setTruckNumberSelected(null);
      setDistance(0);
      setTruckCapacity(0);
      setTruckLoadVolume(0);
      setFields({});
      setRequiredPhotoCount({});
      setFile(null);
      setUploadedFiles([]);
      setSavedFileUrl(null);
      setAddress("");
      setAddressLatAndLong([null,null]);
      setDisposalSiteAddressLatAndLong([null,null]);
      setModalOpen(false);
    }}
    
    footer={null}
    maskClosable={false}
    // isScrollable={true}
  >
  <Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
    
    <Form name="updateTicketForm" onFinish={onFinish} form={form} layout="vertical" >
      <FormWrapper height={showUpdateTicketForm === true ? "585px" : "285"}>
       {showUpdateTicketForm === false  &&  !updateTicketValues &&(
        <>
        
        <FormItem 
            name="project" 
            label="Project"
            rules={[
              {
                required: true,
                message: "Please Select your Project",
              },
            ]} 
          >
            <PaginatedSelect fetchData={fetchProjects} placeholder="Select" options={projectOptions} value={selectedProjectForUpdateForm} onChange={handleProjectChange} style={{width:"100%"}}/>
  
        </FormItem>
      
        <FormItem
            name="sub_activity" 
            label="Sub Activity"
            rules={[
              {
                required: true,
                message: "Please Select Sub Activity",
              },
            ]} 
          >
            <PaginatedSelect fetchData={fetchSubActivityTypes} placeholder="Select" options={subActivityTypeOptions} value={selectedSubActivityForUpdateForm} onChange={handleSubActivityChange} style={{width:"100%"}}/>

        </FormItem>
        <FormItem 
            name="debris_type" 
            label="Debris Type"
            rules={[
              {
                required: true,
                message: "Please Select Debris Type",
              },
            ]} 
          >
            <PaginatedSelect fetchData={fetchDebrisTypes} placeholder="Select" options={debrisTypeOptions} value={selectedDebrisTypeForUpdateForm} onChange={handleDebrisTypeChange} pageNumber={debrisTypeOptionsPage} style={{width:"100%"}}/>
  
        </FormItem>
        </>
        )}
   
        {showUpdateTicketForm && (
          <>
          <div style={{display:"flex", flexDirection:"column", background:"#EBF0F7", padding:"20px", marginBottom:"20px"}}>
              <span style={{display:"flex", flexDirection:"row" , alignItems: "center", justifyContent:"space-evenly"}} >

                <span style={{display:"flex", flexDirection:"row" , alignItems: "center"}}>
                  <Heading text="Project:" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#344054" />
                  <Heading text={updateTicketValues?.project?.name || selectedProjectData?.name} margin="0px 0px 0px 10px" fontSize="1rem" color="#3669AE" />
                </span>
                <span style={{display:"flex", flexDirection:"row", alignItems: "center", marginLeft:"30px"}}>
                  <Heading text="Debris Type:" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#344054" />
            
                  <Heading text={updateTicketValues?.debris_type?.name || selectedDebrisTypeData?.name} margin="0px 0px 0px 10px" fontSize="1rem" color="#3669AE" />
                </span>
                
              </span>
              
              {/* <span style={{display:"flex", flexDirection:"row" , alignItems: "center", marginTop:"20px",  justifyContent:"space-evenly"}}>

                <span style={{display:"flex", flexDirection:"row", alignItems: "center", marginLeft:"30px"}}>
                  <Heading text="Event:" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#344054" />
                  <Heading text={updateTicketValues?.project?.event?.name || selectedProjectData?.event?.name} margin="0px 0px 0px 10px" fontSize="1rem" color="#3669AE" />
                </span>

                <span style={{display:"flex", flexDirection:"row", alignItems: "center", marginLeft:"30px"}}>
                  <Heading text="Debris Type:" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#344054" />
                  <Heading text={updateTicketValues?.debris_type?.name || selectedDebrisTypeData?.name} margin="0px 0px 0px 10px" fontSize="1rem" color="#3669AE" />
                </span>
            

              </span> */}
          </div>
          <FormItem 
            name="truck" 
            label="Truck Number"
            rules={[
              {
                required: true,
                message: "Please Enter Ticket Number",
              },
              {
                validator: (_, value) => {
                    if (!value) {
                        return Promise.resolve(); // Skip validation if empty
                    }
                    if (!/^[a-zA-Z0-9-]*$/.test(value)) {
                        return Promise.reject(new Error("Truck Number can only contain letters, numbers, and dashes"));
                    }
                    return Promise.resolve();
                }
            },
            ]} 
          >
            <PaginatedSelect fetchData={fetchTruckNumber} placeholder="Select" options={truckNumberOptions} value={truckNumberSelected} onChange={handleTruckNumberChange} pageNumber={truckNumberOptionsPage} style={{width:"100%"}}/>

          </FormItem>
        
          
          <div style={{display:"flex", flexDirection:"row"}}>

          <FormItem
            name="field_monitor" 
            label="Field Monitor"
            rules={[
              {
                required: true,
                message: "Please Select a Field Monitor",
              },
            ]}
          >
            <PaginatedSelect fetchData={fetchFieldMonitors} placeholder="Select" options={fieldMonitorOptions} value={fieldMonitorSelected} onChange={handleFieldMonitorChange} pageNumber={fieldMonitorOptionsPage} />
          </FormItem>
          <FormItem
            name="site_monitor" 
            label="Site Monitor"
            style={{marginLeft:"10px"}}
            rules={[
              {
                required: true,
                message: "Please Select a Site Monitor",
              },
            ]}
          >
            <PaginatedSelect fetchData={fetchSiteMonitors} placeholder="Select" options={siteMonitorOptions} value={siteMonitorSelected} onChange={handleSiteMonitorChange} pageNumber={siteMonitorOptionsPage} />
          </FormItem>
          </div>
          <div style={{display:"flex", flexDirection:"row"}}>
            <FormItem
              name="load_date"
              label="Load Date"
              rules={[
                {
                  required: true,
                  message: "Please Select Load Date",
                },
                
              ]}
            >
              <DatePicker 
                placeholder="MM/DD/YYYY" 
                format="MM/DD/YYYY"
                style={{width:"285px"}}
              />
            </FormItem>
          
            <FormItem
              name="load_time"
              label="Load Time"
              style={{marginLeft:"10px"}}
              rules={[
                {
                  required: true,
                  message: "Please Select Time",
                },
              ]}
            >
              <TimePicker placeholder="HH:MM:SS" style={{ width: "285px"}} format="HH:mm:ss"/>

            </FormItem>
          </div>

          <div style={{display:"flex", flexDirection:"row"}}>
            <FormItem
              name="disposal_date"
              label="Disposal Date"
              dependencies={['load_date', 'load_time']}
              rules={[
                {
                  required: true,
                  message: "Please select Disposal Date",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const loadDate = getFieldValue('load_date');
                    if (!value || !loadDate || value.isAfter(loadDate)) {
                      return Promise.resolve();
                    }
                    if (value.isSame(loadDate)) {
                      return Promise.resolve(); // Disposal date can be the same, time will be checked separately
                    }
                    return Promise.reject(new Error('Disposal Date cannot be before Load Date'));
                  },
                }),
              ]}
            >
              <DatePicker 
                placeholder="MM/DD/YYYY" 
                format="MM/DD/YYYY"
                style={{width:"285px"}} 
              />
            </FormItem>
           
            <FormItem
              name="disposal_time"
              label="Disposal Time"
              dependencies={['load_date', 'load_time', 'disposal_date']}
              style={{marginLeft:"10px"}}
              rules={[
                {
                  required: true,
                  message: "Please Select Time",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const loadDate = getFieldValue('load_date');
                    const loadTime = getFieldValue('load_time');
                    const disposalDate = getFieldValue('disposal_date');
                    
                    if (!value || !loadTime) {
                      return Promise.resolve();
                    }
          
                    // If disposal date is the same as load date, disposal time must be strictly after load time
                    if (disposalDate && disposalDate.isSame(loadDate)) {
                      if (value.isSame(loadTime)) {
                        return Promise.reject(new Error('Disposal Time must be after Load Time'));
                      }
                      if (value.isBefore(loadTime)) {
                        return Promise.reject(new Error('Disposal Time must be after Load Time'));
                      }
                    }
          
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TimePicker placeholder="HH:MM" style={{ width: "285px" }} format="HH:mm:ss" />
            </FormItem>
            
          </div>
          <div 
            style={{display:"flex", flexDirection:"row"}}
          >

        <FormItem
          name="manual_ticket_number"
          label="Manual Ticket Number"
          rules={[
            {
              required: false,
              message: "Please Enter Ticket Number",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }
                if (!/^[a-zA-Z0-9]*$/.test(value)) {
                  return Promise.reject(new Error("Ticket Number must be alphanumeric"));
                }
                if (value.length < 2 || value.length > 50) {
                  return Promise.reject(new Error("Ticket Number must be between 2 and 50 characters"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Enter Here" />
        </FormItem>


          <FormItem
            name="status"
            label="Ticket Status"
            style={{marginLeft:"10px"}}
            >
              <Select placeholder="Select" options={ticketStatusOptions} style={{width:"100%"}} />
            </FormItem>
          <FormItem
            name="unit_of_measure"
            label="Unit of Measure"
            style={{marginLeft:"10px"}}
            rules={[
              {
                validator: (_, value) => {
                  
                  // Check if value is null or undefined (meaning no selection was made)
                  if (!value) {
                    return Promise.reject(new Error("Please select a valid Unit of Measure"));
                  }
          
                  // If a valid value exists, resolve the validation
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Select placeholder="Select"  options={unitOptions} style={{width:"100%"}} />
          </FormItem>

          </div>
          <LocationSelector
            address={address}
            setAddress={setAddress}
            setAddressLatAndLong={setAddressLatAndLong}
            form={form}
            checked={false}
            label='Address'
            addressLatAndLong={addressLatAndLong}
            name='address'
          />
          
          <span style={{display:"flex", flexDirection:"row"}}>
            <FormItem
              name="latitude"
              label="Latitude"
              >
              <Input value={addressLatAndLong[0] || null} disabled={true}/>
            </FormItem>
            <FormItem
              name="longitude"
              label="Longitude"
              style={{marginLeft:"10px"}}
            >
              <Input value={addressLatAndLong[1] || null} disabled={true}/>
            </FormItem>
          </span>
            <FormItem
              name="truck_capacity"
              label="Truck Capacity"
            >
                <Input value={truckCapacity} disabled={true}/>
            </FormItem>
           
          <div style={{display:"flex", flexDirection:"row"}}>
            <FormItem
              name="truck_load_volume"
              label="Truck Load Volume Cubic Yards"
            >
                <Input value={truckLoadVolume} disabled={true}/>
            </FormItem>
            <FormItem
              name="distance"
              label="Distance"
              style={{marginLeft:"10px"}}
            >
              <Input value={distance} disabled={true} />
            </FormItem>
          </div>
          

          
          
          {Object.entries(fields?.rate_matrix_fields).map(([key, requirement]) => {
                const isRequired = requirement === "required" ? true : false;
                const isOptional = requirement === "optional" ? true : false;
                const fieldConfig = fieldsMap[key];

                if (fieldConfig) {
                    return generateFormItem(key, fieldConfig, isRequired, isOptional);
                }
                return null;
            })}

          <FormItem 
            name="field_monitor_notes" 
            label="Field Monitor Notes"
            rules={[
              {
                required: false,
                message: "Field Monitor Notes are optional",
              },
              {
                validator: (_, value) => {
                  if (value && value.length > 500) {
                    return Promise.reject(new Error("Notes should not exceed 500 characters"));
                  }
                  if (value && !/^[a-zA-Z0-9\s.,-]*$/.test(value)) {
                    return Promise.reject(new Error("Notes should only include letters, numbers, spaces, periods, commas, and hyphens"));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <TextArea placeholder="Add Notes" />
          </FormItem>
          <FormItem 
            name="site_monitor_notes" 
            label="Site Monitor Notes"
            rules={[
              {
                required: false,
                message: "Site Monitor Notes are optional",
              },
              {
                validator: (_, value) => {
                  if (value && value.length > 500) {
                    return Promise.reject(new Error("Notes should not exceed 500 characters"));
                  }
                  if (value && !/^[a-zA-Z0-9\s.,-]*$/.test(value)) {
                    return Promise.reject(new Error("Notes should only include letters, numbers, spaces, periods, commas, and hyphens"));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <TextArea placeholder="Add Notes" />
          </FormItem>

          <FormItem
            name="attachments"
            label="Attachments"
          >
            <CustomUploader file={file} setFile={setFile} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} directory={"ticket"} />
          </FormItem>
          </>
        )}
     
  
        </FormWrapper>
        <Divider/>
      <SaveContainer className="d-flex justify-content-end">
        <CustomButton
          btnText={"Cancel"}
          margin="0px 5px"
          noBackground
          hideIcon={true}
          onClick={() => {
            form.resetFields();
            setShowUpdateTicketForm(false);
            setSelectedProjectForUpdateForm(null);
            setSelectedSubActivityForUpdateForm(null);
            setSelectedDebrisTypeForUpdateForm(null);
            setProjectSelected(null);
            setSubActivitySelected(null);
            setDebrisTypeSelected(null);
            setDisposalSiteSelected(null);
            setHazardTypeSelected(null);
            setFieldMonitorSelected(null);
            setSiteMonitorSelected(null);
            setTruckNumberSelected(null);
            setDistance(0);
            setTruckCapacity(0);
            setTruckLoadVolume(0);
            setFields({});
            setRequiredPhotoCount({});
            setFile(null);
            setUploadedFiles([]);
            setSavedFileUrl(null);
            setAddress("");
            setAddressLatAndLong([null,null]);
            setDisposalSiteAddressLatAndLong([null,null]);
            setModalOpen(false);
          }}
        />
        {
          showUpdateTicketForm === true ? (
            <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
          ) 
          :
          (
            <Button
              type="button"
              onClick={handleAddClick}
              style={{background:"#3669AE", color:"white", border:"none", width:"80px", height:"40px"}}
            >
              Add
            </Button>
            // <CustomButton btnText={"Add"} color={"white"} type="button" onClick={() => setShowUpdateTicketForm(true)} hideIcon={true} />
          )
        }
      </SaveContainer>
    </Form>
  </CustomModal>
  )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
  };
  
  const FormItem = styled(Form.Item)`
  width: 100%;
  `;

  const DynamicFormItem = styled(Form.Item)`
  flex: 1 1 calc(33.333% - 16px); /* Adjust based on the number of columns desired */
  box-sizing: border-box;
  
  @media (max-width: 768px) {
      flex: 1 1 calc(50% - 16px); /* 2 columns on smaller screens */
  }

  @media (max-width: 480px) {
      flex: 1 1 100%; /* Full width on very small screens */
  }
`;
  
  const FormWrapper = styled.div`
  height: ${(props) => props.height};
  width:  600px;
  padding: 5px;
  overflow: auto;
  
  /* Custom scrollbar styling */
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #3669AE;
      border-radius: 2px;
      min-height: 30px; /* Ensure the thumb isn't too small */
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `;
  
  const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 625px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
  `;