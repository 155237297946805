import React, { useEffect, useState } from 'react'
import { Card, Input, Row, Select } from 'antd'
import styled from 'styled-components';
import HeadingComponent from '../../components/headingComponent/heading';
import CustomButton from '../../components/customButton/customButton';
import SearchInput from '../../components/searchInput/SearchInput';
import { manageProjectsColumns, userManagementColumns } from '../../util/antdTableColumns';
// import { AntdesignTable } from '../../components/antDesignTable/AntdesignTable';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import UpdateUser from '../../components/modals/userManagement/updateUser';
import { pushNotification } from '../../util/notification';
import { useSelector } from 'react-redux';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { status } from '../../util/dropdownData';
import ResetPassword from '../../components/auth/resetPassword';
import CustomFilter from '../../components/customFilterWithSearchBar/customFilter';
import useProjects from '../hooks/useProjects';
import UpdateProjects from '../modals/manageProjects/updateProjects';
import { useNavigate } from 'react-router-dom';
import PaginatedSelect from '../customSelect/paginatedSelect';
import useEvents from '../hooks/useEvents';
import usePermissions from '../hooks/usePermissions';
import { hasPermission } from '../../util/permissionChecker';


export default function ManageProjects({
  selectedProject,
  setSelectedProject,
  selectedProjectId,
  setSelectedProjectId,
  setProjectDetailsModal,
  setUpdateProjectModal,
  updateProjectModal,
  setPermissionPopUp,
  setPermissionText,
  handleEditRow = () => {},
  handleAddRow = () => {},
  projectsDataForTable,
  setProjectsDataForTable,
  fetchProjects,
  currentPage = 1,
  setCurrentPage = () => {},
}) {


  const navigate = useNavigate();
  //------------------ State Management ---------------------//


  const [clientOptions, setClientOptions] = useState([]);

  const [searchedValue, setSearchedValue] = useState('');
  const [eventSelected, setEventSelected] = useState(null);
  const [clientSelected, setClientSelected] = useState(null);
  const [data, setData] = useState();
  const [count, setCount] = useState(0);
  const rolesState = useSelector((state) => state.roles.roles);

  const roles = rolesState.map((role) => ({
      label: role.name,
      value: role.id,
    }));


  //------------------ Custom Hooks ---------------------//
  
  const  {eventOptions, fetchEvents} = useEvents();



  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
    


 

useEffect(() => {
  fetchProjects();
}, []);

  //--------------------- Fetch Clients ---------------------//

  const fetchClients = async (page = 1) => {
    const query = `is_active=true&page=${page}`;
    main_api.get(adminAPIsEndPoints.LIST_CLIENTS(query))
    .then((response) => {
        const result = response?.data?.results;
        if (result) {
          const options = result.map((item) => {
            item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
            item.value = item.id;
            return item;
          });
         
          setClientOptions(options);
        } else {
          setClientOptions([]);
        }
    })
    .catch((error) => {
        pushNotification(error.response.data.detail, "error");
    });
  };
  

  
  useEffect(() => {
    fetchClients();
  }, []);

  //----------------------- Filter -----------------------//

  useEffect(() => {
    let query = `search=${searchedValue}`;
    if(eventSelected){
      query += `&event=${eventSelected}`;
    }
    if (clientSelected!==null) {
      query+=`&client=${clientSelected}`;
    }
    fetchProjects(query);
  }, [eventSelected, clientSelected, searchedValue]);



  

 


  //-------------------- Function for View Details Modal -----------------//

  const handleViewDetails = (value) => {
    if (value) {

      setSelectedProject(value);
      setProjectDetailsModal(true);
    }
  };
  const handleMapUser = (value) => {
    if (value) {
      setSelectedProjectId(value.id);
      navigate("/project-management/map-user");
    }
  };


  return (
    <div style={{ marginTop: '10px' }}>
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Heading text="Manage Projects" margin="0px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
          <CustomButton btnText={"Add New Project"} color={"white"} onClick={handleAddRow} />
        </div>
        
        <div style={{display: "flex", flexDirection: "row"}}>
          <SearchInputWrapper>
            <SearchInput onBlur={(e) => setSearchedValue(e)} onKeyDown={(e) => setSearchedValue(e)} placeholder={"Search By Name..."} />
          </SearchInputWrapper>
         
          <PaginatedSelect
            value={eventSelected}
            placeholder={"Event Name"}
            options={eventOptions}
            onChange={(e) => {
              setEventSelected(e);
              setClientSelected(null);
            }}
            fetchData={fetchEvents}
            style={{marginLeft:"8px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
          />

          <PaginatedSelect
            value={clientSelected}
            placeholder={"Client Name"}
            options={clientOptions}
            onChange={(e) => setClientSelected(e)}
            fetchData={fetchClients}
            style={{marginLeft:"20px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
          />



          <div
            onClick={() => {
              setEventSelected(null);
              setClientSelected(null);
              setSearchedValue('');
              fetchProjects();
            }}
            style={{cursor:"pointer",color:"#EE3E41",marginLeft:"15px", marginBottom: "20px", position:"relative", top:"20px", left:"6px", width:"260px", height:"40px"}}
          >
            Reset Filter
          </div>
        </div>
        <AntdesignTablePagination 
          columns={manageProjectsColumns({handleViewDetails, handleMapUser})} 
          data={projectsDataForTable.data}
          totalCount={projectsDataForTable.count}
          loadPaginatedData={fetchProjects} 
          allowRowSelection={false}
          tableHeight={430}
          tableWidth={1200} 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
    </CustomCard>
  </div>
  )
}



const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
  };
  
const CustomCard = styled(Card)`
    width: calc(100vw - 40px);
    max-width: 1570px;
    height: calc(100vh - 40px);
    max-height: 690px;
    margin-top: 40px;
    margin-left: 40px;
    background-color: white;
    
    @media (max-width: 768px) {
      width: calc(100vw - 20px);
      height: calc(100vh - 20px);
      margin: 10px;
    }
  `;


const SearchInputWrapper = styled.div`
width: 350px;
margin-left: 5px;
`;
