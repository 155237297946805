import React, { useEffect, useState } from 'react'
import { Button, Card, DatePicker, Divider } from 'antd'
import styled from 'styled-components';
import HeadingComponent from '../headingComponent/heading';
import { ReactComponent as CompanyBrandIcon } from "../../assets/rawSvg/companyLogo.svg";
import SearchInput from '../searchInput/SearchInput';
import { AntdesignTable } from '../antDesignTable/AntdesignTable';
import { debrisCostReportColumns, disposalSiteReportColumns, userRolesColumns } from '../../util/antdTableColumns';
import { pushNotification } from '../../util/notification';
import { getRoles } from "../../redux/slices/roles";
import { getUserRoleList } from '../../util/dataService';
import { useDispatch, useSelector } from 'react-redux';
import CustomFilter from '../customFilterWithSearchBar/customFilter';
import PaginatedSelect from '../customSelect/paginatedSelect';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { main_api } from '../../api/axiosHelper';
import dayjs from 'dayjs';
import { AntdesignTablePagination } from '../antDesignTable/AntdesignTablePagination';

export default function DebrisCostReport() {

    const [projectOptions, setProjectOptions] = useState([]);
    const [projectOptionsPage, setProjectOptionsPage] = useState(1);
    const [projectSelected, setProjectSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const fetchProjectOptions = async (page = 1) => {
        const query = `page=${page}`;
        main_api.post(adminAPIsEndPoints.DASHBOARD_PROJECT(query))
          .then((response) => {
            const result = response?.data?.results;
      
            // Handle pagination
            if (response.data.next) {
              const urlObj = new URL(response.data.next);
              const params = new URLSearchParams(urlObj.search);
              const pageNumber = params.get('page');
              setProjectOptionsPage(pageNumber);
            } else {
              setProjectOptionsPage(null);
            }
             // Generate options array from API response
             let options = [...projectOptions, ...result?.map(project => ({
              ...project,
              label: project.name.charAt(0).toUpperCase() + project.name.slice(1),
              value: project.id,
            }))];
            // Filter out duplicate options and keep the one with more properties
            const uniqueOptions = options.reduce((acc, current) => {
              const existing = acc.find(item => item.value === current.value);
              if (existing) {
                // Compare the number of keys and keep the one with more information
                if (Object.keys(current).length > Object.keys(existing).length) {
                  return acc.map(item => item.value === current.value ? current : item);
                }
                return acc;
              } else {
                acc.push(current);
                return acc;
              }
            }, []);
      
            setProjectOptions(uniqueOptions);
          })
          .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
          });
      };

      useEffect(() => {
        fetchProjectOptions();
      }, []);

    
      const handleReportGeneration = () => {
        if (projectSelected) {
          // fetch data
          const payload = {
            project_id: projectSelected,
            is_sheet_export: false
          }
          main_api.post(adminAPIsEndPoints.DEBRIS_COST_REPORT_XLS,payload)
          .then((response) => {
            if (response?.status === 200) {
              const result = response?.data?.results;
              const transformedData = result?.map(item => ({
                ticketNumber: item?.id,
                debrisType: item?.debris_type?.name.charAt(0).toUpperCase() + item?.debris_type?.name.slice(1),
                unitOfMeasure: item?.unit_of_measure && item?.unit_of_measure !== "" ? item?.unit_of_measure?.toUpperCase() : "---",
                contractorCost: item?.contractor_cost,
                type: item.out_bound? "Outbound" : "Inbound",
              }));
              setCount(response?.data?.count);
              setTableData(transformedData);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
          });
        }
      };
      const handleReportDownload = () => {
        if (projectSelected) {
            const payload = {
                project_id: projectSelected,
                is_sheet_export: true
            }
            main_api.post(adminAPIsEndPoints.DEBRIS_COST_REPORT_XLS,payload)
            .then((response) => {
                if (response?.status === 200) {
                pushNotification("Report downloaded and sent to your registered email successfully!", "success");
                }
            })
            .catch((error) => {
                pushNotification(error?.response?.data?.detail, "error");
            });
        }
      }

  return (
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
    <Heading text="Debris Cost Report" margin="0px 0px 15px 5px" fontSize="1.3rem" color="#3B3B3B" />
    <div style={{display: "flex", flexDirection: "row"}}>
            <PaginatedSelect
              value={projectSelected}
              placeholder={"Select Project"}
              options={projectOptions}
              onChange={(e) => setProjectSelected(e)}
              allowClear={true}
              onClear={() => {
                setProjectSelected(null);
                setIsLoading(true);
              }}
              fetchData={fetchProjectOptions}
              pageNumber={projectOptionsPage}
              style={{marginLeft:"8px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
            />
            <Button onClick={handleReportGeneration} type="primary" style={{
              marginLeft: "8px",
              marginBottom: "20px",
              position: "relative",
              top: "12px",
              left: "780px",
              width: "150px",
              height: "40px",
              color: "white",
              borderRadius: "8px"
            }}>
              Generate Report
            </Button>

    </div>
    <CardWrapper>

        {
        isLoading ?
        
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "450px"}}>
            <Heading text="Please Select a Project to generate the Report" fontSize="1.3rem" color="grey" />
            </div>
            :
               
            <PDFCard>
              <span style={{margin:"40px 0px"}}/>
          
             <AntdesignTablePagination
              columns={debrisCostReportColumns} 
              data={tableData}
              totalCount={count}
              loadPaginatedData={handleReportGeneration} 
              allowRowSelection={false}
              pageSize={10}
              tableHeight={450}
              tableWidth={1100}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PDFCard>
        }
    </CardWrapper>
    {isLoading ?
        null
        :
        <span>
          <Button onClick={handleReportDownload} type="primary" style={{
                  marginLeft: "8px",
                  marginBottom: "20px",
                  position: "relative",
                  top: "12px",
                  left: "475px",
                  width: "150px",
                  height: "40px",
                  color: "white",
                  borderRadius: "8px"
                }}>
                  Download Report
                </Button>
        </span>
      }
    
</CustomCard>
  )
}


const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
  };

  

  const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1270px;
  height: calc(100vh - 40px);
  max-height: 720px;
  margin: 20px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;


const CardWrapper = styled.div`
  height: calc(100vh - 60px);
  max-height: 520px;
  max-width:  1270px;
  overflow: auto;

 /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const PDFCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1170px;
  height: calc(100vh - 40px);
  max-height: 1000px;
  margin: 20px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;


