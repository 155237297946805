
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NoPage from "./pages/noPage";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicOnlyRoute from "./components/routesWrapper/publicOnlyRoute";
import ResetPassword from "./components/auth/resetPassword";
import Authentication from "./components/auth/authentication";
import Dashboard from "./pages/dashboard/dashboard";
import ProtectedRoute from "./components/routesWrapper/ProtectedRoute";
import Administration from "./pages/administration/administration";
import EventManagement from "./pages/eventManagement/eventManagement";
import Settings from "./pages/settings/settings";
import TruckManagement from "./pages/truckManagement/truckManagement";
import TicketManagement from "./pages/ticketManagement/ticketManagement";
import DisposalSite from "./pages/disposalSite/disposalSite";
import UserManagement from "./pages/userManagement/userManagement";
import ProjectManagement from "./pages/projectManagement/projectManagement";
import Maps from "./pages/map/map";
import ContractorManagement from "./pages/contractorManagement/contractorManagement";
import ShapeFile from "./pages/map/shapefile";
import usePermissions from "./components/hooks/usePermissions";
import { hasPermission } from "./util/permissionChecker";
import { useState, useEffect } from "react";
import NoPermissionPage from "./pages/noPermissionPage";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { userHasValidToken } from "./api/auth";
import { ReactComponent as CompanyBrandIcon } from "../src/assets/rawSvg/sidebarNavIcons/logoSmall.svg";
import { useSelector } from "react-redux";
import { dispatch } from "./redux/store";
import { getPermissionsData } from "./redux/slices/permissions";
import Reports from "./pages/reports/reports";

function App() {
    //----------------------- Custom Hooks -----------------------//
    const hasValidToken = userHasValidToken();
    const permissionData = useSelector((state) => state?.permissionsData?.permissionsData);
    const isLoadingPermissions = useSelector((state) => state?.permissionsData?.isLoading);

    const [isLoading, setIsLoading] = useState(true);
    const [assignedPermissionsArray, setAssignedPermissionsArray] = useState([]);

  
    useEffect(() => {
        if (permissionData) {
          setAssignedPermissionsArray(permissionData);
         
        } 
    }, [permissionData]);

    const administrationPermissions = [
        "view_debristype",
        "view_trucktype",
        "view_subactivity",
        "manage_hazard_type",
        "manage_hazard_name",
        "view_hazardname",
        "manage_role",
        "view_position",
        "menu_setup",
      ];

  useEffect(() => {
    if (assignedPermissionsArray.length > 0 || !hasValidToken) {
      setIsLoading(false);
    }
  }, [assignedPermissionsArray]);


  const loadingScreen = <div className="App d-flex justify-content-center align-items-center flex-column" style={{ height: "100vh" }}>
            <Spin
                indicator={
                    <LoadingOutlined
                    style={{
                        fontSize: 80,
                    }}
                    spin
                    />
                }
            />
            <CompanyBrandIcon style={{
              position: "relative",
              bottom: "65px",
              right: "0px",
            }} />

        </div>

  return (
    <>
    {(isLoading || isLoadingPermissions) && loadingScreen}
    <Router>
      <Routes>
        {/* NoPage Route */}
        <Route index element={<NoPage />} />

        {/* SignIn Route */}
        <Route path="signin">
          <Route index element={<PublicOnlyRoute Page={Authentication} />} />
        </Route>

        {/* Reset Password Route */}
        {/* <Route path="reset-password" element={<PublicOnlyRoute Page={ResetPassword} />} /> */}

        {/* Dashboard Route */}
        {/* <Route path="dashboard">
          <Route index element={<ProtectedRoute Page={Dashboard} />} />
        </Route> */}
        <Route 
          path="dashboard"
          element={
            hasPermission(assignedPermissionsArray, "manage_dashboard") ? (
              <ProtectedRoute Page={Dashboard} />
            ) : (
              <ProtectedRoute Page={NoPermissionPage} />
            )
          }
        />
        
        {/* Administration Route */}
        <Route 
            path="administration"
            element={
              !isLoadingPermissions ? (
                hasPermission(assignedPermissionsArray, administrationPermissions) ? (
                  <ProtectedRoute Page={Administration} />
                ) : (
                  <ProtectedRoute Page={NoPermissionPage} />
                )
              ) : (
                loadingScreen
              )
            }
        />
         

        {/* Event Management Route */}
        <Route
          path="event-management"
          element={
            hasPermission(assignedPermissionsArray, "view_event") ? (
              <ProtectedRoute Page={EventManagement} />
            ) : (
              <ProtectedRoute Page={NoPermissionPage} />
            )
          }
        />

        {/* Project Management Routes */}
        <Route 
            path="project-management/manage-project" 
            element={
                hasPermission(assignedPermissionsArray, "view_project") ? (
                    <ProtectedRoute Page={ProjectManagement} />
                  ) : (
                    <ProtectedRoute Page={NoPermissionPage} />
                  )
            }
        />
         
        <Route 
            path="project-management/map-user"
            element={
                hasPermission(assignedPermissionsArray, "manage_map_user") ? (
                    <ProtectedRoute Page={ProjectManagement} />
                  ) : (
                    <ProtectedRoute Page={NoPermissionPage} />
                  )
            }
        />
         

        {/* User Management Route */}
        <Route 
            path="user-management" 
            element={
                hasPermission(assignedPermissionsArray, "view_user") ? (
                    <ProtectedRoute Page={UserManagement} />
                  ) : (
                    <ProtectedRoute Page={NoPermissionPage} />
                  )
            
            }
        />
       

        {/* Disposal Site Route */}
        <Route 
            path="disposal-site"
            element={
                hasPermission(assignedPermissionsArray, "view_disposalsite") ? (
                    <ProtectedRoute Page={DisposalSite} />
                  ) : (
                    <ProtectedRoute Page={NoPermissionPage} />
                )
            }
        />
        

        {/* Ticket Management Route */}
        <Route 
            path="ticket-management"
            element={
                hasPermission(assignedPermissionsArray, "view_ticket") ? (
                    <ProtectedRoute Page={TicketManagement} />
                  ) : (
                    <ProtectedRoute Page={NoPermissionPage} />
                )
            }
        />
         

        {/* Contractor Management Route */}
        <Route 
            path="contractor-management/manage-contractors"
            element={
                hasPermission(assignedPermissionsArray, "view_contractor_user") ? (
                    <ProtectedRoute Page={ContractorManagement} />
                  ) : (
                    <ProtectedRoute Page={NoPermissionPage} />
                )
            }
        />
          
        <Route 
            path="contractor-management/manage-rate-matrix"
            element={
                hasPermission(assignedPermissionsArray, "view_contractorratematrix") ? (
                    <ProtectedRoute Page={ContractorManagement} />
                  ) : (
                    <ProtectedRoute Page={NoPermissionPage} />
                )
            }
        />
        

        {/* Truck Management Route */}
        <Route 
            path="truck-management"
            element={
                hasPermission(assignedPermissionsArray, "view_truck") ? (
                    <ProtectedRoute Page={TruckManagement} />
                  ) : (
                    <ProtectedRoute Page={NoPermissionPage} />
                )
            }
        />
         
        {/* Reporting Route */}
        <Route 
            path="reports"
            element={
                // hasPermission(assignedPermissionsArray, "view_truck") ? (
                    <ProtectedRoute Page={Reports} />
                //   ) : (
                //     <ProtectedRoute Page={NoPermissionPage} />
                // )
            }
        />

        {/* Map Route */}
        <Route path="map">
          <Route index element={<ProtectedRoute Page={Maps} />} />
        </Route>

        {/* Shapefile Route */}
        <Route path="shapefile">
          <Route index element={<ProtectedRoute Page={ShapeFile} />} />
        </Route>

        {/* Settings Route */}
        <Route path="settings">
          <Route index element={<ProtectedRoute Page={Settings} />} />
        </Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
