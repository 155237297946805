import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import dayjs from 'dayjs';
import { Button, DatePicker, Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import { CustomModal } from '../../customModal/customModal';
import CustomButton from '../../customButton/customButton';
import { attachmentTypeOptions, countryCodes, disposalSiteTypeOptions } from '../../../util/dropdownData';
import CustomSwitch from '../../customSwitch/customSwitch';
import HeadingComponent from '../../headingComponent/heading';
import LocationSelector from '../../customAddressInput/locationSelector';
import { useSelector } from 'react-redux';
import { getUserPositionList } from '../../../util/dataService';
import { main_api } from '../../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../../constants/apiEndPoints';
import PaginatedSelect from '../../customSelect/paginatedSelect';
import { pushNotification } from '../../../util/notification';
import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as PlusIcon } from "../../../assets/rawSvg/Plus.svg";
import { ReactComponent as CrossIcon } from "../../../assets/rawSvg/Cross.svg";
import CustomUploader from '../../customUploader/customUploader';

export default function UpdateDisposalSite({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    editDisposalSiteValues,
    file,
    setFile,
    uploadedFiles,
    setUploadedFiles,
    padding = "20px",
}) {

    const [address, setAddress] = useState("");
    const [addressLatAndLong, setAddressLatAndLong] = useState([null,null]);
    const [active, setActive] = useState(editDisposalSiteValues?.is_active || true);
    const [disposalSiteType, setDisposalSiteType] = useState(editDisposalSiteValues?.type.id || null);
    const [debrisType, setDebrisType] = useState(editDisposalSiteValues?.type.id || null);
    const [attachmentType, setAttachmentType] = useState(null);
    const [page, setPage] = useState(1);

    const [debrisTypeOptions, setDebrisTypeOptions] = useState([]);

    // const [file, setFile] = useState(null);
    // const [uploadedFiles, setUploadedFiles] = useState([]);

    const [form] = Form.useForm(); 
   


    //---------------------- Fetch Debris Type Options -------------------//

    const fetchDebrisTypeOptions = async (page = 1) => {
      let query = 'is_active=true';
      main_api.get(`${adminAPIsEndPoints.LIST_DEBRIS(query)}&page=${page}`)
      .then((response) => {
      const result = response.data.results;
      if(response.data.next){
        const urlObj = new URL(response.data.next);

        // Get the search parameters
        const params = new URLSearchParams(urlObj.search);

          // Extract the page number
        const pageNumber = params.get('page');

        setPage(pageNumber);

      } else{
        setPage(null);
      }
      // Create options array from API response
     // Generate options from the result
      let options = [...debrisTypeOptions, ...result?.map(item => ({
        label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        value: item.id,
      }))];

// If there are selected debris types, ensure they are included in the options
    if (editDisposalSiteValues?.debris_type) {
      // Create a set of selected debris types with formatted labels and values
      const selectedTypes = editDisposalSiteValues.debris_type.map(debris => {
        const matchingOption = result.find(item => item.id === debris.id);
        if (matchingOption) {
          return {
            label: matchingOption.name.charAt(0).toUpperCase() + matchingOption.name.slice(1),
            value: matchingOption.id,
          };
        } else{
          return {
            label: debris.name.charAt(0).toUpperCase() + debris.name.slice(1),
            value: debris.id,
          };

        }
        return null;
      }).filter(item => item !== null);

      
      // Ensure selected types are included and placed at the beginning
        options = [...selectedTypes, ...options.filter(item => !selectedTypes.some(selected => selected.value === item.value))];
      }


      
      
      // if (editDisposalSiteValues?.debris_type) {

      //   let selectedTypes = {};
      //   const selectedDebrisTypes = editDisposalSiteValues.debris_type.map(debris => {
      //     const matchingOption = options.filter(option => option.value === debris.id).map(option => selectedTypes.label: option.label, selectedTypes.value: option.value );
      //     console.log("matchingOption", matchingOption);
          
      //     return matchingOption;
      //   });
      //   console.log("selectedDebrisTypes", selectedDebrisTypes);
        
      //   options = [...selectedDebrisTypes, ...options.filter(item => item.value !== selectedDebrisTypes.value)]; 
      // }
      setDebrisTypeOptions(options);
  
      }).catch((error) => {
          
      pushNotification(error?.response?.data?.detail, "error");
      });
    };

     //--------------------------- Fetch Attachments-----------------------//

     const fetchAttachments = async () => {
      // API call to fetch attachments
      const objectId = editDisposalSiteValues?.id;
      if (objectId) {
          const query = `object_id=${objectId}&module_name=disposalsite`;
          main_api.get(adminAPIsEndPoints.LIST_UPLOADED_FILES(query))
          .then((response) => {
            const result = response?.data?.results;
            let attachments = result?.map(item => ({
              key: item?.key,
              url: item?.url,
              name: item?.key.split("/").pop(),
              type: item?.type,
              attachment_type: item?.attachment_type
            }))
            setUploadedFiles(attachments.filter(file => file.attachment_type === attachmentType));
          })
          .catch((error) => {
              pushNotification(error?.response?.data?.detail, "error");
          });
      }   
  }

    useEffect(() => {
      if (editDisposalSiteValues) {
        
          form.setFieldsValue({
              name: editDisposalSiteValues.name || "---",
              wacs_number: editDisposalSiteValues.wacs_number || "---",
              site_type: editDisposalSiteValues.site_type,
              debris_type: editDisposalSiteValues?.debris_type.map(type => type.id),
              date_opened: editDisposalSiteValues.date_opened ? dayjs(editDisposalSiteValues.date_opened) : null,
              date_closed: editDisposalSiteValues.date_closed ? dayjs(editDisposalSiteValues.date_closed) : null,
              permit_expire_date: editDisposalSiteValues.permit_expire_date ? dayjs(editDisposalSiteValues.permit_expire_date) : null,
              is_active: editDisposalSiteValues.is_active,
              address: editDisposalSiteValues.address,
              latitude: editDisposalSiteValues.latitude,
              longitude: editDisposalSiteValues.longitude,
              notes: editDisposalSiteValues.notes,
          });
          setAddressLatAndLong([editDisposalSiteValues.latitude, editDisposalSiteValues.longitude]);
      } 
  }, [editDisposalSiteValues]);


  useEffect(() => {
    if (editDisposalSiteValues && editDisposalSiteValues?.id) {
      fetchAttachments();
    }
  }, [editDisposalSiteValues, attachmentType]);

    useEffect(() => {
      if(addressLatAndLong){

        let latitude = addressLatAndLong[0] || null;
        form.setFieldValue("latitude", latitude);

        let longitude = addressLatAndLong[1] || null;
        form.setFieldValue("longitude", longitude);
      }
    }, [addressLatAndLong]);





    const handleSwitchChange = (checked) => {
      setActive(checked);
      form.setFieldsValue({ is_active: checked }); 
      
    };

    const handleDisposalSiteTypeChange = (value) => {
      setDisposalSiteType(value);
      form.setFieldsValue({site_type: value});
    };

    const handleDebrisTypeChange = (value) => {
      setDebrisType(value);
      form.setFieldsValue({debris_type: value});
    };

    const handleAttachmentsTypeChange = (value) => {
      setAttachmentType(value);
    };
   

  
    

  return (

<CustomModal  
  open={isModalOpen}
  title={title}
  width="630px"
  height="580px"
  onCancel={() => {
    setModalOpen(false);
    form.resetFields();
  }}
  
  footer={null}
  maskClosable={false}
  // isScrollable={true}
>
<Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
  <Form name="updateEventForm" onFinish={onFinish} form={form} layout="vertical" >
    <FormWrapper>
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>

      <FormItem 
        name="name"
        label="Disposal Site Name"
        rules={[
          {
            required: true,
            message: "Please Enter Disposal Site Name",
          },
          {
            min: 3,
            max: 50,
            message: "Disposal Site Name Should be between 3 to 50 Characters",
          }
        ]}
      >
        <Input placeholder="Enter Disposal Site Name" />
      </FormItem>
      <FormItem 
        name="wacs_number"
        label="WACS Number"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please Enter WACS Number",
          },
          {
            min:  6,
            max: 12,
            message: "WACS Number Should be between 6 to 12 Digit Number",
          }
        ]}
      >
        <Input placeholder="Enter WACS Number" />
      </FormItem>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>


    <FormItem 
      name="site_type" 
      label="Disposal Site Type"
      rules={[
        {
          required: true,
          message: "Please Select a Disposal Site Type",
        },
      ]} 
    >
      <Select
          value={disposalSiteType}
          placeholder={"Select"}
          options={disposalSiteTypeOptions}
          onChange={handleDisposalSiteTypeChange}
        />
    </FormItem>
    <FormItem 
      name="debris_type" 
      label="Debris Type"
      style={{marginLeft:"5px"}}
      rules={[
        {
          required: true,
          message: "Please Select a Disposal Site Type",
        },
      ]} 
    >
      <PaginatedSelect fetchData={fetchDebrisTypeOptions} placeholder="Select" options={debrisTypeOptions} value={debrisType} mode={"multiple"} onChange={handleDebrisTypeChange} pageNumber={page} />
    </FormItem>
    </div>
    <FormItem 
        name="date_opened" 
        label="Date Opened"
        rules={[
          {
            required: true,
            type: "object",
            message: "The input is not a valid date",
          },
        ]}
      >
        <DatePicker 
          placeholder="MM/DD/YYYY" 
          format={
            {
              format: 'MM/DD/YYYY',
              setFieldsValue: 'MM/DD/YYYY',
            }
          }
          style={{width:"588px"}}
        />
      </FormItem>
      <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem 
        name="date_closed" 
        label="Date Closed"
        dependencies= {["date_opened"]}
        rules={[
          {
            required: false,
            type: "object",
            message: "The input is not a valid date",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const loadDate = getFieldValue('date_opened');
              if (!value || !loadDate || value.isAfter(loadDate)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Date Closed cannot be before Date Opened'));
            },
          }),
        ]}
      >
        <DatePicker 
          placeholder="MM/DD/YYYY" 
          format={
            {
              format: 'MM/DD/YYYY',
              setFieldsValue: 'MM/DD/YYYY',
            }
          }
          style={{width:"290px"}}
        />
      </FormItem>
      <FormItem 
        name="permit_expire_date" 
        label="Permit Expiration Date"
        dependencies= {["date_opened"]}
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            type: "object",
            message: "The input is not a valid date",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const loadDate = getFieldValue('date_opened');
              if (!value || !loadDate || value.isAfter(loadDate)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Permit Expiration Date cannot be before Date Opened'));
            },
          }),
        ]}
      >
        <DatePicker placeholder="MM/DD/YYYY" 
        format={
          {
            format: 'MM/DD/YYYY',
            setFieldsValue: 'MM/DD/YYYY',
          }
        } 
        style={{width:"290px"}}/>
      </FormItem>
    </div>
    <FormItem name="is_active" label="Status">
      <CustomSwitch initialStatus={active} onChange={handleSwitchChange} />
    </FormItem>
    <div  style={{display:"flex", flexDirection:"column"}}>

      <span style={{marginRight:"8px"}}>

        <LocationSelector
            address={address}
            setAddress={setAddress}
            setAddressLatAndLong={setAddressLatAndLong}
            form={form}
            checked={false}
            label='Address'
            addressLatAndLong={addressLatAndLong}
            name='address'
          />
      </span>
    </div>

    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem name="latitude" label="Latitude">
          <Input value={addressLatAndLong[0] || null} disabled={true} />
      </FormItem>
      <FormItem name="longitude" label="Longitude" style={{marginLeft:"5px"}}>
          <Input value={addressLatAndLong[1] || null} disabled={true} />
      </FormItem>
    </div>
    <FormItem name="notes" label="Notes" style={{width:"590px"}}>
      <TextArea placeholder='Enter any additional notes' />
    </FormItem>
    <Heading text="Attachments" margin="20px 0px 10px 0px" />
    <Select
          value={attachmentType}
          placeholder={"Select a Type"}
          options={attachmentTypeOptions}
          onChange={handleAttachmentsTypeChange}
          style={{marginBottom:"10px", width:"280px"}}
    />
    <FormItem name="attachments" style={{width:"590px"}}>
    {attachmentType &&  <CustomUploader file={file} setFile={setFile} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} directory={"disposalsite"} attachmentType={attachmentType} />}
    </FormItem>

      </FormWrapper>
      <Divider/>
    <SaveContainer className="d-flex justify-content-end">
      <CustomButton
        btnText={"Cancel"}
        margin="0px 5px"
        noBackground
        hideIcon={true}
        onClick={() => setModalOpen(false)}
      />
      <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
    </SaveContainer>
  </Form>
</CustomModal>


  )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const FormWrapper = styled.div`
  height: 585px;
  width:  600px;
  overflow: auto;

 /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 625px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;














