import React, { useEffect, useRef, useState } from 'react';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';

export default function CustomMap({
  addressLatAndLong,
  handleReverseGeocode, 
  draggable = false
}) {
  const mapRef = useRef(null);
  let map = null;
  let view = null;
  let pinGraphic; // To store the pin graphic
  let graphicsLayer;

  const initializeMap = () => {
    if (mapRef.current) {
      map = new Map({
        basemap: 'streets-navigation-vector',
      });

      view = new MapView({
        container: mapRef.current,
        map: map,
        center: [67.0011, 24.8607], // Default center (Karachi)
        zoom: 14,
        popup: {
          defaultPopupTemplateEnabled: true
        }
      });

      graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);

      // Initialize draggable pin functionality (if needed)
      if (draggable) {
        enablePinDragging();
      }
    }
  };

  const enablePinDragging = () => {
    let isPinDragging = false;
    const bufferDistance = 50;

    view.on("drag", function(event) {
      if (isPinDragging) event.stopPropagation();
      
      if (event.action === "start") {
        const screenPoint = { x: event.x, y: event.y };
        view.hitTest(screenPoint).then((response) => {
          if (response.results.length && response.results[0].graphic.symbol.type === pinGraphic) {
            isPinDragging = true;
            view.container.style.cursor = "grabbing";
          }
        });
      } else if (event.action === "end") {
        if (isPinDragging) {
          const location = pinGraphic.geometry;
          view.container.style.cursor = "default";
          handleReverseGeocode(location.latitude, location.longitude);
          isPinDragging = false;
        }
      } else if (isPinDragging) {
        const newLocation = view.toMap({ x: event.x, y: event.y });
        pinGraphic.geometry = newLocation;
        updatePin(newLocation);
      }
    });

    view.on("pointer-move", function() {
      if (!isPinDragging) {
        view.container.style.cursor = "default";
      }
    });
  };

  const addMultiplePins = (locations) => {
    graphicsLayer.removeAll(); // Remove any previous pins
    const genericColor = '#40E0D0';
    // const genericShape = 'hexagon';
    const genericShape = "M10,0L5,8.66L-5,8.66L-10,0L-5,-8.66L5,-8.66Z";  // Hexagon

    locations.forEach((location) => {
      const point = new Point({
        longitude: location.longitude,
        latitude: location.latitude,
      });
      const shapeName = location.shape_name ? location.shape_name : genericShape;
      const debrisColor = location.debris_color ? location.debris_color : genericColor;
      
      addPin(point, shapeName, debrisColor);
    });
  };

  const addPin = (location, shapeName, debrisColor) => {
    // const pinSymbol = {
    //   type: 'picture-marker',
    //   url: 'https://static.arcgis.com/images/Symbols/Shapes/RedPin1LargeB.png',
    //   width: '80px',
    //   height: '80px',
    // };

    // const pinSymbol = {
    //     type: "simple-marker",  // Marker type
    //     style: shapeName,        // Marker style: 'circle', 'square', 'diamond', etc.
    //     color: debrisColor,           // Fill color
    //     size: "12px",           // Size of the marker
    //     outline: {              // Outline of the marker
    //       color: "black",
    //       width: 1
    //     }
    // };

    const pinSymbol = {
      type: "simple-marker",  // Marker type
      path: shapeName,        // Marker style: 'circle', 'square', 'diamond', etc.
      color: debrisColor,           // Fill color
      size: "12px",           // Size of the marker
      outline: {              // Outline of the marker
        color: "black",
        width: 1
      }
  };

    

    const pinGraphic = new Graphic({
        geometry: location,
        symbol: pinSymbol
    });

    graphicsLayer.add(pinGraphic);
  };

  function updatePin(location) {
    if (!pinGraphic) {
        const pinSymbol = {
            type: "simple-marker",  // Marker type
            style: "circle",        // Marker style: 'circle', 'square', 'diamond', etc.
            color: "red",           // Fill color
            size: "12px",           // Size of the marker
            outline: {              // Outline of the marker
              color: "black",
              width: 1
            }
        };
      pinGraphic = new Graphic({
        geometry: location,
        symbol: pinSymbol
      });
      graphicsLayer.add(pinGraphic);
    } else {
      pinGraphic.geometry = location;
    }
  }

  useEffect(() => {
    if (!map) {
      initializeMap();
    }

    if (addressLatAndLong && addressLatAndLong.length > 0) {
      addMultiplePins(addressLatAndLong);
      const firstLocation = addressLatAndLong[0];
      view.center = [firstLocation.longitude, firstLocation.latitude];
      view.zoom = 13;
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const location = new Point({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        });
        addPin(location);
        view.center = location;
        view.zoom = 13;
      }, (error) => {
        console.error("Geolocation error: ", error);
      });
    }
  }, [addressLatAndLong]);

  return (
    <div style={{ height: '70vh' }}>
      <div ref={mapRef} style={{ height: '100%' }}></div>
    </div>
  );
}
