import React, { useEffect, useState } from 'react'
import { Card, Segmented } from 'antd'
import styled from 'styled-components';
import HeadingComponent from '../headingComponent/heading';
import { pushNotification } from '../../util/notification';
import CustomButton from '../customButton/customButton';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { AntdesignTablePagination } from '../antDesignTable/AntdesignTablePagination';
import { hazardNameColumns } from '../../util/antdTableColumns';
import CustomFilter from '../customFilterWithSearchBar/customFilter';
import { status } from '../../util/dropdownData';
import UpdateHazardName from '../modals/administration/hazard/updateHazardName';
import useHazard from '../hooks/useHazard';
import usePermissions from '../hooks/usePermissions';
import { hasPermission } from '../../util/permissionChecker';
import UpdateAssessmentHazard from '../modals/administration/assessmentHazard/updateAssessmentHazard';

export default function AssessmentHazard({
  setPermissionPopUp = () => {},
  setPermissionText = () => {},
}) {

  //----------------------- Custom Hooks -----------------------//
  const { hazardNames, fetchHazardNames, handleAddHazardName, handleEditHazardName } = useHazard();

  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];

  //----------------------- State Management -----------------------//

  const [searchedValue, setSearchedValue] = useState('');
  const [statusSelected, setStatusSelected] = useState(null);

  const [editHazardValues, setEditHazardValues] = useState(null);
  const [updateAssessmentHazardModal, setUpdateAssessmentHazardModal] = useState(false);

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);


  //----------------------- Fetch Data -----------------------//

  const fetchData = async (query = '') => {
    fetchHazardNames(query)
  };
    



  //----------------------- Use Effect -----------------------//

  useEffect(() => {
    fetchData();
  }, []);

    //----------------------- Filter -----------------------//

  // useEffect(() => {
  //   let query = `search=${searchedValue}`;
  //   if (statusSelected!==null) {
  //     query+=`&is_active=${statusSelected}`;
  //   }
    
  //   query = `type=assessmentHazard`;

  //   fetchData(query);
  // }, [statusSelected, searchedValue]);


  //----------------------- Handle Add and Edit Modal -----------------------//

  const handleEditRow = (values) => {
    if (values) {
      setEditHazardValues(values);
      // selectTab === "Hazard Name" ? setUpdateAssessmentHazardModal(true) : setUpdateHazardTypeModal(true);
      if (!hasPermission(assignedPermissionsArray, "manage_hazard_name")) {
        setPermissionText('You do not have permission to edit a assessment hazard name');
        setPermissionPopUp(true);
      }
      else  {
        setUpdateAssessmentHazardModal(true)
      } 
    }
  };

  const handleAddRow = () => { 
    setEditHazardValues(null);
    // selectTab === "Hazard Name" ? setUpdateAssessmentHazardModal(true) : setUpdateHazardTypeModal(true);
    if (!hasPermission(assignedPermissionsArray, "manage_hazard_name")) {
      setPermissionText('You do not have permission to add a assessment hazard name');
      setPermissionPopUp(true);
    }
    else  {
      setUpdateAssessmentHazardModal(true)
    }
  };


  //------------------ Functions to Handle Add and Edit Hazard ---------------------//
  
  const handleEditHazard = async (values) => {
    const id = editHazardValues.id;
    const query = '';
    // selectTab === "Hazard Name" ? handleEditHazardName(id,values,setUpdateAssessmentHazardModal) : handleEditHazardType(id,values,setUpdateHazardTypeModal);

    if (!hasPermission(assignedPermissionsArray, "manage_hazard_name")) {
      setPermissionText('You do not have permission to edit a assessment hazard name');
      setPermissionPopUp(true);
    }
    else if (values && id) {
      handleEditHazardName(id,values,setUpdateAssessmentHazardModal,query,currentPage);
    }
  };

  const handleAddHazard = async (values) => {
    handleAddHazardName(values,setUpdateAssessmentHazardModal);
  };

  return (
    <>
      <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <Heading text="Manage Assessment Hazard" margin="0px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
            <CustomButton btnText={"Add Assessment Hazard"} color={"white"} onClick={handleAddRow} />
          </div>
          {/* <CustomFilter 
            searchBar={true}
            filter2={true}
            resetFilters={true}
            searchBarPlaceholder={"Search by Assessment Hazard Name..."}
            filter2Placeholder={"Status"}
            resetFiltersText='Reset Filter'
            filter2Options={status}
            onSearchBarBlur={(e) => setSearchedValue(e)}
            onFilter2Change={(e) => setStatusSelected(e)}
            onResetFiltersClick={() => {
              setSearchedValue('');
              setStatusSelected(null);
              fetchData();
            }}
            filter2Style={{marginLeft:"20px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}}
            resetFiltersStyle={{cursor:"pointer",color:"#EE3E41",marginLeft:"15px", marginBottom: "20px", position:"relative", top:"20px", left:"6px", width:"260px", height:"40px"}}
          /> */}
          {/* <AntdesignTablePagination 
            columns={hazardNameColumns({handleEditRow})} 
            data={hazardNames?.data?.results}
            totalCount={hazardNames?.data?.count}
            loadPaginatedData={fetchHazardNames} 
            allowRowSelection={false}
            tableHeight={450}
            tableWidth={1200} 
          /> */}
      </CustomCard>

      {updateAssessmentHazardModal && (
        <UpdateAssessmentHazard
          isModalOpen={updateAssessmentHazardModal}
          title={editHazardValues ? 'Edit Assessment Hazard' : 'Add Assessment Hazard'}
          onFinish={editHazardValues ? handleEditHazard : handleAddHazard}
          setModalOpen={setUpdateAssessmentHazardModal}
          editHazardValues={editHazardValues}  
        />
      )}
    </>
  )
}



const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};


const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1270px;
  height: calc(100vh - 40px);
  max-height: 720px;
  margin: 20px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;