import React, { useEffect, useState } from 'react'
import {ReactComponent as BackIcon} from '../../assets/rawSvg/lessThanIcon.svg';
import {ReactComponent as EditWhiteIcon} from '../../assets/rawSvg/edit-2.svg';
import CustomButton from '../customButton/customButton';
import HeadingComponent from '../headingComponent/heading';
import styled from 'styled-components';
import { Button, Card, Tag } from 'antd';
import { main_api } from '../../api/axiosHelper';
import { pushNotification } from '../../util/notification';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
export default function ProjectDetails({
    projectDetailsModal,
    setProjectDetailsModal,
    selectedProject,
    handleEditRow = () => {},
}) {
    
    const [attachments, setAttachments] = useState([]);
    
    const fetchAttachments = async () => {
        // API call to fetch attachments
        const objectId = selectedProject?.id;
        if (objectId) {
            
            const query = `object_id=${objectId}&module_name=project`;
            main_api.get(adminAPIsEndPoints.LIST_UPLOADED_FILES(query))
            .then((response) => {
                setAttachments(response?.data?.results);
            })
            .catch((error) => {
                pushNotification(error?.response?.data?.detail, "error");
            });
        } else {
            setAttachments([]);
        }   
    }

    useEffect(() => {
        if (projectDetailsModal) {
            fetchAttachments();
        }
    }, [projectDetailsModal]);

  return (
    <div style={{ marginTop: '10px' }}>
        <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <BackIcon onClick={() => setProjectDetailsModal(false)} style={{width: "20px",height:"15px", cursor:"pointer"}} />
            <Heading text="Project Details" margin="0px 0px 0px 10px" fontSize="1.3rem" color="#3B3B3B" />
        </span>

    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{display:"flex", flexDirection:"row"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"10px", marginLeft:"40px"}}>
                <span style={{marginBlock: "30px"}}>
                    <Heading text="Project Name" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <Heading text={selectedProject?.name || "---"} margin="0px 0px 0px 5px" fontSize="1rem" color="#4D5464" />
                </span>
                <span style={{marginBlock: "30px"}}>
                    <Heading text="Event Name" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <Heading text={selectedProject?.event_name || "---"} margin="0px 0px 0px 5px" fontSize="1rem" color="#4D5464" />
                </span>
                <span style={{marginBlock: "30px"}}>
                    <Heading text="Client Name" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <Heading text={selectedProject?.client_name || "---"} margin="0px 0px 0px 5px" fontSize="1rem" color="#4D5464" />
                </span>
                <span style={{ marginBlock: "30px" }}>
                <Heading text="Sub-Activity" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                <ul style={{ paddingLeft: '20px', margin: '5px 0',}}>
                    {
                        selectedProject?.sub_activity?.length > 0 ? (
                            selectedProject.sub_activity.map((activity, index) => (
                                <li key={index} style={{ marginBottom: '5px', listStyleType: 'disc', color: '#3669AE' }}>
                                    <Heading
                                        text={activity?.name || '---'}
                                        margin="0px 0px 0px 0px"
                                        fontSize="1rem"
                                        color="#4D5464"
                                    />
                                </li>
                            ))
                        ) : (
                            <li style={{ marginBottom: '5px', listStyleType: 'disc', color: '#4D5464' }}>
                                <Heading
                                    text="No Sub-Activities"
                                    margin="0px 0px 0px 0px"
                                    fontSize="1rem"
                                    color="#4D5464"
                                />
                            </li>
                        )
                    }
                </ul>
            </span>
            </div>
            <div style={{display:"flex", flexDirection:"column", marginTop:"10px", marginLeft:"40px" }}>
                <span style={{marginBlock: "30px"}}>
                    <Heading text="City" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <Heading text={selectedProject?.city || "---"} margin="0px 0px 0px 5px" fontSize="1rem" color="#4D5464" />
                </span>
                <span style={{marginBlock: "30px"}}>
                    <Heading text="State" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <Heading text={selectedProject?.state || "---"} margin="0px 0px 0px 5px" fontSize="1rem" color="#4D5464" />
                </span>
                <span style={{marginBlock: "30px"}}>
                    <Heading text="Status" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    {
                        selectedProject?.is_active === true ? (
                            <Tag bordered={false} color="green" style={{ borderRadius: "12px" }}>
                              Active
                            </Tag>
                          ) : (
                            <Tag bordered={false} color="red" style={{ borderRadius: "12px" }}>
                              InActive
                            </Tag>
                          )
                    }
                </span>
               
                <span style={{margin: "28px 10px 10px 2px"}}>
                    <Heading text="Disposal Site" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <ul style={{ paddingLeft: '20px', margin: '5px 0',}}>
                    {
                        selectedProject?.disposal_site?.length > 0 ? (
                            selectedProject.disposal_site.map((site, index) => (
                                <li key={index} style={{ marginBottom: '5px', listStyleType: 'disc', color: '#3669AE' }}>
                                    <Heading
                                        text={site?.name || '---'}
                                        margin="0px 0px 0px 0px"
                                        fontSize="1rem"
                                        color="#4D5464"
                                    />
                                </li>
                            ))
                        ) : (
                            <li style={{ marginBottom: '5px', listStyleType: 'disc', color: '#4D5464' }}>
                                <Heading
                                    text="No Disposal Sites"
                                    margin="0px 0px 0px 0px"
                                    fontSize="1rem"
                                    color="#4D5464"
                                />
                            </li>
                        )
                    }
                </ul>
                </span>
            </div>
            <div style={{display:"flex", flexDirection:"column", marginTop:"10px", marginLeft:"40px"}}>
                <span style={{marginBlock: "30px"}}>
                    <Heading text="Prime Contractor" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <Heading text={selectedProject?.prime_contractor || "---"} margin="0px 0px 0px 5px" fontSize="1rem" color="#4D5464" />
                </span>
                    
                <span style={{marginBlock: "30px"}}>
                    <Heading text="PO Number" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <Heading text={selectedProject?.po_number || "---"} margin="0px 0px 0px 5px" fontSize="1rem" color="#4D5464" />
                </span>

                <span style={{marginBlock: "30px"}}>
                    <Heading text="Project Identification Number" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    <Heading text={selectedProject?.project_identfication_number || "---"} margin="0px 0px 0px 5px" fontSize="1rem" color="#4D5464" />
                </span>   
                {/* <span style={{ marginBlock: "30px" }}>
                    <Heading text="Attachments" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
                    {
                        attachments?.length > 0 ? (
                            <ul style={{ 
                                display: 'flex', 
                                flexWrap: 'wrap',   // Allows wrapping to the next row
                                gap: '10px',        // Space between items
                                paddingLeft: '0', 
                                margin: '5px 0',
                                listStyleType: 'none' // Remove bullet points
                            }}>
                                {
                                    attachments.map((attachment, index) => {
                                        // Check if the attachment is an image by looking at the file type
                                        const imageTypes = ["png", "jpg", "jpeg", "gif"];
                                        const isImage = imageTypes.includes(attachment?.type);
                                        const fileName = attachment?.key.split("/").pop();
                                        
                                        return (
                                            <li 
                                                key={index} 
                                                style={{ 
                                                    flex: '0 1 120px',   // Adjust flex-basis to control the width of each item (120px)
                                                    display: 'flex',     // Flex container for better alignment of image/text
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '80px'      // Ensure a consistent height for each item
                                                }}
                                            >
                                                {
                                                    isImage ? (
                                                        <img 
                                                            src={attachment?.url} 
                                                            alt={fileName || 'attachment'} 
                                                            style={{
                                                                width: "60px",
                                                                height: "60px",
                                                                borderRadius: "8px", 
                                                                objectFit: "cover"
                                                            }}
                                                        />
                                                    ) : (
                                                        <Heading
                                                            text={fileName || '---'}
                                                            margin="0px 0px 0px 0px"
                                                            fontSize="1rem"
                                                            color="#4D5464"
                                                        />
                                                    )
                                                }
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        ) : (
                            <Heading
                                text="No Attachments"
                                margin="0px 0px 0px 5px"
                                fontSize="1rem"
                                color="#4D5464"
                            />
                        )
                    }
                </span> */}

<span style={{ marginBlock: "30px" }}>
    <Heading text="Attachments" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#858D9D" />
    {
        attachments?.length > 0 ? (
            <ul style={{ 
                display: 'flex', 
                flexDirection: 'row', // Ensure items are laid out horizontally
                gap: '10px',          // Space between items
                paddingLeft: '0', 
                margin: '5px 0',
                listStyleType: 'none' // Remove bullet points
            }}>
                {
                    attachments.map((attachment, index) => {
                        // Check if the attachment is an image by looking at the file type
                        const imageTypes = ["png", "jpg", "jpeg", "gif"];
                        const isImage = imageTypes.includes(attachment?.type);
                        const fileName = attachment?.key.split("/").pop();

                        return (
                            <li 
                                key={index} 
                                style={{ 
                                    flex: '0 0 auto',   // Ensure each item stays at its fixed width
                                    display: 'flex',     
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '80px',      // Consistent height
                                    width: '120px'       // Consistent width for horizontal layout
                                }}
                            >
                                {
                                    isImage ? (
                                        <img 
                                            src={attachment?.url} 
                                            alt={fileName || 'attachment'} 
                                            style={{
                                                width: "60px",
                                                height: "60px",
                                                borderRadius: "8px", 
                                                objectFit: "cover"
                                            }}
                                        />
                                    ) : (
                                        <Heading
                                            text={fileName || '---'}
                                            margin="0px 0px 0px 0px"
                                            fontSize="1rem"
                                            color="#4D5464"
                                        />
                                    )
                                }
                            </li>
                        );
                    })
                }
            </ul>
        ) : (
            <Heading
                text="No Attachments"
                margin="0px 0px 0px 5px"
                fontSize="1rem"
                color="#4D5464"
            />
        )
    }
</span>

                
            </div>
           
        </div>
        

        
        <ButtonContainer className="d-flex justify-content-end">
            <Button style={{marginRight: "10px", color:"white", fontSize:"14px", fontWeight:"500", borderRadius:"8px",background:"#3669AE", width:"80px", height:"40px"}} icon={<EditWhiteIcon/>} onClick={()=> handleEditRow(selectedProject)}>Edit</Button>
        </ButtonContainer>
        
        
    </CustomCard>
  </div>
  )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
  

const CustomCard = styled(Card)`
    width: calc(100vw - 40px);
    max-width: 1565px;
    height: calc(100vh - 40px);
    max-height: 500px;
    overflow-y: auto;
    margin-top: 40px;
    margin-left: 40px;
    padding: 20px;
    background-color: #f2f3f5;
    
    @media (max-width: 768px) {
      width: calc(100vw - 20px);
      height: calc(100vh - 20px);
      margin: 10px;
    }

    /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  `;


  const ButtonContainer = styled.div`
  position: sticky;
  bottom: 4px;
  right: 4px;
  padding: 10px 20px;
`;
