

export const accessPermission = [
    { label: "Web", value: "web" },
    { label: "Mobile", value: "mobile" },
    { label: "Both", value: "both" },
  ];

export const status = [
  { label: 'Active', value: true },
  { label: 'In Active', value: false },
];

export const truckWorkStatus = [
  { label: 'Available', value: true },
  { label: 'UnAvailable', value: false },
];

export const truckTypeOptions = [
  { label: 'Simple', value: 'simple' },
  { label: 'U-Shaped', value: 'u-shaped' },
];

export const debrisColorOptions = [
  { label: 'Green', value: '#008000' },
  { label: 'Purple', value: '#800080' },
  { label: 'Red', value: '#FF0000' },
  { label: 'Orange', value: '#FFA500' },
  { label: 'Yellow', value: '#FFFF00' },
  { label: 'Blue', value: '#0000FF' },
  { label: 'Cyan', value: '#00FFFF' },
  { label: 'Pink', value: '#FFC0CB' },
  { label: 'Brown', value: '#A52A2A' },
  { label: 'Gray', value: '#808080' },
  { label: 'Magenta', value: '#FF00FF' },
  { label: 'Teal', value: '#008080' },
  { label: 'Black', value: '#000000' }
];

// export const subActivityShapeOptions = [
//   { label: 'Dot', value: 'circle' },
//   { label: 'Square', value: 'square' },
//   { label: 'Triangle', value: 'triangle' },
//   { label: 'Star', value: 'star' }
// ];

export const subActivityShapeOptions = [
  { label: 'Dot', value: "M0,0 A10,10 0 1,0 0,-0.1Z" },  // Circle (Dot)
  { label: 'Square', value: "M-5,-5L5,-5L5,5L-5,5Z" },  // Square
  { label: 'Triangle', value: "M0,-10L5,5L-5,5Z" },  // Triangle
  { label: 'Star', value: "M0,-10L2,-2H10L3,2L6,10L0,5L-6,10L-3,2L-10,-2H-2Z" }  // Star
];


export const calculationTypeOptions = [
  { label: 'Addition', value: 'addition' },
  { label: 'Subtraction', value: 'subtraction' },
];

export const unitOptions = [
  { label: 'CYDS', value: 'cyds' },
  { label: 'TONS', value: 'tons' },
  { label: 'EACH', value: 'each' },
]

export const unitRequirementOptions = [
  { label: 'Required', value: 'required' },
  { label: 'Optional', value: 'optional' },
  { label: 'Not Required', value: 'not_required' },
];


export const contractorTypeOptions = [
  { label: 'Prime Contractor', value: "prime_contractor" },
  { label: 'Sub Contractor', value: "sub_contractor" },
];

export const ticketStatusOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Closed', value: 'closed' },
];
export const disposalSiteTypeOptions = [
  { label: 'Final', value: "final" },
  { label: 'Temporary', value: "temporary" },
];

export const attachmentTypeOptions = [
  { label: 'Permit', value: "permit" },
  { label: 'Contract/Lease', value: "contract_lease" },
  { label: 'Permitted Burn', value: "permitted_burn" },
  { label: 'Others', value: "others" },
];



export const countryCodes = [
  { label: "US", value: "+1" },
  { label: "MX", value: "+52" },
  { label: "BR", value: "+55" },
  { label: "AR", value: "+54" },
  { label: "GB", value: "+44" },
  { label: "FR", value: "+33" },
  { label: "DE", value: "+49" },
  { label: "IT", value: "+39" },
  { label: "ES", value: "+34" },
  { label: "RU", value: "+7" },
  { label: "CN", value: "+86" },
  { label: "IN", value: "+91" },
  { label: "JP", value: "+81" },
  { label: "KR", value: "+82" },
  { label: "AU", value: "+61" },
  { label: "NZ", value: "+64" },
  { label: "ZA", value: "+27" },
  { label: "NG", value: "+234" },
  { label: "EG", value: "+20" },
  { label: "SA", value: "+966" },
  { label: "TR", value: "+90" },
];
