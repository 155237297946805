import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ManageProjects from '../../components/projectManagementComponents/manageProjects';
import MapUser from '../../components/projectManagementComponents/mapUser';
import ProjectDetails from '../../components/projectManagementComponents/projectDetails';
import useProjects from '../../components/hooks/useProjects';
import UpdateProjects from '../../components/modals/manageProjects/updateProjects';
import useUsers from '../../components/hooks/useUsers';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { pushNotification } from '../../util/notification';
import { hasPermission } from '../../util/permissionChecker';
import usePermissions from '../../components/hooks/usePermissions';
import HeadingComponent from '../../components/headingComponent/heading';
import styled from 'styled-components';
import { Card } from 'antd';
import DeletePermissionModal from '../../components/modals/permission/deletePermissionModal';
import PermissionModal from '../../components/modals/permission/permissionModal';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import CustomFilter from '../../components/customFilterWithSearchBar/customFilter';
import CustomButton from '../../components/customButton/customButton';
import { contractorTypeOptions, status } from '../../util/dropdownData';
import { contractorManagementColumns } from '../../util/antdTableColumns';
import UpdateContractor from '../modals/contractorManagement/updateContractor';
import { useSelector } from 'react-redux';


export default function ManageContractors() {


  //----------------------- Custom Hooks -----------------------//

  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
  

//---------------------- State for Data ----------------------//

const [selectedPosition, setSelectedPosition] = useState(null);

const [contractorsDataForTable, setContractorsDataForTable] = useState({});
const [contractorsOptions, setContractorsOptions] = useState([]);


const [searchedValue, setSearchedValue] = useState('');
const [contractorTypeSelected, setContractorTypeSelected] = useState(null);
const [statusSelected, setStatusSelected] = useState(null);
const [currentPage, setCurrentPage] = useState(1);

const [editContractorValues, setEditContractorValues] = useState(null);

//---------------------- State for Modals ----------------------//

const [updateContractorModal, setUpdateContractorModal] = useState(false);
const [deleteContractorModal, setDeleteContractorModal] = useState(false);

const [permissionText, setPermissionText] = useState('');
const [permissionPopUp, setPermissionPopUp] = useState(false);
  
const rolesState = useSelector((state) => state.roles.roles);

const contractorId = rolesState.filter((role) => role.type === "contractor").map((role) => role.id)[0];


  //----------------------- Fetch Data  -------------------------//

  const fetchData = async (
    query = '', 
    page = 1, 
 ) => {
    main_api.get(`${adminAPIsEndPoints.LIST_CONTRACTOR(query)}&page=${page}`)
    .then((response) => {
    const result = response.data.results;
    
    const transformedContractorsData = result.filter(item => item?.position?.role?.type === "contractor").map(item => ({
        key: item?.id, 
        company_name: item?.additional_data?.company_name?.charAt(0).toUpperCase() + item?.additional_data?.company_name?.slice(1) || '---',
        type: item?.position?.name || '---', 
        primeContractor: item?.prime_contractor?.name.charAt(0).toUpperCase() +  item?.prime_contractor?.name.slice(1) || '---',
        is_active: item?.is_active,
        ...item, 
      }));
    

    setContractorsDataForTable({count: response.data.count, data:  transformedContractorsData });

    // const options = result?.filter(item => item?.position?.role?.type === "contractor").map((item) => {
    //     item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
    //     item.value = item.id;
    //     return item;
    // });
    // setContractorsOptions(options);

    }).catch((error) => {
        
    pushNotification(error?.response?.data?.detail, "error");
    });
};




  //------------------ Functions for Update & Delete Contractor Modal ---------------------//
  
  const handleAddRow = () => {
    if (!hasPermission(assignedPermissionsArray, 'add_contractor_user')) {
      setPermissionText("You do not have permission to add a contractor");
      setPermissionPopUp(true)
    } else {
      setEditContractorValues(null);
      setUpdateContractorModal(true);
    }
  };


  const handleEditRow = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_contractor_user')) {
      setPermissionText("You do not have permission to edit a contractor");
      setPermissionPopUp(true)
    } else if (values) {
          setEditContractorValues(values);
          setUpdateContractorModal(true);
 
    }
  };

  const handleDeleteRow = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_contractor_user')) {
      setPermissionText("You do not have permission to delete a contractor");
      setPermissionPopUp(true)
    }else if (values) {
        setEditContractorValues(values);
        setDeleteContractorModal(true);
    }
  };



 //----------------------------- Add and Edit Contractor Functions -----------------------------//

 const handleEditContractor = async (values) => {
    




  let payload = {
    name: values?.name || '',
    email: values?.email || '',
    phone_number: values?.phone_number || '',
    is_active: values?.is_active,
    prefix: values?.prefix || '',
  }
  
  payload = {
    ...payload,
    additional_data: {
      company_name: values?.company_name || '',
      notes: values?.notes || '',
    }
  };

  
  let emptyEmail = false;
  values?.email_ticket_receipt.map((email) => {
    if (email !== "") {
     return emptyEmail = true;
    }
    return emptyEmail = false;
  });

  if (emptyEmail) {
    payload = {
      ...payload,
      additional_data: {
        ...payload.additional_data,
        email_ticket_receipt: values?.email_ticket_receipt,
      }
    }
  }

  



  if (selectedPosition.label === "Sub Contractor") {
    payload.prime_contractor = values?.prime_contractor || '';
  }
  const id = editContractorValues.key;

  
  
  main_api.put(adminAPIsEndPoints.UPDATE_CONTRACTOR(id), payload)
  .then((response) => {
    if (response.status === 200) {
        pushNotification("Contractor updated successfully", "success");
        let query = `search=${searchedValue}`;
        if(contractorTypeSelected){
          query += `&contractor=${contractorTypeSelected}`;
        }
        if (statusSelected!==null) {
          query+=`&is_active=${statusSelected}`;
        }
        fetchData(query, currentPage);
        setUpdateContractorModal(false);
    }
  }).catch((error) => {
    pushNotification(error?.response?.data?.detail, "error");
  });
 
}

const handleAddContractor = async (values) => {
  
  let payload = {
    name: values?.name || '',
    email: values?.email || '',
    phone_number: values?.phone_number || '',
    is_active: values?.is_active,
    password: values?.password || '',
    confirm_password: values?.confirm_password || '',
    position: selectedPosition?.value || '',
    prefix: values?.prefix || '',
    
  }

  payload = {
    ...payload,
    additional_data: {
      company_name: values?.company_name || '',
      notes: values?.notes || '',
    }
  };

  let emptyEmail = false;
  values?.email_ticket_receipt.map((email) => {
    if (email !== "") {
     return emptyEmail = true;
    }
    return emptyEmail = false;
  });

  if (emptyEmail) {
    payload = {
      ...payload,
      additional_data: {
        ...payload.additional_data,
        email_ticket_receipt: values?.email_ticket_receipt,
      }
    }
  }

  
  if (selectedPosition.label === "Sub Contractor") {
    payload.prime_contractor = values?.prime_contractor || '';
  }

  main_api.post(adminAPIsEndPoints.CREATE_CONTRACTOR, payload)
  .then((response) => {
    if (response.status === 201) {
        pushNotification("Contractor added successfully", "success");
        setSearchedValue('');
        setStatusSelected(null);
        setContractorTypeSelected(null);
        fetchData();
        setUpdateContractorModal(false);
    }
  }).catch((error) => {
    pushNotification(error?.response?.data?.detail, "error");
  });

}

const handleDeleteContractor = async () => {
  const id = editContractorValues.key;
  main_api.delete(adminAPIsEndPoints.UPDATE_CONTRACTOR(id))
  .then((response) => {
    if (response.status === 204) {
        pushNotification("Contractor deleted successfully", "success");
        const query = '';
        fetchData(query, currentPage);
        setDeleteContractorModal(false);
    }
  }).catch((error) => {
    pushNotification(error?.response?.data?.detail, "error");
  });
}



//---------------------- Effect for Fetching Data ----------------------//

useEffect(() => {
  fetchData();
}, []);


//------------------------- Filter --------------------------//
  
   useEffect(() => {
    let query = `search=${searchedValue}`;
    if(contractorTypeSelected){
      query += `&contractor=${contractorTypeSelected}`;
    }
    if (statusSelected!==null) {
      query+=`&is_active=${statusSelected}`;
    }
    fetchData(query);
  }, [contractorTypeSelected, statusSelected, searchedValue]);



  return (
    <div style={{ marginTop: '10px' }}>
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Heading text="Manage Contractors" margin="0px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
          <CustomButton btnText={"Add Contractor"} color={"white"} onClick={handleAddRow} />
        </div>
        <CustomFilter 
          searchBar={true}
          filter1={true}
          filter2={true}
          resetFilters={true}
          searchBarPlaceholder="Search By Company Name..."
          filter1Placeholder="Contractor Type"
          filter2Placeholder="Status"
          resetFiltersText="Reset Filter"
          filter1Options={contractorTypeOptions}
          filter2Options={status}
          onSearchBarBlur={(e) => setSearchedValue(e)}
          onFilter1Change={(e) => setContractorTypeSelected(e)}
          onFilter2Change={(e) => setStatusSelected(e)}
          onResetFiltersClick={() => {
            setContractorTypeSelected(null);
            setStatusSelected(null);
            setSearchedValue('');
            fetchData();
          }}
          filter1Style={{marginLeft:"20px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}}
          filter2Style={{marginLeft:"8px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}}
          resetFiltersStyle={{cursor:"pointer",color:"#EE3E41",marginLeft:"15px", marginBottom: "20px", position:"relative", top:"20px", left:"6px", width:"260px", height:"40px"}}
        />
        <AntdesignTablePagination 
          columns={contractorManagementColumns({handleEditRow})} 
          data={contractorsDataForTable.data}
          totalCount={contractorsDataForTable.count}
          loadPaginatedData={fetchData} 
          allowRowSelection={false}
          tableHeight={450}
          tableWidth={1200} 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
    </CustomCard>
    {updateContractorModal && 
      <UpdateContractor 
        isModalOpen={updateContractorModal} 
        title={editContractorValues ? "Edit Contractor" : "Add New Contractor"} 
        onFinish={editContractorValues ? handleEditContractor : handleAddContractor  } 
        setModalOpen={setUpdateContractorModal} 
        editContractorValues={editContractorValues}
        selectedPosition={selectedPosition}
        setSelectedPosition={setSelectedPosition}
      />
    }
    {deleteContractorModal &&
      <DeletePermissionModal
        isModalOpen={deleteContractorModal} 
        title='Delete Contractor'
        description='Are you sure you want to delete this contractor? This action cannot be undone.'
        deleteText='Delete'
        onDelete={handleDeleteContractor} 
        setModalOpen={setDeleteContractorModal} 
      />
    }
    {permissionPopUp && (
      <PermissionModal 
        text={permissionText}
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />
    )}
  </div>
  )
}



const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1570px;
  height: calc(100vh - 40px);
  max-height: 750px;
  margin-top: 40px;
  margin-left: 40px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;