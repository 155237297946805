import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import moment from "moment";
import { Button, DatePicker, Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import { CustomModal } from '../../customModal/customModal';
import CustomButton from '../../customButton/customButton';
import { countryCodes } from '../../../util/dropdownData';
import CustomSwitch from '../../customSwitch/customSwitch';
import HeadingComponent from '../../headingComponent/heading';
import LocationSelector from '../../customAddressInput/locationSelector';
import { useSelector } from 'react-redux';
import { getUserPositionList } from '../../../util/dataService';
import { main_api } from '../../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../../constants/apiEndPoints';
import PaginatedSelect from '../../customSelect/paginatedSelect';
import { pushNotification } from '../../../util/notification';
import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as PlusIcon } from "../../../assets/rawSvg/Plus.svg";
import { ReactComponent as CrossIcon } from "../../../assets/rawSvg/Cross.svg";
import useEvents from '../../hooks/useEvents';
import CustomUploader from '../../customUploader/customUploader';

export default function UpdateProjects({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    editProjectValues,
    file,
    setFile,
    uploadedFiles,
    setUploadedFiles,
    padding = "20px",
}) {


    
    //------------------ State Management ---------------------//
    const [event, setEvent] = useState(editProjectValues?.event?.id ||null);
    const [client, setClient] = useState(editProjectValues?.client?.id ||null);
    const [contractor, setContractor] = useState(editProjectValues?.contractor?.id ||null);
    const [subActivity, setSubActivity] = useState(editProjectValues?.sub_activity?.map(activity => activity.id) ||null);
    const [disposalSite, setDisposalSite] = useState(editProjectValues?.disposal_site?.map(site => site.id) ||null);

    // const [file, setFile] = useState(null);
    // const [uploadedFiles, setUploadedFiles] = useState([]);
    const [savedFileUrl, setSavedFileUrl] = useState(null);

    const rolesState = useSelector((state) => state.roles.roles);
    
    // Dropdown Options
    const [eventOptions, setEventOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [subActivityOptions, setSubActivityOptions] = useState([]);
    const [disposalSiteOptions, setDisposalSiteOptions] = useState([]);
    const [primeContractorOptions, setPrimeContractorOptions] = useState([]);
    
    const [subActivityOptionsPage, setSubActivityOptionsPage] = useState(1);
    const [disposalSiteOptionsPage, setDisposalSiteOptionsPage] = useState(1);
    const [eventOptionsPage, setEventOptionsPage] = useState(1);
    const [clientOptionsPage, setClientOptionsPage] = useState(1);
    const [primeContractorOptionsPage, setPrimeContractorOptionsPage] = useState(1);

    const [active, setActive] = useState();
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const [form] = Form.useForm(); 
   

    useEffect(() => {
        if (editProjectValues) {
          
          form.setFieldsValue({
            name: editProjectValues?.name,
            event: editProjectValues?.event.id,
            client: editProjectValues?.client.id,
            contractor: editProjectValues?.contractor.id,
            sub_activity: editProjectValues?.sub_activity?.map(activity => activity.id),
            disposal_site: editProjectValues?.disposal_site?.map(site => site.id),
            total_estimated_cy: editProjectValues?.total_estimated_cy,
            total_roadway_miles: editProjectValues?.total_roadway_miles,
            po_number: editProjectValues?.po_number,
            project_identfication_number: editProjectValues?.project_identfication_number,
            city: editProjectValues?.city,
            state: editProjectValues?.state,
            is_active: editProjectValues.is_active,
          });
          setActive(editProjectValues.is_active);
        } 
    }, [editProjectValues]);


    //--------------------------- Fetch Attachments-----------------------//

    const fetchAttachments = async () => {
      // API call to fetch attachments
      const objectId = editProjectValues?.id;
      if (objectId) {
          const query = `object_id=${objectId}&module_name=project`;
          main_api.get(adminAPIsEndPoints.LIST_UPLOADED_FILES(query))
          .then((response) => {
            const result = response?.data?.results;
            let attachments = result?.map(item => ({
              key: item?.key,
              url: item?.url,
              name: item?.key.split("/").pop(),
              type: item?.type,
              attachment_type: item?.attachment_type
            }))
            setUploadedFiles(attachments);
          })
          .catch((error) => {
              pushNotification(error?.response?.data?.detail, "error");
          });
      } 
  }
    
    //--------------------------- Fetch Events ---------------------------//
    
    const fetchEvents = async (page = 1) => {
      const query = `is_active=true&page${page}`;
      main_api.get(adminAPIsEndPoints.LIST_EVENT(query))
      .then((response) => {
        const result = response.data.results;
        if (response.data.next) {
          const urlObj = new URL(response.data.next);
          const params = new URLSearchParams(urlObj.search);
          const pageNumber = params.get('page');
          setEventOptionsPage(pageNumber);
        } else {
          setEventOptionsPage(null);
        }
        let options = [...eventOptions, ...result?.map(event => ({
          ...event,
          label: event.name.charAt(0).toUpperCase() + event.name.slice(1),
          value: event.id,
        }))];
        
        if (editProjectValues?.event) {
          const selectedEvent = {
            ...editProjectValues.event,
            label: editProjectValues.event.name.charAt(0).toUpperCase() + editProjectValues.event.name.slice(1),
            value: editProjectValues.event.id,
          };
          options = [selectedEvent, ...options.filter(item => item.id !== selectedEvent.value)];
        }

        const uniqueOptions = options.reduce((acc, current) => {
          const existing = acc.find(item => item.value === current.value);
          if (existing) {
            // Compare the number of keys and keep the one with more information
            if (Object.keys(current).length > Object.keys(existing).length) {
              return acc.map(item => item.value === current.value ? current : item);
            }
            return acc;
          } else {
            acc.push(current);
            return acc;
          }
        }, []);
        
        setEventOptions(uniqueOptions);
      }).catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
    };
    
    //--------------------------- Fetch Prime Contractors ---------------------------//


    const fetchPrimeContractors = async (page = 1) => {
      const query = `is_active=true&page${page}`;
      main_api.get(adminAPIsEndPoints.LIST_CONTRACTORS(query))
      .then((response) => {
        const result = response?.data?.results;
        
        if (response.data.next) {
          const urlObj = new URL(response.data.next);
          const params = new URLSearchParams(urlObj.search);
          const pageNumber = params.get('page');
          setPrimeContractorOptionsPage(pageNumber);
        } else {
          setPrimeContractorOptionsPage(null);
        }

        let options = [...primeContractorOptions, ...result?.map(primeContractor => ({
          ...primeContractor,
          label: primeContractor.name.charAt(0).toUpperCase() + primeContractor.name.slice(1),
          value: primeContractor.id,
        }))];

        if (editProjectValues?.contractor) {
          const selectedContractor = {
            ...editProjectValues.contractor,
            label: editProjectValues.contractor.name.charAt(0).toUpperCase() + editProjectValues.contractor.name.slice(1),
            value: editProjectValues.contractor.id,
          };
          options = [selectedContractor, ...options.filter(item => item.id !== selectedContractor.value)];
        }

        const uniqueOptions = options.reduce((acc, current) => {
          const existing = acc.find(item => item.value === current.value);
          if (existing) {
            // Compare the number of keys and keep the one with more information
            if (Object.keys(current).length > Object.keys(existing).length) {
              return acc.map(item => item.value === current.value ? current : item);
            }
            return acc;
          } else {
            acc.push(current);
            return acc;
          }
        }, []);

        setPrimeContractorOptions(uniqueOptions);
      }).catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
    };

    //--------------------------- Fetch Clients ---------------------------//


    const fetchClients = async (page = 1) => {
      const query = `is_active=true&page=${page}`;
      main_api.get(adminAPIsEndPoints.LIST_CLIENTS(query))
      .then((response) => {
          const result = response.data.results;
         
          if (response.data.next) {
            const urlObj = new URL(response.data.next);
            const params = new URLSearchParams(urlObj.search);
            const pageNumber = params.get('page');
            setClientOptionsPage(pageNumber);
          } else {
            setClientOptionsPage(null);
          }

          let options = [...clientOptions, ...result?.map(client => ({
            ...client,
            label: client.name.charAt(0).toUpperCase() + client.name.slice(1),
            value: client.id,
          }))];

          if (editProjectValues?.client) {
            const selectedClient = {
              ...editProjectValues.client,
              label: editProjectValues.client.name.charAt(0).toUpperCase() + editProjectValues.client.name.slice(1),
              value: editProjectValues.client.id,
            };
            options = [selectedClient, ...options.filter(item => item.id !== selectedClient.value)];
          }

          const uniqueOptions = options.reduce((acc, current) => {
            const existing = acc.find(item => item.value === current.value);
            if (existing) {
              // Compare the number of keys and keep the one with more information
              if (Object.keys(current).length > Object.keys(existing).length) {
                return acc.map(item => item.value === current.value ? current : item);
              }
              return acc;
            } else {
              acc.push(current);
              return acc;
            }
          }, []);

          setClientOptions(uniqueOptions);
      })
      .catch((error) => {
          pushNotification(error?.response?.data?.detail, "error");
      });
  };

    //--------------------------- Fetch Sub-Activities ---------------------------//


    const fetchSubActivities = async (query = '',page = 1) => {
      main_api.get(`${adminAPIsEndPoints.LIST_SUB_ACTIVITY(query)}&is_active=true&page=${page}`)
      .then((response) => {
        const result = response.data.results;
        if (response.data.next) {
          const urlObj = new URL(response.data.next);
          const params = new URLSearchParams(urlObj.search);
          const pageNumber = params.get('page');
          setSubActivityOptionsPage(pageNumber);
        } else {
          setSubActivityOptionsPage(null);
        }
        let newOptions = result?.map((item) => {
          item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
          item.value = item.id;
          return item;
        });

        let options = subActivityOptions !== null && subActivityOptions !== undefined ? [...subActivityOptions] : [];

        newOptions.forEach(newOption => {
          if (!options.find(option => option.value === newOption.value)) {
            options.push(newOption);
          }
        });

        if (editProjectValues?.sub_activity) {
          const selectedSubActivities = editProjectValues.sub_activity.map(activity => ({
            label: activity.name.charAt(0).toUpperCase() + activity.name.slice(1),
            value: activity.id,
          }));
          options = [...selectedSubActivities, ...options.filter(option => !selectedSubActivities.find(selectedOption => selectedOption.value === option.value))];
        }

        setSubActivityOptions(options);
      }).catch((error) => {
        pushNotification(error.response.data.detail, "error");
      });
    };

    //------------------------ Fetch Disposal Site Options ------------------------//


  const fetchDisposalSiteOptions = async (query = '', page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_DISPOSAL_SITES(query)}&is_active=true&page=${page}`)
    .then((response) => {
    const result = response.data.results;
    
    if (response.data.next) {
      const urlObj = new URL(response.data.next);
      const params = new URLSearchParams(urlObj.search);
      const pageNumber = params.get('page');
      
      setDisposalSiteOptionsPage(pageNumber);
    } else {
      setDisposalSiteOptionsPage(null);
    }

    let newOptions = result?.map((item) => {
      item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
      item.value = item.id;
      return item;
    });

    let options = disposalSiteOptions !== null && disposalSiteOptions !== undefined ? [...disposalSiteOptions] : [];
    newOptions.forEach(newOption => {
      if (!options.find(option => option.value === newOption.value)) {
        options.push(newOption);
      }
    });

    if (editProjectValues?.disposal_site) {
      const selectedDisposalSites = editProjectValues.disposal_site.map(site => ({
        label: site.name.charAt(0).toUpperCase() + site.name.slice(1),
        value: site.id,
      }));
      options = [...selectedDisposalSites, ...options.filter(option => !selectedDisposalSites.find(selectedOption => selectedOption.value === option.value))];
    }

    setDisposalSiteOptions(options);


    }).catch((error) => {
        
    pushNotification(error?.response?.data?.detail, "error");
    });
};



    useEffect(() => {
        fetchAttachments();
        fetchClients();
        fetchSubActivities();
        fetchPrimeContractors();
        fetchEvents();
        fetchDisposalSiteOptions();
    }, []);


    //------------------ Event Handlers ---------------------//
    
    const handleSwitchChange = (checked) => {
      setActive(checked);
      form.setFieldsValue({ is_active: checked }); 
      
    };

    const handleEventChange = (value) => {
        setEvent(value);
    };

    const handleClientChange = (value) => {
        setClient(value);
    };

    const handleContractorChange = (value) => {
        setContractor(value);
    };

    const handleSubActivityChange = (value) => {
        setSubActivity(value);
    };

    const handleDisposalSiteChange = (value) => {
        setDisposalSite(value);
    };

    return (
   
<CustomModal  
  open={isModalOpen}
  title={title}
  width="630px"
  heigth="580px"
  onCancel={() => {
    setModalOpen(false);
    form.resetFields();
  }}
  
  footer={null}
  maskClosable={false}
  // isScrollable={true}
>
<Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
  <Form name="updateProjectForm" onFinish={onFinish} form={form} layout="vertical" >
    <FormWrapper>
    <div style={{display:"flex", flexDirection:"row", marginRight:"15px"}}>

      <FormItem 
        name="name"
        label="Project Name"
        rules={[
          {
            required: true,
            message: "Please enter project name",
          },
          {
            min: 3,
            max: 50,
            message: "Project's name should be between 3 to 50 characters",
          }
        ]}
      >
        <Input placeholder="Enter Project Name" style={{width:"280px"}}/>
      </FormItem>
      <FormItem 
      name="event" 
      label="Event Name"
      rules={[
        {
          required: true,
          message: "Please Select an Event Name",
        },
      ]} 
    >
      <PaginatedSelect fetchData={fetchEvents} placeholder="Select Event" options={eventOptions} value={event} onChange={handleEventChange} pageNumber={eventOptionsPage} style={{width:"280px"}}/>
    </FormItem>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"15px"}}>
        <FormItem 
            name="client" 
            label="Client Name"
            rules={[
                {
                required: true,
                message: "Please Select a Client Name",
                },
            ]} 
        >
            <PaginatedSelect fetchData={fetchClients} placeholder="Select Client" options={clientOptions} value={client} onChange={handleClientChange} pageNumber={clientOptionsPage} style={{width:"280px"}}/>
        </FormItem>
        <FormItem 
            name="contractor" 
            label="Prime Contractor"
            rules={[
                {
                required: true,
                message: "Please Select a Prime Contractor",
                },
            ]} 
        >
            <PaginatedSelect fetchData={fetchEvents} placeholder="Select Prime Contractor" options={primeContractorOptions} value={contractor} onChange={handleContractorChange} pageNumber={primeContractorOptionsPage} style={{width:"280px"}}/>
        </FormItem>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"15px"}}>

      <FormItem 
        name="po_number"
        label="PO Number"
        rules={[
          {
            required: false,
            message: "Please enter PO Number",
          },
          {
            min: 3,
            max: 50,
            message: "PO Number should be between 3 to 50 characters",
          }
        ]}
      >
        <Input placeholder="Enter PO Number" style={{width:"280px"}}/>
      </FormItem>
      <FormItem 
        name="project_identfication_number"
        label="Project Identification Number"
        rules={[
          {
            required: true,
            message: "Please enter project identification number",
          },
          {
            min: 4,
            max: 4,
            message: "Project identification number should be of 4 characters",
          },
          {
            pattern:  /^[A-Za-z0-9]+$/,
            message: "Please enter a valid project identification number",
          }
        ]}
      >
        <Input placeholder="Enter Project Identification Number" style={{width:"280px"}}/>
      </FormItem>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"15px"}}>

      <FormItem 
        name="city"
        label="City"
        rules={[
          {
            required: false,
            message: "Please enter city name",
          },
          {
            min: 3,
            max: 50,
            message: "City name should be between 3 to 50 characters",
          }
        ]}
      >
        <Input placeholder="Enter City" style={{width:"280px"}}/>
      </FormItem>
      <FormItem 
        name="state"
        label="State"
        rules={[
          {
            required: false,
            message: "Please enter state name",
          },
          {
            min: 3,
            max: 50,
            message: "State's name should be between 3 to 50 characters",
          }
        ]}
      >
        <Input placeholder="Enter State" style={{width:"280px"}}/>
      </FormItem>
    </div>
    <FormItem name="sub_activity" label="Sub-Activities">
      <PaginatedSelect fetchData={fetchSubActivities} mode={'multiple'}  placeholder="Select Sub-Activity" options={subActivityOptions} value={subActivity} onChange={handleSubActivityChange} pageNumber={subActivityOptionsPage} style={{width:"570px"}}/>
    </FormItem>
    <FormItem name="disposal_site" label="Disposal Sites">
      <PaginatedSelect fetchData={fetchDisposalSiteOptions} mode={'multiple'}  placeholder="Select Disposal Site" options={disposalSiteOptions} value={disposalSite} onChange={handleDisposalSiteChange} pageNumber={disposalSiteOptionsPage} style={{width:"570px"}}/>
    </FormItem>
    <FormItem 
      name="total_estimated_cy" 
      label="Total Estimated CY"
      rules={[
        {
          validator(_, value) {
            // If the value is empty or not provided, skip validation (optional)
            if (!value) {
              return Promise.resolve();
            }
            // Ensure the value is numeric (only digits)
            if (!/^\d+$/.test(value)) {
              return Promise.reject(new Error("Total Estimated CY must be numeric"));
            }
            // Ensure the value does not exceed 10 digits
            if (value.toString().length > 10) {
              return Promise.reject(new Error("Total Estimated CY must not exceed 10 digits"));
            }
            // Validation passed
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputNumber placeholder='Enter Total Estimated CY' style={{width:"570px"}}/>
    </FormItem>
    <FormItem 
      name="total_roadway_miles" 
      label="Total Roadway Miles"
      rules={[
        {
          validator(_, value) {
            // If the value is empty or not provided, skip validation (optional)
            if (!value) {
              return Promise.resolve();
            }
            // Ensure the value is numeric (only digits)
            if (!/^\d+$/.test(value)) {
              return Promise.reject(new Error("Total Roadway Miles must be numeric"));
            }
            // Ensure the value does not exceed 10 digits
            if (value.toString().length > 10) {
              return Promise.reject(new Error("Total Roadway Miles must not exceed 10 digits"));
            }
            // Validation passed
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputNumber placeholder='Enter Total Roadway Miles' style={{width:"570px"}}/>
    </FormItem>

    
    <FormItem name="is_active" label="Status">
      <CustomSwitch initialStatus={active} onChange={handleSwitchChange} />
    </FormItem>
    
   <FormItem name="attachments" label="Attachments">
    <CustomUploader
      file={file}
      setFile={setFile}
      uploadedFiles={uploadedFiles}
      setUploadedFiles={setUploadedFiles}
      directory={"project"}
    />
   </FormItem>

   

      </FormWrapper>
      <Divider/>
    <SaveContainer className="d-flex justify-content-end">
      <CustomButton
        btnText={"Cancel"}
        margin="0px 5px"
        noBackground
        hideIcon={true}
        onClick={() => setModalOpen(false)}
      />
      <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
    </SaveContainer>
  </Form>
</CustomModal>


  )
}


const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const FormWrapper = styled.div`
  height: 585px;
  width:  600px;
  overflow: auto;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 625px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;






