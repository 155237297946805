import { useEffect, useState } from 'react';
import { Collapse, Transfer } from 'antd';
import HeadingComponent from '../headingComponent/heading';
import CustomFilter from '../customFilterWithSearchBar/customFilter';
import "./updateMapUser.css";
import { useSelector } from 'react-redux';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import CustomButton from '../customButton/customButton';
import { pushNotification } from '../../util/notification';
import { hasPermission } from '../../util/permissionChecker';
import usePermissions from '../hooks/usePermissions';

export default function UpdateMapUser({
    userOptions,
    positionOptions,
    setRoleSelected,
    setPositionSelected,
    selectedProjectId,
    availableUserData,
    fetchPosition = () => {},
    setPermissionText = () => {},
    setPermissionPopUp = () => {},
    setUserMapped = () => {},
}) {

    //------------------ Custom Hooks ---------------------//
  

    const { assignedPermissions } = usePermissions();

    const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];   
  
    const [activeKey, setActiveKey] = useState([]);
    const rolesState = useSelector((state) => state.roles.roles);
    const roles = rolesState.filter((role) => role.type !== "client" && role.type !== "contractor").map((role) => ({
        label: role.name,
        value: role.id,
    }));
    const [targetKeys, setTargetKeys] = useState([]);

    
    
    const handleChangeColapse = (key) => {
      if (!hasPermission(assignedPermissionsArray, 'manage_map_user')) {
        setPermissionText('You do not have permission to map users on a project');
        setPermissionPopUp(true);
      } else {
        setActiveKey(key);
      }
    };

 

    const handleAssignUserToProject = () => {
      if (selectedProjectId) {
        
        const payload = {
          user: targetKeys.map(Number),
          project: selectedProjectId,
        }
        main_api.post(adminAPIsEndPoints.ADD_USER_TO_PROJECT, payload)
        .then((response) => {
          if (response.status === 201) {
              setUserMapped(true);
              setTargetKeys([]);
              pushNotification("Users Added Successfully!", "success");
          }
        }).catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        pushNotification("Please select a project to assign users!","error")
      }
      
    };  
    // transfer functions

    const handleChange = (newTargetKeys) => {
      
        setTargetKeys(newTargetKeys);

    };

    const renderItem = (item) => {
        const customLabel = (
        <span className="custom-item">
            {item.name} - {item.position}
        </span>
        );
        return {
        label: customLabel,
        value: item.name,
        };
    };


    return (
    <Collapse 
      defaultActiveKey={['1']} 
      onChange={handleChangeColapse} 
      expandIconPosition='end'
      activeKey={activeKey} // Bind the activeKey state
      style={{ marginTop: '20px', marginBottom: '20px' }}
    >
      <Collapse.Panel 
        header={
          <Heading 
            text="Update User Map" 
            margin="0px 0px 0px 5px" 
            fontSize="1.3rem" 
            color="#3B3B3B" 
          />
        } 
        key="1"
        style={{ 
          backgroundColor: activeKey.includes('1') ? '#EBF0F7' : 'white', // Conditional background color
        }}
      >

        <CustomFilter 
          filter1={true}
          paginatedSelect={true}
          resetFilters={true}
          filter1Placeholder="Select Role"
          paginatedSelectPlaceholder="Select Position"
          resetFiltersText="Reset Filter"
          filter1Options={roles}
          paginatedSelectOptions={positionOptions}
          onFilter1Change={(e) => {
            setRoleSelected(e);
            setPositionSelected(null);
          }}
          onPaginatedSelectChange={(e) => setPositionSelected(e)}
          paginatedSelectFetchData={fetchPosition}
          onResetFiltersClick={() => {
            setRoleSelected(null);
            setPositionSelected(null);
          }}
          filter1Style={{ 
            marginLeft: '0px',
            marginBottom: '20px',
            position: 'relative',
            top: '12px',
            left: '6px',
            width: '260px',
            height: '40px',
          }}
          paginatedSelectStyle={{ 
            marginLeft: "20px", 
            marginBottom: "20px", 
            position: "relative", 
            top: "12px", 
            left: "6px", 
            width: "260px", 
            height: "40px" 
          }}
          resetFiltersStyle={{ 
            cursor: "pointer", 
            color: "#EE3E41", 
            marginLeft: "15px", 
            marginBottom: "20px", 
            position: "relative", 
            top: "20px", 
            left: "6px", 
            width: "260px", 
            height: "40px" 
          }}
        />
        <Heading 
          text="Move Users Accordingly" 
          margin="20px 0px 10px 5px" 
          fontSize="0.87rem" 
          fontWeight={500} 
          color="#101828" 
        />
        <Transfer
          dataSource={availableUserData}
          showSearch
          listStyle={{
            width: 280,
            height: 300,
            background: "white"
          }}
          targetKeys={targetKeys}
          onChange={handleChange}
          render={renderItem}
          selectAllLabels={['Available Users', 'Assigned Users']}
          selectionsIcon={<span></span>}
          showSelectAll={false}
        />
         <div className="d-flex justify-content-end" style={{margin: "20px 20px 20px 0px"}}>

          <CustomButton btnText={"Save Changes"} color={"white"} onClick={handleAssignUserToProject} hideIcon={true} />
         </div>

      </Collapse.Panel>
    </Collapse>
  )
}


const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
  };
  